import React from 'react';

import { connect } from 'react-redux';
import { isLoggedIn } from '../selectors';
import { createStructuredSelector } from 'reselect';
import PageWrapper from '../../../components/PageWrapper';
import modal from 'modal';

const NotLoggedInMessage = ({ showModal }) => (
  <PageWrapper>
    <h2>Not logged in</h2>
    <p>
      Sorry, you need to be{' '}
      <button
        className='btn btn-link'
        onClick={() => showModal('login')}
      >
        logged in
      </button> first.
    </p>
  </PageWrapper>
);

const LoginGate = ({ children, showModal, isLoggedIn }) => {

  if (isLoggedIn) {
    return children;
  }

  return <NotLoggedInMessage showModal={showModal} />;

};

const ConnectedLoginGate = connect(
  createStructuredSelector({
    isLoggedIn
  }),
  (dispatch) => ({
    showModal: (name) => dispatch(modal.actions.showModal(name))
  })
)(LoginGate);

export const requiresLogin = (WrappedComponent) => ({ showModal,  ...props }) => (
  <ConnectedLoginGate showModal={showModal}>
    <WrappedComponent {...props} />
  </ConnectedLoginGate>
);
