import styled from 'styled-components';
import { ifThemeValue, themeValue, mappedThemeValue } from '../utils';

const selfAlignmentMap = {
  'top': 'start',
  'middle': 'center',
  'bottom': 'end'
};

const alignItemsMap = {
  'top': 'flex-start',
  'middle': 'center',
  'bottom': 'flex-end'
};

const justifyContentMap = {
  'left': 'flex-start',
  'center': 'center',
  'right': 'flex-end'
};

export default styled.div`
  text-align: ${themeValue(`font.normal.align`)};

  align-self: ${
    ifThemeValue(
      'textBox.enabled',
      'stretch',
      mappedThemeValue('verticalAlignment', selfAlignmentMap) || 'center'
    )
  };

  display: flex;
  flex-direction: column;
  align-items: ${ mappedThemeValue('font.normal.align', justifyContentMap) || 'center' };
  justify-content: ${ mappedThemeValue('verticalAlignment', alignItemsMap) || 'center' };


  background-color: ${ ifThemeValue('textBox.enabled', themeValue('textBox.backgroundColor', 'transparent'), 'transparent') };
  border-style: solid;
  border-color: ${ ifThemeValue('textBox.enabled', themeValue('textBox.borderColor', 'transparent'), 'transparent') };
  border-width: ${ ifThemeValue('textBox.enabled', themeValue('textBox.borderWidth', '0'), '0') };
  padding: ${ ifThemeValue('textBox.enabled', themeValue('textBox.padding', '0'), '0') };
`;
