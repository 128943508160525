import React from 'react';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';

import resourceModule from 'resource';
import playlistModule from 'playlist';
import { previewResource } from '../actions';
import { liveResourceSelector } from '../selectors';


class PlaylistAutoAdvancer extends React.Component {

  componentDidUpdate(prevProps) {
    const { currentlyLive, playlist, previewResource, resources } = this.props;

    if (
      currentlyLive &&
      (
        !prevProps.currentlyLive ||
        currentlyLive.id !== prevProps.currentlyLive.id
      )
    ) {

      const positionInPlaylist = playlist.findIndex(
        (pl) => pl.id === currentlyLive.id
      );

      if (positionInPlaylist < playlist.length - 1) {
        const nextItem = playlist[positionInPlaylist + 1];

        if (nextItem.type === 'resource') {
          previewResource(resources[nextItem.id]);
        }
        else {
          previewResource(nextItem);
        }

      }
    }
  }

  render() {
    return null;
  }

}

export default connect(
  createStructuredSelector({
    currentlyLive: liveResourceSelector,
    resources: resourceModule.selectors.resourceState,
    playlist: playlistModule.selectors.currentPlaylistSelector
  }),
  {
    previewResource
  }
)(PlaylistAutoAdvancer);
