import "core-js/shim";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga';

import './index.css';
import createStore from './store';
import Layout from './components/Layout';
import routes from './routes';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN || (typeof(window) !== 'undefined' && window._SENTRY_DSN);

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    release: `JustWords-web@${process.env.REACT_APP_VERSION}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}
else {
  console.log("DSN not set, not initialising Sentry"); // eslint-disable-line no-console
}

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(
    process.env.REACT_APP_GA_TRACKING_ID,
    {
      gaOptions: {
        debug: process.env.NODE_ENV === 'development',
        siteSpeedSampleRate: 100
      }
    }
  );
}
else {
  console.log("GA ID not set, not initialising GA"); // eslint-disable-line no-console
}

const { store } = createStore({}, null, true);

const renderMethod = process.env.NODE_ENV === 'production' ? ReactDOM.hydrate : ReactDOM.render;

renderMethod(
  (
    <Provider store={store}>
      <Layout routes={routes} />
    </Provider>
  ),
  document.getElementById('root')
);
