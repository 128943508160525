import React from 'react';
import PageWrapper from '../components/PageWrapper';

export default () => (
  <PageWrapper>
    <h1>JustWords privacy policy</h1>
    <p>
      Updated: 19th November 2019.
    </p>

    <h2>Our contact details</h2>
    <p>
      JustWords is a service from <a href="https://www.muscatech.co.uk/">Muscatech Limited</a>,
      registered in England and Wales with company registration number <b>12306761</b>.
    </p>
    <pre>
      Muscatech Limited{'\n'}
      11 Burton Place{'\n'}
      Oxford{'\n'}
      OX4 2RQ
    </pre>
    <p>
      You can contact us by emailing <a href="mailto:info@muscatech.co.uk">info@muscatech.co.uk</a>.
    </p>

    <h2>What type of information we have</h2>
    <p>
      When you register for JustWords, we collect your email address and store it in your account.
      We also collect anonymised statistics about your computer and web browser when you use this
      site, which may include an approximate location. Our server logs may record your computer's
      IP address.
    </p>
    <p>
      If you purchase a subscription or other paid addition to this service, we obtain limited information
      about your payment card from our payment processor, such as the last four digits, the country of issuance
      and the expiration date. Currently, our payment processor is Stripe. Stripe uses and processes your
      complete payment information in accordance with{ ' ' }
      <a href="https://stripe.com/privacy/">Stripe’s privacy policy</a>.


    </p>

    <h2>How we get the information and why we have it</h2>
    <p>
      If you have registered for an account, you provided your email address as part of the sign-up
      process.
    </p>
    <p>
      Anonymous statistics are collected from your browser as you use the site.
    </p>
    <p>
      Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing
      this information are:
    </p>
    <ul>
      <li>
        <b>Consent</b> - By registering for an account you consent to us storing your email address. You
        can withdraw your consent at any time by emailing us and asking for us to delete your account.
      </li>
      <li>
        <b>Legitimate interest</b> - the other information we gather helps us to direct our product
        development and enables us to better shape the product to suit our users' needs.
      </li>
      <li>
        <b>Necessary to perform a contract</b> - in the case of payment information for the provision of a
        paid service
      </li>
    </ul>

    <h2>What we do with the information we have</h2>

    <ul>
      <li>
        We use your <b>email address</b> to identify your account with us. We may use it to contact you
        in relation to this service. We will not contact you for marketing purposes unless you have
        explicitly consented to us doing so.
      </li>
      <li>
        We use the <b>anonymised statistics</b> in order to better understand how you use this service,
        and to inform our product development. (For example, we may identify common screen resolutions
        used by our customers, and focus our quality assurance processes based on that information).
      </li>
      <li>
        We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
        We may pass data to trusted third parties who assist us in operating our site, conducting our business,
        or servicing you, so long as those parties agree to keep this information confidential. We may also release
        your information when we believe release is appropriate to comply with the law, enforce our site policies,
        or protect ours or others rights, property, or safety.
        <ul>
          <li>
            We use <a href="https://www.google.com/analytics">Google Analytics</a> to collect and process
            anonymised statistics relating to use of this website.
          </li>
        </ul>
      </li>
    </ul>

    <h2>How we store your information</h2>

    <p>
      Your data is stored securely on our servers. We implement a variety of security measures to maintain the safety of
      your personal information when you enter, submit, or access your personal information.
    </p>
    <p>
      We will make a good faith effort to retain server logs containing the IP address of all requests to this server
      for no more than 90 days.
    </p>

    <h2>Your data protection rights</h2>
    <p>
      Under data protection law, you have rights including:
    </p>
    <ul>
      <li>
        Your <b>right of access</b> - You have the right to ask us for copies of your personal information.
      </li>
      <li>
        Your <b>right to rectification</b> - You have the right to ask us to rectify information you think is inaccurate.
        You also have the right to ask us to complete information you think is incomplete.
      </li>
      <li>
        Your <b>right to erasure</b> - You have the right to ask us to erase your personal information in certain circumstances.
      </li>
      <li>
        Your <b>right to restriction of processing</b> - You have the right to ask us to restrict the processing of your
        information in certain circumstances.
      </li>
      <li>
        Your <b>right to object to processing</b> - You have the the right to object to the processing of your personal data
        in certain circumstances.
      </li>
      <li>
        Your <b>right to data portability</b> - You have the right to ask that we transfer the information you gave us to another
        organisation, or to you, in certain circumstances.
      </li>
    </ul>

    <p>
      You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.
      Please contact us at <a href="mailto:info@muscatech.co.uk">info@muscatech.co.uk</a> if you wish to make a request.
    </p>

    <h2>How to complain</h2>
    <p>
      You can also complain to the ICO if you are unhappy with how we have used your data.
      The ICO’s address is:
    </p>

    <pre>
      Information Commissioner’s Office{'\n'}
      Wycliffe House{'\n'}
      Water Lane{'\n'}
      Wilmslow{'\n'}
      Cheshire{'\n'}
      SK9 5AF{'\n'}
      Helpline number: 0303 123 1113
    </pre>

  </PageWrapper>
);
