import React from 'react';
import PageWrapper from 'components/PageWrapper';

import { Card, CardHeader, CardBody } from 'reactstrap';

const Feature = ({ children, title }) => (
  <Card>
    <CardHeader tag='h3'>{title}</CardHeader>
    <CardBody className='d-flex'>
      { children }
    </CardBody>
  </Card>
);

export default () => (
  <PageWrapper>
    <h1>
      JustWords features
    </h1>

    <Feature title='Display your songs and liturgy'>
      <ul>
        <li>Enter song lyrics and liturgy in our <b>simple editor</b></li>
        <li>Store songs in your <b>library</b></li>
        <li>
          <b>Quickly search</b> for the song you need using any lyric - no need
          to remember the first line or title
        </li>
        <li>
          Mark song sections as <i>verses</i>, <i>choruses</i>, <i>bridges</i>
          { ' ' } and group phrases together to avoid splitting them over
          multiple screenfuls
        </li>
      </ul>
    </Feature>

    <Feature title='Customise your themes'>
      <ul>
        <li>Change the font face, size, and colour</li>
        <li>Set up <b>multiple themes</b> and switch between them easily</li>
        <li>
          Songs <b>automatically reformat</b> to fit your theme - no need to
          manually fix line/screen breaks when the text size changes
        </li>
      </ul>
    </Feature>

    <Feature title='Show Scripture'>
      <ul>
        <li>
          Integrates with {' '}
          <a
            href="https://www.bible.com/"
            rel="noopener noreferrer"
            target='_blank'
          >
            Bible.com
          </a> { ' ' }
          to show
          passages of Scripture
        </li>
        <li>Instant access to a large number of translations</li>
      </ul>
    </Feature>

    <Feature title='Synchronise and share'>
      <ul>
        <li>
          Synchronise your library between <b>unlimited computers</b> on one
          account
        </li>
        <li>
          <i>Soon:</i> Add read-only shared accounts to give safe access to
          other users
        </li>
      </ul>
    </Feature>

  </PageWrapper>
);
