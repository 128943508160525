import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ResourceForm from './forms/ResourceForm';
import * as actions from '../actions';
import ConfirmationButton from '../../ui/components/ConfirmationButton';

import modal, { connectedModal } from 'modal';

const ConnectedModal = connectedModal('resource/edit');

const EditResourceModal = ({ deleteResource, updateResource, resource, showModal }) => {
  const hide = () => showModal(null);

  return (
    <ConnectedModal className='full-size'>
      <div className='d-flex justify-content-between modal-header'>
        <h5 className='modal-title'>
          Edit resource
        </h5>
        { resource ?
          <span>
            <ConfirmationButton
              className='text-danger text-right'
              color='link'
              confirmText={`Delete '${resource.title}' forever`}
              onClick={() => { hide(); deleteResource({ id: resource.id }); }}
              text='Delete'
            >
              Are you sure?
            </ConfirmationButton>
          </span>
        : null
        }
      </div>
      <ResourceForm
        hide={hide}
        onSubmit={(resource) => updateResource(resource)}
        resource={resource}
      />
    </ConnectedModal>
  );
};

EditResourceModal.propTypes = {
  deleteResource: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  resource: PropTypes.object
};

export default connect(
  createStructuredSelector({
    resource: modal.selectors.modalPayloadSelector
  }),
  {
    deleteResource: actions.deleteResource,
    updateResource: actions.updateResource,
    showModal: modal.actions.showModal
  }
)(EditResourceModal);
