import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Background from './Background';
import TextWrapper from './TextWrapper';
import LineRenderer from './LineRenderer';

import themeModule from 'theme';
import MetaRenderer from './MetaRenderer';
import FadeToBlack from './FadeToBlack';
import Content from './Content';

export const Renderer = ({ blanked, canvasHeight, canvasWidth, fadeToBlack, id, liveGroup, measurementRef, meta, theme }) => {
  const themeProviderProps = theme ? { theme: themeModule.schemas.Theme.cast(theme) } : { theme: { background: 'black' } };
  return (
    <ThemeProvider {...themeProviderProps}>
      <Fragment>
        <Background
          blanked={blanked}
          canvasHeight={canvasHeight}
          canvasWidth={canvasWidth}
        >
          <Content ref={measurementRef}>
            {
              liveGroup ?
                <TextWrapper>
                  {
                    liveGroup.map(
                      (line, idx) => <LineRenderer
                        key={idx}
                        line={line} />
                    )
                  }
                </TextWrapper> : null
            }
            <MetaRenderer
              blanked={blanked}
              fadedOut={fadeToBlack}
              id={id}
              meta={meta} />
          </Content>
        </Background>
        <FadeToBlack active={fadeToBlack} />
      </Fragment>
    </ThemeProvider>
  );
};

Renderer.propTypes = {
  blanked: PropTypes.bool,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  fadeToBlack: PropTypes.bool,
  id: PropTypes.string,
  liveGroup: PropTypes.array,
  meta: PropTypes.object,
  theme: PropTypes.object
};
