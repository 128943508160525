import React from 'react';

import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {  useDispatch, useSelector } from 'react-redux';
import { saveOutputSettings, updateSettings } from '../actions';
import { outputsSelector, settingsState } from '../selectors';
import SettingsForm from './SettingsForm';

import modal, { connectedModal, CloseModalButton } from 'modal';

const ConnectedModal = connectedModal('settings');

const SettingsModal = () => {

  const settings = useSelector(settingsState);
  const dispatch = useDispatch();

  const outputs = useSelector(outputsSelector);

  const [workingOutputs, setWorkingOutputs] = React.useState([]);

  React.useEffect(
    () => {
      setWorkingOutputs([...outputs]);
    },
    [outputs]
  );

  return (
    <ConnectedModal
      className='full-size'
    >
      <ModalHeader>Settings</ModalHeader>
      <ModalBody className='settings-editor'>
        <SettingsForm
          onSubmit={
            (newSettings) => {
              dispatch(updateSettings(newSettings));
              dispatch(saveOutputSettings(workingOutputs));
              dispatch(modal.actions.showModal(null));
            }
          }
          outputs={workingOutputs}
          setOutputs={setWorkingOutputs}
          settings={settings}
        />
      </ModalBody>
      <ModalFooter>
        <CloseModalButton color='secondary'>Close without saving</CloseModalButton>
        <Button
          color='primary'
          form='settingsForm'
          type='submit'
        >
          Apply
        </Button>
      </ModalFooter>
    </ConnectedModal>
  );
};

export default SettingsModal;
