import { getDotpath } from 'utils/dotpath';

export const themeValue = (dotPath, defaultValue) => props => getDotpath(props.theme, dotPath) || defaultValue;

export const ifThemeValue = (dotPath, whenTrue, whenFalse) => props => {
  const themeValue = getDotpath(props.theme, dotPath);
  return themeValue ? whenTrue : whenFalse;
};

export const mappedThemeValue = (dotPath, mapping) => props => mapping[getDotpath(props.theme, dotPath)];
