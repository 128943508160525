import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { subscriptionSelector } from '../selectors';
import { getSubscription } from '../actions';
import SubscriptionDetails from './SubscriptionDetails';
import NoSubscription from './NoSubscription';

import modal from 'modal';

const SubscriptionRetrievalError = () => (
  <p className='text-danger'>
    There was an error retrieving your subscription details. Please try again later.
  </p>
);

const SubscriptionInfo = ({ account, error, paymentMethod, price, showSubscribeModal, subscription }) => {
  const [ isLoading, setLoading ] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      setLoading(true);
      Promise.resolve(
        dispatch(getSubscription())
      ).then(
        r => {
          if (r && r.payload && (r.payload.status !== 200 || r.payload.data.subscription.status === 'canceled')) {
            if (showSubscribeModal) {
              dispatch(
                modal.actions.showModal('subscribe')
              );
            }
          }
        }

      );
      setLoading(false);
    },
    [dispatch, showSubscribeModal]
  );

  if (error) {
    return <SubscriptionRetrievalError error={error} />;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (account.unlimited) {
    return (
      <p>
        Your account has <b>unlimited</b> status. You do not require a subscription
        to JustWords Premium to enjoy full functionality.
      </p>
    );
  }

  if (!subscription) {
    return <NoSubscription />;
  }

  return (
    <SubscriptionDetails
      paymentMethod={paymentMethod}
      price={price}
      subscription={subscription}
    />
  );
};

export default connect(
  subscriptionSelector
)(SubscriptionInfo);
