import * as React from 'react';

import { Button, Collapse } from 'reactstrap';
import { ChangeEmailForm } from './ChangeEmailForm';
import { ChangePasswordForm } from './ChangePasswordForm';

const Panels = {
  NONE: 0,
  PASSWORD: 1,
  EMAIL: 2
};

export const AccountManagementPanel = () => {

  const [currentPanel, setCurrentPanel] = React.useState(Panels.NONE);

  const switchPanel = React.useCallback(
    (nextPanel) => {
      if (nextPanel === currentPanel) {
        setCurrentPanel(Panels.NONE);
      }
      else {
        setCurrentPanel(nextPanel);
      }
    },
    [currentPanel]
  );

  return (
    <>
      <Button
        active={currentPanel === Panels.PASSWORD}
        className='mr-2'
        color='primary'
        onClick={() => switchPanel(Panels.PASSWORD)}
        >
        Change your password
      </Button>
      <Button
        active={currentPanel === Panels.EMAIL}
        color='primary'
        onClick={() => switchPanel(Panels.EMAIL)}
      >
        Change your email address
      </Button>
      <Collapse isOpen={currentPanel === Panels.PASSWORD}>
        <ChangePasswordForm />
      </Collapse>
      <Collapse isOpen={currentPanel === Panels.EMAIL}>
        <ChangeEmailForm />
      </Collapse>
    </>
  );
};
