import { resolved } from 'api';
import resourceModule from 'resource';
import * as constants from './constants';


export default ({ dispatch, getState }) => next => action => {

  if (action.type === constants.START_SYNC) {
    if (action.payload.request && action.payload.request.data.lastSync === 0) {
      // Full sync should include all resources and themes

      const state = getState();
      const { theme: { themes } } = state;

      const resourceActions = resourceModule.selectors.resourcesSelector(state).map(
        r => ({
          type: 'resource/PUT',
          resource: r,
          meta: {
            account: action.payload.account
          }
        })
      );

      const themeActions = Object.values(themes).map(
        t => ({
          type: 'theme/PUT',
          theme: t,
          meta: {
            account: action.payload.account
          }

        })
      );

      const newAction = {
        ...action
      };
      newAction.payload.request.data.actions = [
        ...resourceActions,
        ...themeActions
      ];

      return next(newAction);
    }
  }

  if (action.type === resolved(constants.START_SYNC)) {
    if (action.payload.data && action.payload.data.actions) {

      const resourcesToAdd = action.payload.data.actions.filter(
        (a) => a.type === resourceModule.constants.PUT_RESOURCE
      ).map(
        (a) => a.resource
      );

      dispatch(
        resourceModule.actions.bulkPutResources(
          resourcesToAdd.map(
            r => ({ resource: r })
          )
        )
      );

      const remainder = action.payload.data.actions.filter(
        (a) => a.type !== resourceModule.constants.PUT_RESOURCE
      );

      remainder.forEach(
        pastAction => dispatch({
          ...pastAction,
          meta: {
            ...pastAction.meta,
            bypassSyncQueue: true,
            bypassRendering: true
          }
        })
      );
    }
  }

  return next(action);
};
