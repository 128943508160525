import { resolved } from 'chrome';
import output from 'output';
import playlist from 'playlist';
import resourceModule from 'resource';
import settings from 'settings';
import themeModule from 'theme';
import ui from 'ui';

import { invalidateCache, generateDisplayModel } from './actions';
import { cacheSelector } from './selectors';

const doMakeModel = (store, resource) => {
  const ccli = store.getState().settings.ccli;

  const dirtyResource = {
    ...resource,
    meta: {
      ...resource.meta,
      ccli
    }
  };

  store.dispatch(
    generateDisplayModel(dirtyResource)
  );

};

const regenerate = (store, state) => {
  const currentLiveResource = ui.selectors.liveResourceSelector(state);
  const currentPreviewResource = ui.selectors.previewResourceSelector(state);
  const resources = resourceModule.selectors.resourceState(state);
  const currentPlaylist = playlist.selectors.currentPlaylistSelector(state);
  store.dispatch(invalidateCache());

  if (currentLiveResource) {
    if (currentLiveResource.meta && currentLiveResource.meta.sourceFromPlaylist) {
      const scripture = currentPlaylist.find(p => p.id === currentLiveResource.id);
      if (scripture) {
        doMakeModel(store, scripture);
      }
    }
    else {
      doMakeModel(store, resources[currentLiveResource.id] || currentLiveResource);
    }
  }
  if (currentPreviewResource) {
    if (currentPreviewResource.meta && currentPreviewResource.meta.sourceFromPlaylist) {
      const scripture = currentPlaylist.find(p => p.id === currentPreviewResource.id);
      if (scripture) {
        doMakeModel(store, scripture);
      }
    }
    else {
      doMakeModel(store, resources[currentPreviewResource.id] || currentPreviewResource);
    }
  }
};

export default store => next => action => {

  const retVal = next(action);

  if (action.meta && action.meta.bypassRendering) {
    return retVal;
  }

  const state = store.getState();
  const cache = cacheSelector(state);

  switch (action.type) {
    case ui.constants.PREVIEW_RESOURCE:
    case ui.constants.LIVE_RESOURCE:
      if (action.resource && !cache[action.resource.id]) {
        doMakeModel(store, action.resource);
      }
      break;
    case resourceModule.constants.PUT_RESOURCE:
      if (action.resource) {
        doMakeModel(store, action.resource);
      }
      break;

    case output.constants.UPDATE_DISPLAY_SIZE:
    case playlist.constants.SET_ITEM_THEME:
    case themeModule.constants.SELECT_THEME:
    case resolved(themeModule.constants.LOAD):
    case themeModule.constants.PUT_THEME:
    case themeModule.constants.DELETE_THEME:
    case settings.constants.UPDATE_SETTINGS:
    case settings.constants.SAVE_OUTPUT_SETTINGS:
      regenerate(store, state);
      break;

    default:
      break;
  }

  return retVal;
};
