import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { previewResourceProcessingSelector, previewResourceSelector } from '../selectors';
import * as actions from '../actions';
import { RenderedResource } from 'resource';
import output from '../../output';
import { ResourceLoading } from './ResourceLoading';

export const Preview = () => {

  const processing = useSelector(previewResourceProcessingSelector);
  const resource = useSelector(previewResourceSelector);
  const dispatch = useDispatch();

  const handleGroupClick = (_, blockIndex, groupIndex) => {
    dispatch(actions.previewResource(null));
    dispatch(actions.liveResource(resource));
    dispatch(output.actions.setBlockAddress(blockIndex, groupIndex));
    return true;
  };

  if (processing) {
    return (
      <div className='resource'>
        <ResourceLoading resource={processing} />
      </div>
    );
  }
  if (resource) {
    return (
      <RenderedResource
        onGroupClick={handleGroupClick}
        resource={resource}
      />
    );
  }
  return <div className='resource'></div>;
};
