import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import resourceModule from 'resource';
import * as actions from '../actions';
import modal from 'modal';
import output from '../../output';
import playlist from 'playlist';
import auth from 'auth';
import { ShowModalButton } from 'modal';
import { createSearchAction } from 'redux-search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faFileUpload, faSortAlphaDown, faSortNumericDown } from '@fortawesome/free-solid-svg-icons';
import { getSearchSelectors } from 'redux-search/dist/commonjs/selectors';

import LibraryContents from './LibraryContents';
import LibraryLimit from './LibraryLimit';
import { HotKeys } from 'react-hotkeys';

const KEYMAP = {
  MOVE_DOWN: 'down',
  MOVE_UP: 'up',
  SELECT: 'enter',
  GO_LIVE: ['ctrl+enter', 'command+enter'],
  START_SEARCH: ['ctrl+f', 'command+f'],
  CANCEL_SEARCH: 'escape'
};

const Filters = {
  ALL: () => true,
  SONGS: (r) => (r[0].type === resourceModule.constants.TYPES.SONG),
  LITURGY: (r) => (r[0].type === resourceModule.constants.TYPES.LITURGY)
};

const FilterButton = ({ activeFilter, filter, setFilter, title, icon }) => (
  <Button
    active={activeFilter === filter}
    block
    color='secondary'
    onClick={() => setFilter(filter)}
    title={title}
  >
    <FontAwesomeIcon
      fixedWidth
      icon={icon} />
    <span>{title}</span>
  </Button>
);

const alphaSort = (a, b) => {
  if (a && b && a.title && b.title) {
    const ta = a.title.toUpperCase();
    const tb = b.title.toUpperCase();
    if (ta < tb) {
      return -1;
    }
    else if (ta > tb) {
      return 1;
    }
  }
  return 0;
};

const mostRecentSort = (a, b) => {
  if (a && b && a.meta && b.meta) {
    return (b.meta.lastUsed || 1e10) - (a.meta.lastUsed || 1e10);
  }
  return 0;
};

const arraysEqual = (array1, array2) => array1.length === array2.length && array1.every((value, index) => value === array2[index]);

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
          map.set(key, [item]);
      } else {
          collection.push(item);
      }
  });
  return map;
};

class Library extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: -1,
      typeFilter: Filters.ALL,
      sortByRecent: true,
      filteredResources: []
    };
    this.setTypeFilter = this.setTypeFilter.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.startSearch = this.startSearch.bind(this);
    this.manipulateSelection = this.manipulateSelection.bind(this);
    this.filterResources = this.filterResources.bind(this);

    this.handlers = {
      MOVE_DOWN: this.manipulateSelection,
      MOVE_UP: this.manipulateSelection,
      SELECT: this.manipulateSelection,
      GO_LIVE: this.manipulateSelection,
      START_SEARCH: this.startSearch,
      CANCEL_SEARCH: this.cancelSearch
    };
  }

  startSearch(evt) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (this._searchBox) {
      this._searchBox.focus();
      this._searchBox.select();
    }
  }

  componentDidMount() {
    if (this._searchBox && this.props.searchMode) {
      window.setTimeout(this.startSearch, 10);
    }
    this.setState({
      filteredResources: this.filterResources()
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchMode, search: { result }, resources } = this.props;
    if (searchMode && !prevProps.searchMode) {
      this.startSearch();
    }
    else if (!searchMode && searchMode !== undefined && prevProps.searchMode) {
      this.cancelSearch();
    }

    if (
      !arraysEqual(result, prevProps.search.result) ||
      this.state.typeFilter !== prevState.typeFilter ||
      this.state.sortByRecent !== prevState.sortByRecent ||
      resources !== prevProps.resources
    ) {
      const filteredResources = this.filterResources();
      this.setState({
        filteredResources,
        selectedIndex: Math.min(filteredResources.length - 1, this.state.selectedIndex)
      });
    }
  }

  filterResources() {
    const { resources, search: { result } } = this.props;
    const groupedResults = groupBy(result, (id) => id.split('@')[0]);
    return Array.from(groupedResults.keys()).map(
      (id) => {
        const results = groupedResults.get(id);
        const addr = results[0].split('@')[1].split(',');
        return [resources[id], addr && addr.length === 3 ? addr.map(a => parseInt(a, 10)) : [0, 0, 0]];
    })
    .filter(this.state.typeFilter)
    .filter((r) => !!r)
    .sort((r1, r2) => (this.state.sortByRecent && mostRecentSort(r1[0], r2[0])) || alphaSort(r1[0], r2[0]));
  }

  setTypeFilter(filter) {
    this.setState({ typeFilter: filter });
  }

  cancelSearch(evt) {
    const { performSearch, search: { text }, showModal } = this.props;
    if (text !== '') {
      performSearch('');
      this.setState({ selectedIndex: -1 });
      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
    else {
      showModal(null);
    }
  }

  manipulateSelection(evt) {
    const { addToPlaylist, setBlockAddress, showModal, previewResource, liveResource } = this.props;
    const { selectedIndex, filteredResources } = this.state;
    if (evt.defaultPrevented) {
      // We've already handled this (I think Ctrl+Enter sometimes gets a dupe with Enter)
      return;
    }
    if (evt.key === 'ArrowDown') {
      this.setState({ selectedIndex: Math.min(this.state.selectedIndex + 1, this.props.search.result.length - 1) });
    }
    else if (evt.key === 'ArrowUp') {
      this.setState({ selectedIndex: Math.max(this.state.selectedIndex - 1, 0) });
    }
    else if (evt.key === 'Enter' && selectedIndex > -1) {
      const [selectedResource, address] = filteredResources[selectedIndex];
      if (selectedResource) {
        if (evt.shiftKey) {
          addToPlaylist(selectedResource);
          if (this._searchBox) {
            this._searchBox.select();
          }
        }
        else {
          if (evt.ctrlKey) {
            liveResource(selectedResource);
            setBlockAddress(address[0], 0); // We're working on the pre-rendered resource so linegroup addresses will be inaccurate
          }
          else if (!evt.altKey) {
            previewResource(selectedResource);
          }
          evt.preventDefault();
          this.cancelSearch();
          showModal(null);
        }
      }
    }
  }

  render() {
    const {
      addToPlaylist,
      deleteResource,
      extraButtons,
      liveResource,
      performSearch,
      previewResource,
      resources,
      resourceLimit,
      search: { text },
      setBlockAddress,
      showHelpText,
      showModal
    } = this.props;

    const resourceCount = Object.keys(resources).filter(k => k[0] !== '_').length;

    return (
      <HotKeys
        className='library'
        handlers={this.handlers}
        keyMap={KEYMAP}
      >
        <div className='sidebar'>
          <div>
            <ShowModalButton
              block
              color='primary'
              disabled={resourceLimit >= 0 && resourceCount >= resourceLimit}
              modalName='resource/add'
              title='Create new'
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faPlus} />
              <span>New</span>
            </ShowModalButton>
            <ShowModalButton
              block
              color='primary'
              disabled={resourceLimit >= 0 && resourceCount >= resourceLimit}
              modalName='resource/import'
              title='Import'
          >
              <FontAwesomeIcon
                fixedWidth
                icon={faFileUpload} />
              <span>Import</span>
            </ShowModalButton>
          </div>
          <div className='my-2'>
            <FilterButton
              activeFilter={this.state.typeFilter}
              filter={Filters.ALL}
              icon={resourceModule.constants.ICONS.ALL}
              setFilter={this.setTypeFilter}
              title='Show all'
            />
            <FilterButton
              activeFilter={this.state.typeFilter}
              filter={Filters.SONGS}
              icon={resourceModule.constants.ICONS[resourceModule.constants.TYPES.SONG]}
              setFilter={this.setTypeFilter}
              title='Songs'
            />
            <FilterButton
              activeFilter={this.state.typeFilter}
              filter={Filters.LITURGY}
              icon={resourceModule.constants.ICONS[resourceModule.constants.TYPES.LITURGY]}
              setFilter={this.setTypeFilter}
              title='Liturgy'
            />

          </div>
          <div className='my-2'>
            <Button
              active={this.state.sortByRecent}
              block
              color='secondary'
              onClick={() => this.setState({ sortByRecent: true })}
              title='Sort by recently used'
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faSortNumericDown} />
              <span>Most recent first</span>
            </Button>
            <Button
              active={!this.state.sortByRecent}
              block
              color='secondary'
              onClick={() => this.setState({ sortByRecent: false })}
              title='Sort by title, A-Z'
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faSortAlphaDown} />
              <span>Alphabetical</span>
            </Button>
          </div>
          <p className='text-muted mt-2'>
            Showing { this.state.filteredResources.length } of { resourceCount } items
          </p>
          <LibraryLimit
            count={resourceCount}
            limit={resourceLimit} />
        </div>

        <InputGroup className='searchbar'>
          <Input
            bsSize='lg'
            innerRef={(elem) => this._searchBox = elem}
            onChange={(e) => performSearch(e.target.value.toLowerCase())}
            onKeyDown={this.manipulateSelection}
            onKeyUp={(e) => {
              if (e.keyCode === 27) {
                this.cancelSearch();
              }
            }}
            placeholder='Search by title or content...'
            tabIndex={0}
            value={text}
          />
          <InputGroupAddon addonType='append'>
            <Button onClick={this.cancelSearch}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
        </InputGroup>

        <LibraryContents
          addToPlaylist={addToPlaylist}
          deleteResource={deleteResource}
          extraButtons={extraButtons}
          liveResource={liveResource}
          previewResource={previewResource}
          searchResults={this.state.filteredResources}
          selectedIndex={this.state.selectedIndex}
          setBlockAddress={setBlockAddress}
          showHelpText={showHelpText}
          showModal={showModal}
        />
      </HotKeys>
    );
  }
}

Library.defaultProps = {
  extraButtons: null,
  showHelpText: true
};

const { text, unfilteredResult } = getSearchSelectors({
  resourceName: 'resources',
  resourceSelector: (resourceName, state) => state[resourceName]
});

const results = createSelector(
  unfilteredResult, text,
  (result, text) => ({
    result,
    text
  })
);

export default connect(
  createStructuredSelector({
    resources: resourceModule.selectors.resourceState,
    search: results,
    resourceLimit: auth.selectors.limitSelector('resources')
  }),
  {
    ...actions,
    deleteResource: resourceModule.actions.deleteResource,
    showModal: modal.actions.showModal,
    setBlockAddress: output.actions.setBlockAddress,
    addToPlaylist: playlist.actions.addResource,
    performSearch: createSearchAction('resources')
  }
)(Library);
