import React from 'react';
import PropTypes from 'prop-types';

import RenderedResourceContent from './RenderedResourceContent';
import { EditResourceButton } from 'components/EditResourceButton';
import { useSelector } from 'react-redux';
import { resourceState } from '../selectors';

const EDITABLE_RESOURCES = ['Song', 'Liturgy'];

const RenderedResource = ({ resource, ...others }) => {

  const allResources = useSelector(resourceState);
  const originalResource = allResources[resource.id];

  return (
    <div className="resource">
      <div className='d-flex'>
        <h4 className='flex-grow-1'>{resource.title}</h4>
        <EditResourceButton
          className='mb-2 p-0'
          color='secondary'
          disabled={!EDITABLE_RESOURCES.includes(resource.type)}
          resource={originalResource}
          size='sm'
        />
      </div>
      <RenderedResourceContent
        resource={resource}
        {...others}
      />
    </div>
  );
};

// Return true from any of the onXClick handlers to stop propagation
// of the event to higher-up nodes.
// onLineClick is called first, then onGroupClick, then onBlockClick.
RenderedResource.propTypes = {
  editable: PropTypes.bool,
  onBlockClick: PropTypes.func,
  onGroupClick: PropTypes.func,
  onLineClick: PropTypes.func,
  resource: PropTypes.object
};

export default RenderedResource;
