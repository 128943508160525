/**
 * Try to intelligently paste from an HTML source into our object model.
 * Returns true if an intelligent paste has occurred, or false otherwise. In the
 * latter case, the caller is still responsible for inserting data into the
 * editor themselves!
 */
export const pasteHTML = (editor, html) => {
  if (html.getElementsByClassName('chord-pro-line').length > 0) {
    // WorshipTogether.com, possibly others
    pasteChordPro(editor, html);
    return true;
  }
  if (
    html.getElementsByTagName('body')[0].childElementCount ===
    html.getElementsByTagName('body')[0].getElementsByTagName('span').length
  ) {
    // Planning Center's hideous PDFfy markup
    pastePlanningCenter(editor, html);
    return true;
  }
  return false;
};

const pasteChordPro = (editor, html) => {
  html.getElementsByTagName('div').forEach(
    tag => {
      if (tag.classList.contains('chord-pro-line')) {
        const text = tag.innerText.trim();
        if (text.length > 0) {
          editor.insertText(text);
          editor.insertBreak();
        }
      }
      if (tag.classList.contains('chord-pro-br')) {
        editor.insertBreak();
      }
    }
  );
};

const pastePlanningCenter = (editor, html) => {
  const spans = html.getElementsByTagName('span');

  let prevYPos = 0;

  const lines = [];
  let currentLine = '';

  for (let span of spans) {
    const spanTop = parseFloat(span.style.top);
    if (spanTop > prevYPos) {
      if (currentLine !== '') {
        lines.push(currentLine);
      }
      currentLine = '';
      prevYPos = spanTop;
    }
    currentLine = `${currentLine} ${span.innerText}`.trim();
  }
  lines.push(currentLine);

  lines.forEach(
    line => {
      if (line.length > 6 && line.slice(0, 6) === 'Verse ') {
        editor.insertBreak();
        editor.setVerse();
      }
      else if (line === 'Bridge') {
        editor.insertBreak();
        editor.setBridge();
      }
      else if (line === 'Chorus') {
        editor.insertBreak();
        editor.setChorus();
      }
      else {
        editor.insertText(line);
        editor.insertBreak();
      }

    }
  );
};
