import * as React from 'react';

import { Button, Popover, PopoverBody } from 'reactstrap';

export default ({ actionText, children, color, disabled, onClick, text, ...props }) => {

  const [isOpen, setOpen] = React.useState(false);
  const buttonRef = React.useRef();
  const toggle = () => !disabled && setOpen(!isOpen);

  return (
    <React.Fragment>
      <Button
        color={color}
        disabled={disabled}
        innerRef={buttonRef}
        onClick={toggle}
        {...props}
      >
        { children }
      </Button>
      {
        buttonRef.current && (
          <Popover
            isOpen={isOpen}
            placement='auto'
            target={buttonRef.current}
            toggle={toggle}
            trigger='legacy'
          >
            <PopoverBody>
              <p>
                { text }
              </p>
              <div className='d-flex'>
                <Button
                  className='mx-1'
                  color='secondary'
                  onClick={() => setOpen(false)}
                  size='sm'
                  style={{ flexBasis: '50%' }}
                >
                  Cancel
                </Button>
                <Button
                  className='mx-1'
                  color={color}
                  onClick={(e) => { setOpen(false); onClick(e); } }
                  size='sm'
                  style={{ flexBasis: '50%' }}
                >
                  { actionText }
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        )
      }
    </React.Fragment>
  );

};
