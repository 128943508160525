import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBible } from '@fortawesome/free-solid-svg-icons';
import { ShowModalButton } from 'modal';

export default () => (
  <ShowModalButton
    color='primary'
    modalName='bible'
    shortcut={['ctrl+b', 'ctrl+B', 'command+b', 'command+B']}
    title='Insert Bible reading'
  >
    <FontAwesomeIcon icon={faBible} />
    Bible
  </ShowModalButton>
);
