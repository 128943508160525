import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queueSelector } from '../selectors';
import * as constants from '../constants';

import themeModule from 'theme';
import playlist from 'playlist';
import { cacheDisplayModel, processDisplayModel } from '../actions';
import { useBroadcastChannel } from '../../../hooks';

export const RenderQueueProcessor = ({ canvasSize }) => {

  const queue = useSelector(queueSelector);
  const currentPlaylist = useSelector(playlist.selectors.currentPlaylistSelector);
  const globalTheme = useSelector(themeModule.selectors.currentThemeSelector);
  const themes = useSelector(themeModule.selectors.themesSelector);

  const dispatch = useDispatch();
  const bc = useBroadcastChannel('justwords');

  React.useEffect(
    () => {
      if (bc && queue.length > 0) {
        const resource = queue[0];

        // Work out which theme we need
        // Note: if an item is in the playlist multiple times, then we only care
        // about the theme set on its first occurrence.
        let theme = globalTheme;

        currentPlaylist.forEach(
          pli => {
            if (pli.meta && pli.meta.themeID && pli.id === resource.id) {
              theme = themes[pli.meta.themeID] || globalTheme;
            }
          }
        );

        // Mark it as being processed...
        dispatch(processDisplayModel(resource.id));

        // And pass it via broadcastchannel to whomever will render it for us
        bc.postMessage(
          {
            type: constants.GENERATE_DISPLAY_MODEL,
            canvasSize,
            resource,
            theme
          },
          '*'
        );

      }
    },
    [bc, canvasSize, currentPlaylist, dispatch, globalTheme, queue, themes]
  );

  React.useEffect(
    () => {
      bc.onmessage = ({ data }) => {
        if (data.type === constants.CACHE_DISPLAY_MODEL) {
          dispatch(cacheDisplayModel(data.resource));
        }
      };
    },
    [bc, dispatch]
  );

  return null;

};
