import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';

import { Button } from 'reactstrap';

const isMac = typeof(navigator) !== "undefined" && navigator.userAgentData.platform.toUpperCase().indexOf('MAC')>=0;

const formatShortcut = (shortcut) => {
  if (Array.isArray(shortcut)) {
    return shortcut[0].replace('ctrl+', isMac ? 'cmd+' : 'ctrl+').split('+').map((s) => s[0].toUpperCase() + s.slice(1)).join('+');
  }
  return shortcut.replace('ctrl+', isMac ? 'cmd+' : 'ctrl+').split('+').map((s) => s[0].toUpperCase() + s.slice(1)).join('+');
};

const ShortcutButton = ({ children, onClick, shortcut, title, ...props }) => (
  <>
    <Button
      {...props}
      onClick={onClick}
      title={shortcut ? `${title || ''} (${formatShortcut(shortcut)})`.trim() : title}
    >
      { children }
    </Button>
    <GlobalHotKeys
      handlers={{ [`trigger_${shortcut}`]: (e) => { onClick(e); e.preventDefault(); } }}
      keyMap={{ [`trigger_${shortcut}`]: shortcut }}
    />
  </>
);

export default ShortcutButton;
