var CHROME_EXTENSION_ID = undefined;

const getMetaValue = key => {
  if (typeof(document) === 'undefined') {
    return null;
  }
  const meta = document.head.querySelector(`meta[name=${key}]`);
  return meta !== null ? meta.getAttribute('value') : null;
};

export const getExtensionID = () => {
  if (!CHROME_EXTENSION_ID) {
    const maybeMeta = getMetaValue('X-JustWords-Extension');
    CHROME_EXTENSION_ID = maybeMeta !== null ? maybeMeta : null;
    //console.log("Discovered:", CHROME_EXTENSION_ID);
  }
  return CHROME_EXTENSION_ID;
};

export const getExtensionVersion = () => {
  return getMetaValue('X-JustWords-Extension-Version');
};

export const resolved = (actionType) => `${actionType}_RESOLVED`;
export const rejected = (actionType) => `${actionType}_REJECTED`;
