
const _getDotpath = (obj, pathArray) => {
  if (!obj || pathArray.length === 0) {
    return obj;
  }
  else {
    const next = obj[pathArray[0]];
    return _getDotpath(next, pathArray.slice(1));
  }
};

const _setDotpath = (obj, pathArray, value) => {
  if (pathArray.length === 1) {
    obj[pathArray[0]] = value;
  }
  else {
    if (!obj[pathArray[0]]) {
      obj[pathArray[0]] = {};
    }
    _setDotpath(obj[pathArray[0]], pathArray.slice(1), value);
  }
};

export const getDotpath = (obj, path) => _getDotpath(obj, path.split('.'));
export const setDotpath = (obj, path, value) => _setDotpath(obj, path.split('.'), value);
export const immutableSetDotpath = (obj, path, value) => _setDotpath({ ...obj }, path.split('.'), value);
