import React from 'react';
import { Card } from 'reactstrap';
import Footer from './Footer';

export default ({ cardClassName, children }) => (
  <div className='d-flex flex-column'>
    <Card className={`page-card flex-grow-1 ${cardClassName || ''}`}>
      { children }
    </Card>
    <Footer />
  </div>
);
