const EvenSplitStrategy = {
  splitDisplayGroup(group) {
    const halfway = Math.floor(group.length / 2);
    const splits = [
      group.slice(0, halfway),
      group.slice(halfway)
    ];
    return splits;
  },

  splitLine(line) {
    const words = line[0].split(' ');
    const halfWords = Math.floor(words.length / 2);
    return [
      [words.slice(0, halfWords).join(' '), line[1], line[2]],
      [words.slice(halfWords).join(' '), line[1]],
    ];
  }
};

class Measurer {
  constructor(id, canvasWidth, canvasHeight) {
    this.id = id;
    this.width = canvasWidth;
    this.height = canvasHeight;

    this._renderKey = 0;
    this._pendingMeasurements = {};

    this.measure = this.measure.bind(this);
    this.handleMeasurement = this.handleMeasurement.bind(this);
    this.done = this.done.bind(this);

    window.addEventListener('message', this.handleMeasurement);
  }

  measure(displayGroup, meta, theme) {
    const myRenderKey = `${this.id}/${this._renderKey++}`;

    const promise = new Promise(
      (resolve) => {
        this._pendingMeasurements[myRenderKey] = resolve;
      }
    );

    window.postMessage(
      {
        type: 'renderer/SET_RENDER_OPTIONS',
        renderOptions: {
          canvasHeight: this.height,
          canvasWidth: this.width,
          liveGroup: displayGroup,
          meta,
          theme,
          renderKey: myRenderKey
        }
      }
    );

    return promise;
  }

  handleMeasurement({ data }) {
    if (data.type === 'renderer/MEASUREMENTS' && this._pendingMeasurements[data.renderKey]) {
      this._pendingMeasurements[data.renderKey](data.measurements);
      delete this._pendingMeasurements[data.renderKey];
    }
  }

  done() {
    window.removeEventListener('message', this.handleMeasurement);
  }

}


class RenderingEngine {

  constructor(theme, width, height) {
    this.theme = theme;
    this.width = width;
    this.height = height;
    this.mapResource = this.mapResource.bind(this);
    this.mapBlock = this.mapBlock.bind(this);
    this.annotateLineGroups = this.annotateLineGroups.bind(this);
  }

  async mapResource(resource) {
    this.strategy = EvenSplitStrategy;
    const result = [];
    const measurer = new Measurer(resource.id, this.width, this.height);

    for(let i = 0; i < resource.content.length; i++) {
      const mapped = await this.mapBlock(resource.content[i], resource.meta, measurer);
      result.push(mapped);
    }

    measurer.done();

    return result;
  }

  async mapBlock(block, meta, measurer) {
    const content = [];

    const annotatedLineGroups = await this.annotateLineGroups(block.content, meta, measurer);

    const pending = [...annotatedLineGroups];

    while (pending.length > 0) {
      const current = pending.shift();
      if (current.measurements.textHeight > current.measurements.displayHeight) {

        // To avoid adding the tail of one linegroup to the start of another,
        // we must deal with all the splits from the current group in a
        // separate stack to the one holding all other linegroups from the block.
        const currentPending = [current];

        while (currentPending.length > 0) {
          const next = currentPending.shift();

          if (next.measurements.textHeight > next.measurements.displayHeight) {
            // line group is too large
            if (next.lineGroup.length > 1) {
              const split = await this.annotateLineGroups(
                this.strategy.splitDisplayGroup(next.lineGroup),
                meta,
                measurer
              );
              currentPending.unshift(...split);
            }
            else {
              // only one line in the group and it's too large
              const tooLongLine = next.lineGroup[0];
              if (tooLongLine[0].includes(' ')) {
                // If there are multiple words, we stand a chance...
                const split = await this.annotateLineGroups(
                  [ this.strategy.splitLine(tooLongLine) ],
                  meta,
                  measurer
                );
                currentPending.unshift(...split);
              }
              else {
                // There's no way to fit this on screen without splitting words -
                // bug out and suck it up
                content.push(next.lineGroup);
              }
            }
          }
          else {
            content.push(next.lineGroup);
          }
        }


      }
      else {
        // If the original linegroup is small enough, let's try adding
        // the next linegroup in - but only if they both fit
        const currentGroup = [...current.lineGroup];
        let currentHeight = current.measurements.textHeight;
        const displayHeight = current.measurements.displayHeight;

        while (pending.length > 0 && pending[0].measurements.textHeight + currentHeight <= displayHeight) {
          const next = pending.shift();
          currentGroup.push(...next.lineGroup);
          currentHeight += next.measurements.textHeight;
        }

        content.push(currentGroup);
      }
    }

    return {
      type: block.type,
      content: content
    };
  }

  async annotateLineGroups (lineGroups, meta, measurer) {
    return await Promise.all(
      lineGroups.map(
        async lg => {
          const measurements = await measurer.measure(lg, meta, this.theme);
          return {
            lineGroup: lg,
            measurements
          };
        }
      )
    );
  }
}


export const createDisplayModel = async (resource, displayWidth, displayHeight, theme) => {

  const width = Math.max(displayWidth || 640, 640);
  const height = Math.max(displayHeight || 480, 480);

  const re = new RenderingEngine(theme, width, height);

  const content = await re.mapResource(resource);

  const displayResource = {
    ...resource,
    content,
    theme
  };

  return displayResource;
};
