import React from 'react';
import { connect } from 'react-redux';

const Router = ({ location: { type }, routes }) => {
  let Component = null;
  let payload = {};
  if (type && type[0] !== '@') {
    const locationName = type.slice(9);
    Component = routes[locationName].component;
    payload = routes[locationName].payload || {};
  }

  if (!Component) {
    Component = () => <p>Not found!</p>;
  }

  return <Component {...payload} />;
};

export default connect(
  ({ location }) => ({ location })
)(Router);
