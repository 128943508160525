import Account from './pages/Account';
import App from './App';
import Features from './pages/Features';
import GetStarted from './pages/GetStarted';
import Home from './pages/Home';
import { Library } from './pages/Library';
import PasswordReset from './pages/PasswordReset';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';

import auth from 'auth';

const routes = {};

const addRoute = (name, path, component=null, payload={}) => {
  routes[name] = { action: `location/${name}`, path, component, payload };
};

addRoute('HOME', '/', Home);
addRoute('FEATURES', '/features/', Features);
addRoute('PRICING', '/pricing/', Pricing);
addRoute('START', '/start/', GetStarted);
addRoute('LIBRARY', '/library/', auth.requiresLogin(Library));
addRoute('LIVE', '/live/', auth.requiresLogin(App));
addRoute('ACCOUNT', '/account/', auth.requiresLogin(Account));
addRoute('ACCOUNT_SUBSCRIBE', '/account/subscribe', auth.requiresLogin(Account), { showSubscribeModal: true });
addRoute('PASSWORD_RESET', '/password-reset', auth.requiresLogout(routes.HOME.action)(PasswordReset));
addRoute('PRIVACY', '/privacy', Privacy);

export default routes;
