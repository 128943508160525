import * as constants from './constants';

import VERSION from '../../version';
import { combineReducers } from 'redux';
import { resolved } from 'chrome';

import { Output } from './schemas';

const INITIAL_STATE = {
  display: {
    width: 1280,
    height: 720
  },
  outputs: [
    Output.getDefault()
  ],
  lastSeenPreReleaseWarningVersion: null,
  settingsVersion: 2
};

const migrateToSettingsV2 = state => ({
  lastSeenPreReleaseWarningVersion: state.lastSeenPreReleaseWarningVersion,
  display: {
    width: state.outputWindow.width,
    height: state.outputWindow.height
  },
  outputs: [
    {
      top: state.outputWindow.top,
      left: state.outputWindow.left,
      fullscreen: state.outputWindow.fullscreen,
      width: state.outputWindow.width,
      height: state.outputWindow.height,
      type: 'window'
    }
  ],
  settingsVersion: 2
});

const outputsReducer = (state = INITIAL_STATE.outputs, action) => {

  switch (action.type) {
    case constants.SAVE_OUTPUT_SETTINGS:
      const newOutputs = action.outputs.map(o => Output.cast(o, { stripUnknown: true }));
      return newOutputs;

    case resolved(constants.RETRIEVE_DISPLAY_SETTINGS):
      const no = [...state];
      const output = action.payload;
      const index = output.index;
      no[index] = output;
      delete no[index].index;
      no[index].type = 'window';
      return no;

    default:
      return state;
  }
};

const displayReducer = (state = INITIAL_STATE.display, action) => {

  switch (action.type) {
    case constants.UPDATE_SETTINGS:
      let hasChanged = false;
      if (action.settings.display) {
        Object.entries(state).forEach(
          ([k, v]) => {
            if (action.settings.display[k] !== v) {
              hasChanged = true;
            }
          }
        );
      }

      return !hasChanged ? state : {
        ...action.settings.display,
        _loaded: true
      };

    default:
      return state;
  }
};

const prereleaseReducer = (state = null, action) => {
  if (action.type === constants.SET_PRERELEASE_WARNING_VERSION) {
    return VERSION;
  }
  return state;
};

const CCLIReducer = (state = '', action) => {
  if (action.type === constants.UPDATE_SETTINGS) {
    return action.settings.ccli || '';
  }
  return state;
};

const settingsReducer = combineReducers({
  ccli: CCLIReducer,
  display: displayReducer,
  outputs: outputsReducer,
  lastSeenPreReleaseWarningVersion: prereleaseReducer,
  _loaded: (s=false, _) => s,
  settingsVersion: (v=2,) => v
});

export default (state=INITIAL_STATE, action) => {
  switch (action.type) {

    case '@@INIT':
      if ((!state.settingsVersion || state.settingsVersion < 2) && state.outputWindow) {
        return migrateToSettingsV2(state);
      }

      if (!state.display || !state.outputs) {
        return {
          ...state,
          display: { ...INITIAL_STATE.display },
          outputs: [...INITIAL_STATE.outputs]
        };
      }
      return state;

      case resolved(constants.LOAD):
        return {
          ...action.payload,
          _loaded: true
        };

    default:
      return settingsReducer(state, action);
  }
};
