import React from 'react';

import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

const sortByName = (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;

const styles = {
  control: (base, state) => {
    const newStyles = {
      ...base,
      borderRadius: '0.25rem'
    };
    if (state.isFocused) {
      newStyles['borderColor'] = '#90aece';
      newStyles['boxShadow'] = '0 0 0 0.2rem rgba(68, 110, 155, 0.25)';
    }
    return newStyles;
  }
};

const VersionSelector = ({ loading, onSelect, selectedVersion, versions }) => (
  <FormGroup>
    <Label for='bible-version'>Bible version:</Label>
    <Select
      getOptionLabel={version => `${version.name} (${version.abbreviation})`}
      getOptionValue={version => version.id}
      isLoading={loading}
      onChange={(selected) => onSelect(selected.id)}
      options={Object.values(versions).sort(sortByName)}
      styles={styles}
      value={versions[selectedVersion]}
    />
  </FormGroup>
);

export default VersionSelector;
