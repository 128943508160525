import React from 'react';
import { Field } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { SketchPicker } from 'react-color';

const Cover = ({ onClick }) => (
  <div
    className='cover'
    onClick={onClick}
  ></div>
);

class ColourPickerField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showing: false
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.setState({ showing: !this.state.showing });
  }

  handleChange(color) {
    const { autoSave, field: { name }, form: { setFieldValue, submitForm } } = this.props;

    const alphaToHex = color.rgb.a < 1 ? Math.round(color.rgb.a * 255).toString(16).padStart(2, '0') : '';

    setFieldValue(name, `${color.hex === 'transparent' ? '#000000' : color.hex}${alphaToHex}`.toUpperCase(), !autoSave);
    if (autoSave) {
      submitForm();
    }
  }

  render() {
    const { autoSave, field, form: { errors, touched, handleSubmit, values }, ...props } = this.props;

    return (
      <div className='form-field'>
        <div className="d-flex align-items-center position-relative">
          <div
            className='colour-block'
            onClick={this.toggle}
            style={{ backgroundColor: field.value }}
          ></div>
          <Input
            {...field}
            {...props}
            id={field.name}
            invalid={!!touched[field.name] && !!errors[field.name]}
            onChange={(e) => {
              field.onChange(e);
              if (autoSave) {
                handleSubmit(values);
              }
            }}
            onFocus={this.toggle}
            valid={touched[field.name] && !errors[field.name]}
          />
          {
            this.state.showing ?
              <>
                <Cover onClick={() => this.setState({ showing: false })} />
                <SketchPicker
                  color={field.value}
                  onChange={this.handleChange}
                  width='50%'
                />
              </>
            : null
          }

        </div>
        {
          errors[field.name] ?
            <FormFeedback>{errors[field.name]}</FormFeedback>
          : null
        }
      </div>
    );
  }

}

export default ({ name, ...props }) => {
  return (
    <Field
      component={ColourPickerField}
      name={name}
      {...props}
    />
  );
};
