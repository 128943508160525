import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

const ConfirmationButton = ({ children, confirmText, onClick, text, ...others }) => {
  const [isShowing, setShowing] = React.useState(false);

  if (isShowing) {
    return (
      <Fragment>
        <span>
          { children }
        </span>
        <Button
          color='danger'
          onClick={(e) => { setShowing(false); onClick(e); }}
        >
          {confirmText}
        </Button>
        <Button onClick={() => setShowing(false)}>
          Cancel
        </Button>
      </Fragment>
    );
  }
  else {
    return (
      <Button
        {...others}
        onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShowing(true); } }
      >
        {text}
      </Button>
    );
  }
};

export default ConfirmationButton;
