const findBook = (book) => (b) => b.usfm === book;

export const getChaptersInRange = (bookList, startRef, endRef) => {

  const startBookIdx = bookList.findIndex(findBook(startRef.book));
  const endBookIdx = bookList.findIndex(findBook(endRef.book));
  if (startBookIdx > endBookIdx) {
    return [];
  }

  if (startBookIdx === endBookIdx) {
    if (startRef.chapter > endRef.chapter) {
      return [];
    }
    else if (startRef.chapter === endRef.chapter && startRef.verse > endRef.verse) {
      return [];
    }
  }

  const result = [
    `${startRef.book}.${startRef.chapter}`
  ];

  let curBook = startRef.book, curChapter = startRef.chapter;

  let curBookChapters = bookList.find(findBook(curBook)).chapters;

  while (curBook !== endRef.book || curChapter !== endRef.chapter) {

    if (curChapter++ > curBookChapters.length - 1) {
      curChapter = 1;
      const nextBook = bookList[bookList.findIndex(findBook(curBook)) + 1];

      if (!nextBook) {
        return result;
      }

      curBook = nextBook.usfm;
      curBookChapters = nextBook.chapters;
    }
    result.push(`${curBook}.${curChapter}`);
  }

  return result;
};

export const downloadChaptersIfNeeded = (contentState, selectedVersion, chapters, download) => {
  chapters.forEach(chapter => {
    if (
      !contentState[selectedVersion] ||
      !contentState[selectedVersion][chapter] ||
      (!contentState[selectedVersion][chapter]._loaded && !contentState[selectedVersion][chapter]._loading)
    ) {
      download(selectedVersion, chapter);
    }
  });
};

export const extractVerses = (bible, content, startRef, endRef) => {
  if (!content) {
    return;
  }


  let curRef = {
    ...startRef
  };

  let curChapter = content[`${curRef.book}.${curRef.chapter}`];

  if (!curChapter) {
    return;
  }

  let verses = [
    [`${curRef.chapter}:${curRef.verse}`, curChapter[`${curRef.book}.${curRef.chapter}.${curRef.verse}`]]
  ];

  let prevChapter = curRef.chapter;

  while (curRef.book !== endRef.book || curRef.chapter !== endRef.chapter || curRef.verse !== endRef.verse) {
    curRef = { ...curRef, verse: curRef.verse + 1 };
    let curVerse =  curChapter[`${curRef.book}.${curRef.chapter}.${curRef.verse}`];
    if (!curVerse) {
      curRef = { ...curRef, chapter: curRef.chapter + 1, verse: 1 };
      curChapter = content[`${curRef.book}.${curRef.chapter}`];

      if (!curChapter) {
        const nextBook = bible.books[bible.books.findIndex(findBook(curRef.book)) + 1];
        if (!nextBook) {
          return verses;
        }
        curRef = { book: nextBook.usfm, chapter: 1, verse: 1 };

        curChapter = content[`${curRef.book}.${curRef.chapter}`];
        if (!curChapter) {
          return verses;
        }
      }

      curVerse =  curChapter[`${curRef.book}.${curRef.chapter}.${curRef.verse}`];
    }

    const nextReference = prevChapter === curRef.chapter ? `${curRef.verse}` : `${curRef.chapter}:${curRef.verse}`;

    verses.push([
      nextReference,
      curVerse
    ]);

    prevChapter = curRef.chapter;
  }

  return verses;
};

export const deriveTitle = (bible, start, end) => {
  if (!bible.books) {
    return '';
  }
  const startBook = bible.books.find(findBook(start.book));

  let text = `${startBook.human} ${start.chapter}: ${start.verse}`;

  if (end.book === start.book) {
    if (end.chapter === start.chapter) {
      if (start.verse !== end.verse) {
        text = `${text}–${end.verse}`;
      }
    }
    else {
      text = `${text} – ${end.chapter}: ${end.verse}`;
    }
  }
  else {
    const endBook = bible.books.find(findBook(end.book));
    text = `${text} – ${endBook.human} ${end.chapter}: ${end.verse}`;
  }

  return text;
};
