import * as React from 'react';

import { ModalHeader, ModalBody, Button } from 'reactstrap';
import dayjs from 'dayjs';

import { connectedModal } from '../../modal';
import { CANCEL_SUBSCRIPTION_MODAL, DATE_FORMAT } from '../constants';
import { useDispatch } from 'react-redux';
import { cancelSubscription } from '../actions';
import { showModal } from '../../modal/actions';

const ModalWrapper = connectedModal(CANCEL_SUBSCRIPTION_MODAL);

export const CancelSubscriptionModal = ({ subscription }) => {

  const formattedPeriodEnd = dayjs(subscription.current_period_end).format(DATE_FORMAT);

  const dispatch = useDispatch();

  const confirmCancel = () => {
    dispatch(cancelSubscription());
    dispatch(showModal(null));
  };

  return (
    <ModalWrapper centered>
      <ModalHeader>
        Cancel your subscription
      </ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to cancel your subscription to JustWords
          Premium?
        </p>
        <p>
          If you cancel, then after { formattedPeriodEnd }
          { ' ' } you will lose the benefits of JustWords Premium:
        </p>
        <ul>
          <li>
            You will no longer be able to store and access unlimited songs and
            liturgy
          </li>
          <li>
            Your library and themes will no longer be synchronised across all
            your different computers
          </li>
        </ul>
        <p>
          You can continue to enjoy JustWords Premium until your current
          subscription period ends on { formattedPeriodEnd }. You may
          resubscribe at any point in the future to regain access to
          these benefits.
        </p>
        <div className='d-flex justify-content-end align-items-center'>
          <Button
            className='mr-4 text-danger'
            color='link'
            onClick={confirmCancel}
          >
            I understand, cancel my subscription
          </Button>
          <Button
            color='primary'
            onClick={() => dispatch(showModal(null))}
          >
            I want to keep my subscription
          </Button>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
};
