import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';
import { connectRoutes } from 'redux-first-router';
import axiosMiddleware from 'redux-axios-middleware';
import reduxThunk from 'redux-thunk';
import queryString from 'query-string';

import api from 'api';
import auth from 'auth';
import bible from 'bible';
import chrome from 'chrome';
import fonts from 'fonts';
import gaMiddleware from './gaMiddleware';
import modal from 'modal';
import output from 'output';
import playlist from 'playlist';
import resource from 'resource';
import subscription from 'subscription';
import settings from 'settings';
import sync from 'sync';
import theme from 'theme';
import ui from 'ui';
import renderCache from 'renderCache';

import routes from '../routes';

let composeEnhancers = compose;
if (typeof(window) !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true });
}

const rudyRoutes = {};

Object.values(routes).forEach(({ action, ...rest }) => {
  rudyRoutes[action] = rest;
});

export default (initialState={}, initialPath=null, isAuthoritative=false) => {
  const rudy = connectRoutes(
    rudyRoutes,
    {
      initialEntries: initialPath ? [initialPath] : undefined,
      querySerializer: queryString
    }
  );

  let reduxSearch = null;
  let searchReducer = null;
  if (typeof(window) !== 'undefined' && typeof(global) !== 'undefined') {
    // This avoids loading js-worker-search - which depends on clientside API -
    // when rendering on the server.
    reduxSearch = require('../search').default;
    searchReducer = require('../search').searchReducer;
  }

  let enhancers;

  if (isAuthoritative && typeof(window) !== 'undefined') {
    enhancers = composeEnhancers(
      rudy.enhancer,
      applyMiddleware(
        auth.middleware,
        axiosMiddleware(api.client),
        reduxThunk,
        rudy.middleware,
        gaMiddleware,
        sync.middleware,
        chrome.middleware,
        renderCache.middleware,
        ui.middleware
      )
    );

    if (reduxSearch) {
      enhancers = compose(
        reduxSearch({
          resourceIndexes: {
            resources: resource.searchIndexer
          },
          resourceSelector: (resourceName, state) => state[resourceName]
        }),
        enhancers
      );
    }

    if (typeof(localStorage) !== 'undefined') {
      enhancers = compose(
        persistState(
          [
            'auth',
            'bible',
            'playlist',
            'settings',
            'sync'
          ]
        ),
        enhancers
      );
    }
  }
  else if (typeof(window) !== 'undefined') {
    enhancers = composeEnhancers(
      applyMiddleware(
        chrome.middleware
      )
    );
  }
  else {
    enhancers = composeEnhancers(
      applyMiddleware(
        chrome.middleware
      )
    );
  }

  const syncReducer = sync.reducer([
    resource.constants.PUT_RESOURCE,
    resource.constants.DELETE_RESOURCE,
    resource.constants.BULK_PUT_RESOURCES,
    theme.constants.PUT_THEME,
    theme.constants.DELETE_THEME
  ]);

  const reducers = {
    auth: auth.reducer,
    bible: bible.reducer,
    chrome: chrome.reducer,
    fonts: fonts.reducer,
    location: rudy.reducer,
    modal: modal.reducer,
    output: output.reducer,
    playlist: playlist.reducer,
    renderCache: renderCache.reducer,
    resources: resource.reducer,
    settings: settings.reducer,
    subscription: subscription.reducer,
    sync: syncReducer,
    theme: theme.reducer,
    ui: ui.reducer
  };

  if (reduxSearch && isAuthoritative) {
    reducers.search = searchReducer;
  }

  const rootReducer = combineReducers(reducers);

  return {
    store: createStore(
      rootReducer,
      initialState,
      enhancers
    ),
    thunk: rudy.thunk
 };
};
