import React from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Card, CardBody } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import auth from 'auth';
import chrome from 'chrome';
import subscription from 'subscription';
import resourceModule from 'resource';

import PageWrapper from '../components/PageWrapper';
import { LibraryLimit } from 'ui';
import { StoreStateLoader } from '../components/StoreStateLoader';

let stripe;

if (typeof(window) !== 'undefined' && window._STRIPE_API_KEY) {
  stripe = loadStripe(window._STRIPE_API_KEY);
}
else {
  stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
}


const Account = ({ account, resources, showSubscribeModal }) => (
  <StoreStateLoader>
    <PageWrapper>
      <Elements stripe={stripe}>
        <h1>Your Account: {account.email}</h1>

        <Card>
          <h2>Subscription</h2>
          <CardBody>
            <subscription.SubscriptionInfo
              account={account}
              showSubscribeModal={showSubscribeModal}
            />
          </CardBody>
        </Card>

        <Card>
          <h2>Account limits</h2>
          <CardBody className='d-flex'>
            <div className='d-flex flex-column'>
              <h3>Library items</h3>
              <LibraryLimit
                count={resources.length}
                limit={account.limits.resources} />
            </div>
          </CardBody>
        </Card>

        <Card>
          <h2>Account administration</h2>
          <CardBody>
            <auth.AccountManagementPanel />
          </CardBody>
        </Card>

        <subscription.SubscribeModal />
      </Elements>
    </PageWrapper>
  </StoreStateLoader>
);

export default connect(
  createStructuredSelector({
    account: auth.selectors.accountSelector,
    resources: resourceModule.selectors.resourcesSelector
  }),
)(
  chrome.requiresPlugin()(Account)
);
