import React from 'react';
import styled, { css } from 'styled-components';
import { themeValue, ifThemeValue, mappedThemeValue } from '../utils';

const alignMap = {
  'left': 'flex-start',
  'center': 'center',
  'right': 'flex-end'
};

const LineContainer = styled.div.attrs(props => ({
  rendertype: props.type === 'scripture' ? 'normal' : props.type
}))`
  position: relative;
  align-self: ${props => mappedThemeValue(`font.${props.rendertype}.align`, alignMap, 'center')};
`;

const Annotation = styled.sup`
  display: inline;
  font-size: 0.7em;
`;

const Line = styled.div.attrs(props => ({
  rendertype: props.type === 'scripture' ? 'normal' : props.type
}))`
  font-family: ${themeValue('font.face')};
  font-size: ${themeValue('font.size')}pt;
  line-height: ${themeValue('font.lineHeight', 1.2)};
  letter-spacing: ${themeValue('font.letterSpacing', 0)}rem;
  position: relative;
  z-index: 2;

  color: ${props => themeValue(`font.${props.rendertype}.color`)};
  font-style: ${props => ifThemeValue(`font.${props.rendertype}.italic`, 'italic', 'normal')};
  font-weight: ${props => ifThemeValue(`font.${props.rendertype}.bold`, 'bold', 'normal') };
  text-align: ${props => themeValue(`font.${props.rendertype}.align`)};

  text-shadow: ${props => {
    return ifThemeValue(
      `font.${props.rendertype}.shadow.enabled`,
      css`${themeValue(`font.${props.rendertype}.shadow.color`)} ${themeValue(`font.${props.rendertype}.shadow.xOffset`)} ${themeValue(`font.${props.rendertype}.shadow.yOffset`)} ${themeValue(`font.${props.rendertype}.shadow.blur`)}`,
      css`none`
    );
  }};

  & ${Annotation} {
    display: ${
      () => ifThemeValue(
        'scripture.showAnnotations',
        'inline',
        'none'
      )
    }
  }
`;

const Outline = styled(Line)`
  color: transparent;
  -webkit-text-stroke: ${props => themeValue(`font.${props.rendertype}.outline.width`)} ${props => themeValue(`font.${props.rendertype}.outline.color`)};

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  ${
    props =>
    ifThemeValue(
      `font.${props.rendertype}.outline.enabled`,
      css`
        display: inline;
      `,
      css`display: none;`
    )
  }
`;

const LINE_TYPES = [
  'normal',
  'minor',
  'scripture'
];

const LineRenderer = ({ line: [text, type, annotation] }) => {
  const typeText = LINE_TYPES[type];
  return (
    <LineContainer type={typeText}>
      <Line type={typeText}>
        <Annotation>{annotation}</Annotation>
        {text}
      </Line>
      <Outline type={typeText}>
        <Annotation>{annotation}</Annotation>
        {text}
      </Outline>
    </LineContainer>
  );
};

export default LineRenderer;
