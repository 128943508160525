import React from 'react';

export default ({ className }) => (
  <div className={className}>
    <p>
      Subscribe to JustWords Premium for £5 per month and:
    </p>
    <ul>
      <li>Store unlimited songs</li>
      <li>Store unlimited liturgy</li>
      <li>Sync your account across all devices</li>
    </ul>
  </div>
);
