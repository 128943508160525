import ReactGA from 'react-ga';

import modal from 'modal';
import renderCache from 'renderCache';

export default () => next => action => {
  if (action.type.startsWith('location/')) {
    ReactGA.pageview(action.meta.location.current.pathname);
  }
  else if (action.type === modal.constants.SHOW_MODAL) {
    if (action.modal) {
      ReactGA.modalview(action.modal);
    }
  }
  else if (action.type === renderCache.constants.CACHE_DISPLAY_MODEL) {
    const startTime = action.resource.meta.renderStartTime;
      const now = Date.now();
      console.info(`Render of ${action.resource.id} took ${now - startTime}ms`); // eslint-disable-line no-console
      ReactGA.timing({
        category: 'Rendering',
        variable: 'resource_render',
        value: now - startTime
      });
  }
  return next(action);
};
