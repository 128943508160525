import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { versionsSelector, selectedVersionSelector, bibleContentSelector } from '../selectors';
import * as actions from '../actions';
import { getChaptersInRange, downloadChaptersIfNeeded, extractVerses, deriveTitle } from '../functions';
import VersionSelector from './VersionSelector';
import ReferenceSelector from './ReferenceSelector';
import BiblePreview from './BiblePreview';
import { Button } from 'reactstrap';

const isBefore = (books, first, second) => {
  if (books.findIndex((b) => b.usfm === first.book) > books.findIndex((b) => b.usfm === second.book)) {
    return false;
  }
  else if (first.book === second.book) {
    if (first.chapter > second.chapter) {
      return false;
    }
    else if (first.chapter === second.chapter) {
      return first.verse < second.verse;
    }
  }
  return true;
};

const INITIAL_REFERENCE = {
  book: 'GEN',
  chapter: 1,
  verse: 1
};

const BibleEditor = ({
  content,
  loading,
  loadChapter,
  loadVersion,
  loadVersions,
  onSelectVerses,
  selectVersion,
  selectedVersion,
  setAttribution,
  setTitle,
  versions
}) => {

  const [start, setStart] = useState(INITIAL_REFERENCE);
  const [end, setEnd] = useState(INITIAL_REFERENCE);

  const books = useMemo(
    () => versions[selectedVersion].books,
    [selectedVersion, versions]
  );

  // Load available English translations on component mount
  useEffect(
    () => {
      loadVersions('eng');
    },
    [loadVersions]
  );

  // When a new translation is selected, load it if it's not already loaded,
  // and set attribution if it is
  useEffect(
    () => {
      if (selectedVersion && !versions[selectedVersion]?.books) {
        loadVersion(selectedVersion);
      }
      if (selectedVersion && versions[selectedVersion]) {
        setAttribution(versions[selectedVersion].abbreviation);
      }
      if (!selectedVersion) {
        selectVersion(1);
      }
    },
    [loadVersion, selectVersion, selectedVersion, setAttribution, versions]
  );

  // Load content if needed
  useEffect(
    () => {
      if (books && !isBefore(books, end, start)) {
        const chaps = getChaptersInRange(versions[selectedVersion].books, start, end);
        downloadChaptersIfNeeded(content, selectedVersion, chaps, loadChapter);
      }
    },
    [books, content, end, loadChapter, selectedVersion, start, versions]
  );

  // Compile content into verses and title
  useEffect(
    () => {
      if (content && content[selectedVersion] && versions && versions[selectedVersion]) {
        const verses = extractVerses(versions[selectedVersion], content[selectedVersion], start, end);
        const title = deriveTitle(versions[selectedVersion], start, end);
        onSelectVerses(verses);
        setTitle(title);
      }
    },
    [content, end, onSelectVerses, selectedVersion, setTitle, start, versions]
  );

  const forceReload = () => {
    if (books && !isBefore(books, end, start)) {
      const chaps = getChaptersInRange(versions[selectedVersion].books, start, end);
      chaps.forEach(
        c => loadChapter(selectedVersion, c)
      );
    }
  };

  const setStartRef = (ref) => {
    const newEnd = isBefore(books, end, ref) ? ref : end;
    setStart(ref);
    if (newEnd !== end) {
      setEnd(newEnd);
    }
  };

  let isInvalidRange = false;

  if (selectedVersion && versions[selectedVersion]) {
    isInvalidRange = start && end && books && isBefore(books, end, start);
  }

  return (
    <Fragment>
      <div className='editor-controls'>
        <div className='reference-controls'>
          <VersionSelector
            loading={loading}
            onSelect={selectVersion}
            selectedVersion={selectedVersion}
            versions={versions}
          />
          Start:
          <ReferenceSelector
            onChange={setStartRef}
            reference={start}
            selectedVersion={versions[selectedVersion]}
          />
          End:
          <ReferenceSelector
            invalid={isInvalidRange}
            onChange={setEnd}
            reference={end}
            selectedVersion={versions[selectedVersion]}
          />
        </div>
        <Button
          color='link'
          disabled={loading}
          onClick={forceReload}
        >
          Reload passage
        </Button>
      </div>
      <div>
        <BiblePreview
          content={content[selectedVersion]}
          endRef={end}
          startRef={start}
          version={versions[selectedVersion]} />
      </div>
    </Fragment>
  );
};

export default connect(
  createStructuredSelector({
    content: bibleContentSelector,
    selectedVersion: selectedVersionSelector,
    versions: versionsSelector
  }),
  actions
)(BibleEditor);
