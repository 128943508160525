import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';

import ColourPickerField from 'components/ColourPickerField';
import FormField from 'components/FormField';

export default () => (
  <Fragment>
    <FormGroup className='linear'>
      <Label for='background'>Background colour:</Label>
      <ColourPickerField
        autoSave
        name='background' />
    </FormGroup>
    <FormGroup className='linear'>
      <Label for='verticalAlignment'>Vertical alignment:</Label>
      <FormField
        autoSave
        name='verticalAlignment'
        type='select'>
        <option value='top'>Top</option>
        <option value='middle'>Middle</option>
        <option value='bottom'>Bottom</option>
      </FormField>
    </FormGroup>
    <FormGroup className='linear'>
      <Label for='fadeToBlackTime'>Fade to black duration (sec):</Label>
      <FormField
        autoSave
        min={0}
        name='fadeToBlackTime'
        step={0.1}
        type='number'
      />
    </FormGroup>
  </Fragment>
);
