import * as constants from './constants';

const INITIAL_STATE = {
  cache: {},
  queue: [],
  processing: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case constants.GENERATE_DISPLAY_MODEL:
      if (!state.queue.find(r => r.id === action.resource.id)) {
        return {
          ...state,
          queue: [
            ...state.queue,
            {
              ...action.resource,
              meta: {
                ...action.resource.meta,
                renderStartTime: Date.now()
              }
            }
          ]
        };
      }
      else {
        return state;
      }

    case constants.PROCESSING_DISPLAY_MODEL:
      const queuedResource = state.queue.find(r => r.id === action.resource.id);
      return {
        ...state,
        queue: state.queue.filter(r => r !== queuedResource),
        processing: [...state.processing, queuedResource]
      };

    case constants.CACHE_DISPLAY_MODEL:
      const { resource } = action;
      return {
        ...state,
        cache: {
          ...state.cache,
          [resource.id]: resource
        },
        processing: state.processing.filter(r => r.id !== resource.id)
      };

    case constants.INVALIDATE_CACHE:
      return {
        ...state,
        cache: {}
      };

    default:
      return state;
  }
};
