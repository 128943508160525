import * as React from 'react';

import RenderedLineGroup from './RenderedLineGroup';
import { clickHandler } from '../functions';

const RenderedBlock = ({
  addressSetByClick,
  block,
  blockIndex,
  descriptor,
  live,
  liveGroupAddress,
  onBlockClick,
  onGroupClick,
  onLineClick
}) => (
  <div
    className={`block ${block.type} ${live ? 'live' : ''}`}
    data-descriptor={descriptor}
    data-index={blockIndex}
    data-original-type={block.originalType}
    data-type={block.type}
    onClick={clickHandler(onBlockClick, block, blockIndex)}
  >
    {
      block.content.map(
        (lineGroup, idx) => (
          <RenderedLineGroup
            addressSetByClick={addressSetByClick}
            blockIndex={blockIndex}
            groupIndex={idx}
            key={idx}
            lineGroup={lineGroup}
            live={live && idx === liveGroupAddress}
            onGroupClick={onGroupClick}
            onLineClick={onLineClick}
          />
        )
      )
    }
  </div>
);

export default RenderedBlock;
