import * as yup from 'yup';
import * as constants from './constants';

const Line = yup.array().min(2).max(2);

const LineGroup = yup.array().of(Line).min(1);

const Title = yup.string().ensure().trim().min(1, 'Title must not be empty');

const resourceType = yup.string().oneOf(['Song', 'Liturgy']);

const songBlockType = yup.string().oneOf(['verse', 'chorus', 'bridge']);

const liturgyBlockType = yup.string().oneOf(['section']);

const timestamp = yup.date().transform(
  function (castValue, originalValue) {
    return this.isType(castValue) ? castValue : new Date(originalValue);
  }
);

const SongMeta = yup.object({
  author: yup.string().ensure().required('Author is required'),
  licence: yup.string().oneOf(Object.keys(constants.LICENCE_TYPES)).required(),
  copyright: yup.string().when(
    'licence', {
      is: (l) => l === 'CCLI',
      then: yup.string().ensure().required('Copyright information is required for CCLI-licensed songs')
    }
  ),
  created: timestamp,
  modified: timestamp,
  lastUsed: timestamp
});

const LiturgyMeta = yup.object({
  attribution: yup.string()
});

const SongBlock = yup.object().shape({
  type: songBlockType,
  content: yup.array().of(LineGroup).min(1)
});

const LiturgyBlock = yup.object().shape({
  type: liturgyBlockType,
  content: yup.array().of(LineGroup).min(1)
});

export const Resource = yup.object().shape({
  title: Title.required(),
  type: resourceType.required(),
  content: yup.array().required().when(
    'type', {
      is: constants.TYPES.SONG,
      then: yup.array().of(SongBlock).min(1),
      otherwise: yup.array().of(LiturgyBlock).min(1)
    }
  ),
  meta: yup.object().when(
    'type', {
      is: constants.TYPES.SONG,
      then: SongMeta,
      otherwise: LiturgyMeta
    }
  )
});

export const FormResource = yup.object({
  resource: Resource
});
