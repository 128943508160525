import React from 'react';

import { Button, ButtonGroup } from 'reactstrap';
import { AutoSizer, List } from 'react-virtualized';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

import resourceModule from 'resource';
import ConfirmationButton from './ConfirmationButton';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const LibraryEntry = ({ addToPlaylist, deleteResource, extraButtons, isLive, showModal, onClick, onDoubleClick, resource, address, style }) => {
  const matchingLine = resource.content[address[0]].content[address[1]][address[2]][0];

  const ExtraButtons = extraButtons;

  return (
    <div
      className={`library-entry ${isLive ? 'selected' : ''}`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={style}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={ resourceModule.constants.ICONS[resource.type] || resourceModule.constants.ICONS.DEFAULT }
        size='2x'
      />{ ' ' }
      <span className='title'>
        {resource.title}
      </span>
      <span className='content-preview text-muted d-flex justify-content-between'>
        <span>
          { matchingLine }
        </span>
        <span>
          { resource.meta.lastUsed && `Last used ${dayjs(resource.meta.lastUsed).fromNow()}` }
        </span>
      </span>
      <ButtonGroup className='library-tools'>
        <Button
          className='px-1'
          color='primary'
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); addToPlaylist(resource);}}
          title="Add to playlist"
          >
          <FontAwesomeIcon
            fixedWidth
            icon={faPlus} />
        </Button>
        <Button
          className='px-1'
          color='secondary'
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); showModal('resource/edit', resource); }}
          title="Edit"
        >
          <FontAwesomeIcon
            fixedWidth
            icon={faPencilAlt} />
        </Button>
        { extraButtons && <ExtraButtons resource={resource} /> }
        <ConfirmationButton
          className='px-1'
          color='danger'
          confirmText={`Delete '${resource.title}' forever`}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); deleteResource(resource); } }
          text={<FontAwesomeIcon
            fixedWidth
            icon={faTrashAlt} />}
        />
      </ButtonGroup>
    </div>
  );
};

export default ({
  addToPlaylist,
  deleteResource,
  extraButtons,
  liveResource,
  previewResource,
  searchResults,
  selectedIndex,
  setBlockAddress,
  showHelpText,
  showModal
}) => (
  <div className="d-flex flex-column">
    <div className="library-contents">
      <AutoSizer>
        {
          ({ height, width }) => (
            <List
              height={height}
              rowCount={searchResults.length}
              rowHeight={64}
              rowRenderer={
                ({ index, style }) => {
                  const [ resource, address ] = searchResults[index];
                  return !!resource ? (
                    <LibraryEntry
                      address={address}
                      addToPlaylist={addToPlaylist}
                      deleteResource={deleteResource}
                      extraButtons={extraButtons}
                      isLive={selectedIndex === index}
                      key={resource.id}
                      onClick={() => previewResource(resource)}
                      onDoubleClick={() => {
                          addToPlaylist(resource);
                          previewResource(null);
                          liveResource(resource);
                          setBlockAddress(
                            address[0],
                            0, // We're working on the pre-rendered resource so linegroup addresses will be inaccurate
                          );
                          showModal(null);
                        }
                      }
                      resource={resource}
                      showModal={showModal}
                      style={style}
                    />
                  ) : null;
                }
              }
              scrollToIndex={selectedIndex >= 0 ? selectedIndex : undefined}
              width={width}
            />
          )
        }
      </AutoSizer>
    </div>
    {
      showHelpText && (
        <small className="text-muted text-center">
          {
            selectedIndex === -1 ?
            'Press Up/Down to select an item or click to preview (double click to go live)'
            : 'Press Enter to preview selection, Ctrl+Enter to go live, Shift+Enter to add to playlist'
          }
        </small>
      )
    }

  </div>
);
