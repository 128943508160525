import * as constants from './constants';

const INITIAL_STATE = {
  live: true,
  blanked: false,
  previewResource: null,
  liveResource: null,
  editedResource: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case constants.GO_LIVE:
      return {
        ...state,
        live: true
      };

      case constants.GO_OFF:
        return {
          ...state,
          live: false
        };

      case constants.TOGGLE_BLANK:
        return {
          ...state,
          blanked: !state.blanked
        };

      case constants.PREVIEW_RESOURCE:
        return {
          ...state,
          previewResource: action.resource ? action.resource.id : null
        };

      case constants.LIVE_RESOURCE:
        return {
          ...state,
          liveResource: action.resource ? action.resource.id : null
        };

    default:
      return state;
  }
};
