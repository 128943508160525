import React from 'react';

import compare from 'semver/functions/compare';

import { getExtensionID, getExtensionVersion } from '../functions';
import InstallFromStorePrompt from './InstallFromStorePrompt';

class RequiresPlugin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pluginID: null,
      pluginVersion: null,
      tries: 0,
      failed: false
    };
    this.foundExtension = this.foundExtension.bind(this);
    this.stillSearching = this.stillSearching.bind(this);
    this.searchInterval = null;
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      const search = () => {
        const maybeExtension = getExtensionID();
        if (maybeExtension) {
          this.foundExtension(maybeExtension);
          if (this.searchInterval) {
            window.clearInterval(this.searchInterval);
          }
          return true;
        }
        else {
          this.stillSearching();
          return false;
        }
      };

      search();
      this.searchInterval = window.setInterval(search, 100);
    }
  }

  stillSearching() {
    const tries = this.state.tries + 1;
    this.setState({ tries });
    if (tries > this.props.settings.maxRetries) {
      this.setState({
        failed: true
      });
      if (this.searchInterval) {
        window.clearInterval(this.searchInterval);
      }
    }
  }

  foundExtension(id) {
    this.setState({
      pluginID: id,
      pluginVersion: getExtensionVersion(),
      failed: false
    });
  }

  render() {
    const { children, settings } = this.props;
    const { minVersion } = settings;
    const { failed, pluginID, pluginVersion, tries } = this.state;

    if (pluginID !== null) {
      if (
        !minVersion ||
        (pluginVersion && compare(minVersion, pluginVersion) <= 0)
      ) {
        return children;
      }
      else {
        return (
          <p>
            Your plugin is too old! Please ensure you have the most recent
            version of the plugin installed. (You have version {pluginVersion || 'unknown'}
            , but version {minVersion} or later is needed.)
          </p>
        );
      }
    }
    else if (failed) {
      const NotFound = settings.notFound;
      return <NotFound />;
    }
    else {
      const Searching = settings.searching;
      return <Searching
        maxTries={settings.maxRetries}
        tries={tries} />;
    }
  }
}

const DEFAULT_SETTINGS = {
  maxRetries: 10,
  notFound: InstallFromStorePrompt,
  searching: () => <p>Searching for plugin</p>
};


export default (settings) => (WrappedComponent) => (props) => {
  const mySettings = {
    ...DEFAULT_SETTINGS,
    ...settings
  };
  return (
    <RequiresPlugin settings={mySettings}>
      <WrappedComponent {...props} />
    </RequiresPlugin>
  );
};
