import * as React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectGroup } from '@fortawesome/free-regular-svg-icons';
import { faChessKing, faChessPawn, faUndo, faRedo, faAlignLeft } from '@fortawesome/free-solid-svg-icons';

const SongEditorToolbar = ({ editor }) => (
  <ButtonGroup>
    <Button
      color='primary'
      onClick={editor.setVerse}
    >
      Verse
    </Button>
    <Button
      color='primary'
      onClick={editor.setChorus}
    >
      Chorus
    </Button>
    <Button
      color='primary'
      onClick={editor.setBridge}
    >
      Bridge
    </Button>
  </ButtonGroup>
);

const LiturgyEditorToolbar = ({ editor }) => (
  <ButtonGroup>
    <Button
      color='primary'
      onClick={editor.setSection}
    >
      <FontAwesomeIcon
        className='ml-0'
        icon={faAlignLeft}
      />
      Section
    </Button>
  </ButtonGroup>
);

export const EditorToolbar = ({ editor, resourceType }) => {
  return (
    <div className='toolbar editor-controls mb-0 justify-content-between'>
      {
        resourceType === 'Song' && <SongEditorToolbar editor={editor} />
      }
      {
        resourceType === 'Liturgy' && <LiturgyEditorToolbar editor={editor} />
      }

      <Button
        color='primary'
        onClick={editor.groupLines}
      >
        <FontAwesomeIcon
          className='ml-0'
          icon={faObjectGroup}
        />
        Group lines
      </Button>

      <ButtonGroup>
        <Button onClick={editor.setMajor}>
          <FontAwesomeIcon
            className='ml-0'
            icon={faChessKing}
          />
          Major
        </Button>
        <Button onClick={editor.setMinor}>
          <FontAwesomeIcon
            className='ml-0'
            icon={faChessPawn}
          />
          Minor
        </Button>
      </ButtonGroup>

      <ButtonGroup>
        <Button
          disabled={editor.history.undos.length === 0}
          onClick={editor.undo}
        >
          <FontAwesomeIcon
            className='ml-0'
            icon={faUndo}
          />
          Undo
        </Button>
        <Button
          disabled={editor.history.redos.length === 0}
          onClick={editor.redo}
        >
          <FontAwesomeIcon
            className='ml-0'
            icon={faRedo}
          />
          Redo
        </Button>
      </ButtonGroup>
    </div>
  );
};
