import React from 'react';
import { Button } from 'reactstrap';

import { Workbox, messageSW } from 'workbox-window';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import VERSION from '../version';

const Version = () => {

  const [updateAvailable, setUpdateAvailable] = React.useState(false);
  const [registration, setRegistration] = React.useState(null);
  const workbox = React.useRef();

  React.useEffect(
    () => {
      const doRegister = async () => {
        if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
          const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

          workbox.current = new Workbox(swUrl);

          workbox.current.addEventListener(
            'waiting', () => setUpdateAvailable(true)
          );

          workbox.current.addEventListener(
            'waiting', () => setUpdateAvailable(true)
          );

          const reg = await workbox.current.register();
          setRegistration(reg);

        }
      };

      doRegister();
    },
    []
  );

  if (updateAvailable) {
    return (
      <span>
        <FontAwesomeIcon icon={faInfoCircle} /> {' '}
        New version available! {' '}
        <Button
          color='link'
          onClick={
            () => {
              workbox.current.addEventListener(
                'controlling',
                () => window.location.reload()
              );
              messageSW(registration.waiting, { type: 'SKIP_WAITING' });
            }
          }
          title={`JustWords ${VERSION}. A newer version has been downloaded and will run when you reload the page.`}
        >
          Click to reload
        </Button>
      </span>
    );
  }

  return (
    <span>
      JustWords { VERSION }.
    </span>
  );
};

export default Version;
