import React from 'react';

import { Button, ButtonGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { themeState } from '../selectors';
import * as actions from '../actions';
import { sortByName } from '../functions';
import classNames from 'classnames';
import ThemeForm from './ThemeForm';

import modal, { connectedModal, CloseModalButton } from 'modal';

const ConnectedModal = connectedModal('theme');

class ThemeEditorModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      themeIndex: null,
      workingCopies: {}
    };
    this.saveWorkingCopy = this.saveWorkingCopy.bind(this);
    this.saveAll = this.saveAll.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.themeIndex === null || !props.themes[state.themeIndex]) {
      return {
        themeIndex: props.currentTheme
      };
    }
    return null;
  }

  saveWorkingCopy(index, theme) {
    this.setState({
      workingCopies: {
        ...this.state.workingCopies,
        [index]: theme
      }
    });
  }

  saveAll() {
    const { updateTheme } = this.props;

    Object.keys(this.state.workingCopies).forEach(
      (idx) => {
        updateTheme(this.state.workingCopies[idx]);
      }
    );

    this.setState({ workingCopies: {} });
  }

  render() {
    const { showModal, themes } = this.props;

    const selectedTheme = this.state.workingCopies[this.state.themeIndex] || themes[this.state.themeIndex];

    return (
      <ConnectedModal className='full-size'>
        <ModalHeader>Theme Editor</ModalHeader>
        <ModalBody className='theme-editor'>
          <div className='item-list'>
            <h5>Themes</h5>
            <ul>
              {
                Object.values(themes).sort(sortByName).map(
                  (t) => (
                    <li
                      className={classNames({
                        'live': t.id === this.state.themeIndex
                      })}
                      key={t.id}
                      onClick={() => this.setState({ themeIndex: t.id })}
                    >
                      {t.name}{ this.state.workingCopies[t.id] ? ' (modified)': '' }
                    </li>
                  )
                )
              }
            </ul>
            <ButtonGroup className='library-tools'>
              <Button
                color='primary'
                onClick={this.props.newTheme}
              >
                New
              </Button>
              <Button
                onClick={() => this.props.duplicateTheme(themes[this.state.themeIndex])}
              >
                Copy
              </Button>
              <Button
                color='danger'
                disabled={Object.values(themes).length <= 1}
                onClick={
                  () => this.props.deleteTheme({
                    id: themes[this.state.themeIndex].id
                  })
                }
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
          <div className='theme-form'>
            {
              selectedTheme ?
                <ThemeForm
                  onSubmit={(t) => this.saveWorkingCopy(this.state.themeIndex, t)}
                  theme={selectedTheme}
                />
              :
                <p>Please select a theme to edit.</p>
            }

          </div>
        </ModalBody>
        <ModalFooter>
          <CloseModalButton color='secondary'>Close without saving</CloseModalButton>
          <Button
            color='primary'
            disabled={Object.keys(this.state.workingCopies).length === 0}
            onClick={this.saveAll}
          >
            Apply
          </Button>
          <Button
            color='primary'
            disabled={Object.keys(this.state.workingCopies).length === 0}
            onClick={() => { this.saveAll(); showModal(null); }}
          >
            Save and close
          </Button>
        </ModalFooter>
      </ConnectedModal>
    );
  }
}

export default connect(
  themeState,
  {
    ...actions,
    showModal: modal.actions.showModal
  }
)(ThemeEditorModal);
