import React from 'react';
import { connect } from 'react-redux';
import { themeState } from '../selectors';
import { selectTheme } from '../actions';
import { sortByName } from '../functions';

import { Form, FormGroup, Input, Label } from 'reactstrap';


const ThemeChooser = ({ currentTheme, selectTheme, themes }) => (
  <Form
    className='d-flex flex-grow-1'
    inline>
    <FormGroup className='flex-grow-1'>
      <Label>Theme:</Label>
      <Input
        className='theme-select flex-grow-1'
        onChange={(e) => selectTheme(e.target.value )}
        type='select'
        value={currentTheme}
      >
        {
          Object.values(themes).sort(sortByName).map(
            (t) => <option
              key={t.id}
              value={t.id}>{t.name}</option>
          )
        }
      </Input>
    </FormGroup>
  </Form>
);

export default connect(
  themeState,
  {
    selectTheme
  }
)(ThemeChooser);
