import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Progress } from 'reactstrap';

import 'style/main.scss';
import ui, { AppModals, MainWindow } from 'ui';
import chrome from 'chrome';
import settings from 'settings';
import ErrorBoundary from './components/ErrorBoundary';
import { withBrowserGate } from './components/BrowserGate';
import PageWrapper from './components/PageWrapper';
import { StoreStateLoader } from './components/StoreStateLoader';
import { OutputWindows } from 'output';
import { RenderQueueProcessor } from 'renderCache';

const App = () => {
  const blanked = useSelector(ui.selectors.blankedSelector);
  const canvasSize = useSelector(settings.selectors.displaySettingsSelector);
  return (
    <StoreStateLoader>
      <ErrorBoundary>
        <MainWindow />
      </ErrorBoundary>
      <ErrorBoundary>
        <AppModals />
      </ErrorBoundary>
      <OutputWindows blanked={blanked} />
      <RenderQueueProcessor canvasSize={canvasSize} />
    </StoreStateLoader>
  );
};

const ConnectedApp = connect(
  createStructuredSelector({
    blanked: ui.selectors.blankedSelector,
    live: ui.selectors.liveSelector
  })
)(App);

const Searching = ({ tries, maxTries }) => (
  <PageWrapper>
    <div className='modeless'>
      <p>Searching for plugin...</p>
      <Progress
        animated
        max={maxTries}
        value={tries}
      />
    </div>
  </PageWrapper>
);

const NotFound = () => (
  <PageWrapper cardClassName='text-center'>
    <chrome.InstallFromStorePrompt />
  </PageWrapper>
);

export default chrome.requiresPlugin({
  minVersion: '0.2.2',
  searching: Searching,
  notFound: NotFound
})(
  withBrowserGate(ConnectedApp)
);
