const parseContent = (content) => {
  const parser = new DOMParser();
  return parser.parseFromString(content, 'text/html');
};

export const extractVerseData = (content) => {
  const result = {};

  const parsedContent = parseContent(content);

  const verses = parsedContent.documentElement.querySelectorAll('.verse[data-usfm]');

  verses.forEach(verse => {
    const usfm = verse.dataset.usfm;

    let contentString = '';

    verse.querySelectorAll('.content').forEach(
      (c) => contentString += c.innerText
    );

    if (result[usfm]) {
      result[usfm] += ' ' + contentString;
    }
    else {
      result[usfm] = contentString;
    }
  });

  return result;
};
