import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import auth from 'auth';
import modal, { connectedModal } from 'modal';
import SubscriptionForm from './SubscriptionForm';
import { getSubscription, subscribe } from '../actions';
import SubscriptionBenefits from './SubscriptionBenefits';

const ConnectedModal = connectedModal('subscribe');

const SubscribeModal = ({ email, subscribe }) => {
  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const cardElement = elements.getElement('card');

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email
      }
    });
    if (result.paymentMethod) {
      subscribe(result.paymentMethod.id).then(
        () => {
          setLoading(false);
          dispatch(modal.actions.showModal(null));
          dispatch(getSubscription());
        }
      );
    }
    else if (result.error) {
      setError(result.error.message);
    }
  };

  return (
    <ConnectedModal centered>
      <ModalHeader>
        Subscribe
      </ModalHeader>
      <ModalBody>
        <div className='d-flex mx-4'>
          <SubscriptionBenefits className='flex-grow-1 border-right px-4' />
          <SubscriptionForm
            error={error}
            setError={setError}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color='link'
          onClick={() => dispatch(modal.actions.showModal(null))}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={isLoading}
          onClick={
            (evt) => {
              setLoading(true);
              handleSubmit(evt);
            }
          }
        >
          {
            isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
              />
            )
          }
          Subscribe now
        </Button>
      </ModalFooter>
    </ConnectedModal>
  );
};

export default connect(
  auth.selectors.accountSelector,
  {
    subscribe
  }
)(
  ({ email, subscribe }) => (
    <SubscribeModal
      email={email}
      subscribe={subscribe}
    />
  )
);
