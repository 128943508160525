export const LOCAL_STORAGE_KEY = 'justwords_settings';
export const LOAD = 'settings/LOAD';
export const SAVE = 'settings/SAVE';

export const UPDATE_SETTINGS = 'settings/UPDATE';
export const APPLY_DISPLAY_SETTINGS = 'settings/APPLY_DISPLAY_SETTINGS';
export const RETRIEVE_DISPLAY_SETTINGS = 'settings/RETRIEVE_DISPLAY_SETTINGS';
export const SET_PRERELEASE_WARNING_VERSION = 'settings/SET_PRERELEASE_WARNING_VERSION';

export const SAVE_OUTPUT_SETTINGS = 'settings/SAVE_OUTPUT_SETTINGS';
export const ADD_OUTPUT = 'settings/ADD_OUTPUT';
export const REMOVE_OUTPUT = 'settings/REMOVE_OUTPUT';
