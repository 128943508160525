import { createSelector } from 'reselect';

const authState = (state) => state.auth;

export const isLoggedIn = createSelector(
  authState,
  (auth) => !!auth.account
);

export const accountSelector = createSelector(
  authState,
  (auth) => auth.account
);

export const accessTokenSelector = createSelector(
  authState,
  (auth) => auth.access
);

export const refreshTokenSelector = createSelector(
  authState,
  (auth) => auth.refresh
);

export const tokenExpirySelector = createSelector(
  authState,
  (auth) => auth.expiry
);

export const hasFeature = (key) => createSelector(
  authState,
  (auth) => {
    if (auth.account && auth.account.permissions) {
      return auth.account.permissions.includes(key);
    }
    return false;
  }
);

export const limitSelector = (key) => createSelector(
  authState,
  (auth) => {
    if (auth.account && auth.account.limits) {
      return auth.account.limits[key] || 0;
    }
    return 0;
  }
);
