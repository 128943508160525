import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { Viewer } from '../modules/changelog';

import CHANGELOG from '../changelog.json';

export default () => {

  const [isShowing, setShowing] = React.useState(false);
  const toggle = () => setShowing(!isShowing);

  return (
    <React.Fragment>
      <Button
        color='link'
        onClick={toggle}
      >
        Release notes
      </Button>
      <Modal
        isOpen={isShowing}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          Release notes
        </ModalHeader>
        <ModalBody>
          <Viewer changelog={CHANGELOG} />
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            onClick={toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );

};
