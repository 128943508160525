import * as constants from './constants';

export const goLive = () => ({
  type: constants.GO_LIVE
});

export const goOff = () => ({
  type: constants.GO_OFF
});

export const toggleBlank = () => ({
  type: constants.TOGGLE_BLANK
});

export const previewResource = (resource) => ({
  type: constants.PREVIEW_RESOURCE,
  resource: resource
});

export const liveResource = (resource) => ({
  type: constants.LIVE_RESOURCE,
  resource: resource
});
