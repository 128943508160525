import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Progress } from 'reactstrap';

import withGracefulUnmount from './withGracefulUnmount';
import PageWrapper from './PageWrapper';

import resource from 'resource';
import settings from 'settings';
import theme from 'theme';
import { refreshAccountData } from '../modules/auth/actions';

const Loading = ({ total, remaining }) => (
  <PageWrapper>
    <div className='modeless'>
      <p>Loading...</p>
      <Progress
        animated
        max={total}
        value={total - remaining}
      />
    </div>
  </PageWrapper>
);

export const StoreStateLoader = withGracefulUnmount(
  ({ children }) => {
    const resourceLoadState = useSelector(resource.selectors.resourceLoadStateSelector);
    const settingsLoadState = useSelector(settings.selectors.settingsLoadStateSelector);
    const themeLoadState = useSelector(theme.selectors.themeLoadStateSelector);
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(!(resourceLoadState && themeLoadState));

    React.useEffect(
      () => {
        if (!settingsLoadState) {
          dispatch(settings.actions.load());
        }
        else if (!resourceLoadState) {
          dispatch(resource.actions.load());
        }
        else if (!themeLoadState) {
          dispatch(theme.actions.load());
        }
        else {
          setLoading(false);
        }
      },
      [dispatch, resourceLoadState, settingsLoadState, themeLoadState]
    );


    React.useEffect(
      // Save resources and themes to extension's local storage on unmount
      () => {
        refreshAccountData()(dispatch);
        return () => {
          dispatch(resource.actions.save());
          dispatch(theme.actions.save());
        };
      },
      [dispatch]
    );

    const states = [resourceLoadState, settingsLoadState, themeLoadState];
    const remaining = states.reduce(
      (total, value) => value ? total : total + 1,
      0
    );

    if (loading) {
      return (
        <Loading
          remaining={remaining}
          total={states.length}
        />
      );
    }

    return (
      <>
        {children}
      </>
    );
  }
);
