import React from 'react';
import { Field } from 'formik';
import { Input, InputGroup, FormFeedback } from 'reactstrap';
import FontSelector from './FontSelector';

class FontSelectorField extends React.PureComponent {

  render() {
    const { autoSave, field, form: { errors, touched, submitForm, setFieldValue }, ...props } = this.props;
    return (
      <div className='form-field'>
        <div className="d-flex align-items-center">
          <InputGroup>
            <Input
              {...field}
              {...props}
              id={field.name}
              invalid={!!touched[field.name] && !!errors[field.name]}
              onChange={(e) => {
                setFieldValue(field.name, e.target.value, !autoSave);
                if (autoSave) {
                  submitForm();
                }
              }}
              valid={touched[field.name] && !errors[field.name]}
            />
            <FontSelector
              currentFont={field.value}
              onSelect={
                (f) => {
                  setFieldValue(field.name, f, !autoSave);
                  if (autoSave) {
                    submitForm();
                  }
                }
              }
            />
          </InputGroup>
        </div>
        {
          errors[field.name] ?
            <FormFeedback>{errors[field.name]}</FormFeedback>
          : null
        }
      </div>
    );
  }

}

export default ({ name, ...props }) => {
  return (
    <Field
      component={FontSelectorField}
      name={name}
      {...props}
    />
  );
};
