import { useField } from 'formik';
import * as React from 'react';

import { FormGroup, Input, Label } from 'reactstrap';

const COMMON_OUTPUT_SIZES = [
  { width: 1024, height: 768, label: '1024x768 (VGA)' },
  { width: 1366, height: 768, label: '1366x768' },
  { width: 1280, height: 720, label: '1280x720 (WXGA)' },
  { width: 1280, height: 1024, label: '1280x1024 (SXGA)' },
  { width: 1440, height: 900, label: '1440x900 (WXGA+)' },
  { width: 1600, height: 900, label: '1600x900 (HD+)' },
  { width: 1680, height: 1050, label: '1680x1050 (WSXGA+)' },
  { width: 1920, height: 1080, label: '1920x1080 (Full HD)' },
];

export const DisplaySize = () => {

  // eslint-disable-next-line no-unused-vars
  const [displayWidth, _widthMeta, widthHelpers] = useField('display.width');
  // eslint-disable-next-line no-unused-vars
  const [displayHeight, _heightMeta, heightHelpers] = useField('display.height');

  let initialSelectedSize = -1;
  COMMON_OUTPUT_SIZES.forEach(
    (os, idx) => {
      if (os.width === displayWidth.value && os.height === displayHeight.value) {
        initialSelectedSize = idx;
      }
    }
  );

  const [selectedSize, setSelectedSize] = React.useState(initialSelectedSize);

  const [custom, setCustom] = React.useState(selectedSize === -1);

  const handleSelectionChange = (e) => {
    const index = e.target.value;
    if (index >= 0) {
      const target = COMMON_OUTPUT_SIZES[index];
      heightHelpers.setValue(target.height);
      widthHelpers.setValue(target.width);
      setCustom(false);
    }
    else {
      setCustom(true);
    }
    setSelectedSize(index);
  };

  return (
    <FormGroup className='canvas-form'>
      <div className='mr-4'>
        <h5>Common sizes:</h5>
        <Input
          onChange={handleSelectionChange}
          type='select'
          value={selectedSize}
        >
          {
            COMMON_OUTPUT_SIZES.map(
              (os, idx) => (
                <option
                  key={idx}
                  value={idx}
                >
                  {os.label}
                </option>
              )
            )
          }
          <option value={-1}>Custom...</option>
        </Input>
        <div className='form-text'>
          Output windows of a different size to the canvas size will be scaled to fit.
        </div>
      </div>
      <div>
        <h5>Custom size:</h5>
        <FormGroup className='linear mt-2'>
          <Label>
            Width
          </Label>
          <Input
            disabled={!custom}
            onChange={e => widthHelpers.setValue(e.target.value)}
            type='number'
            value={displayWidth.value}
          />
        </FormGroup>
        <FormGroup className='linear mt-2'>
          <Label>
            Height
          </Label>
          <Input
            disabled={!custom}
            onChange={e => heightHelpers.setValue(e.target.value)}
            type='number'
            value={displayHeight.value}
          />
        </FormGroup>
      </div>
    </FormGroup>
  );
};
