import React from 'react';
import Link from 'redux-first-router-link';

import moment from 'moment';

import ReleaseNotes from './ReleaseNotesModal';
import Version from './Version';
import { KeyboardShortcuts } from './KeyboardShortcuts';

export default () => (
  <div className='footer py-1 px-2 d-flex justify-content-around flex-wrap'>
    <span>
      Copyright &copy; {moment().format('YYYY')} <a href="https://www.muscatech.co.uk/">Muscatech Limited</a>.
    </span>
    <ReleaseNotes />
    <Link to={{ type: 'location/PRIVACY' }}>Privacy policy</Link>
    <KeyboardShortcuts />
    <Version />
  </div>
);
