import * as React from 'react';
import { FormText, Input } from 'reactstrap';
import { VersionNotes } from './VersionNotes';

export const Viewer = ({ changelog }) => {
  const [currentVersionIdx, setCurrentVersionIdx] = React.useState(0);

  const pickVersion = React.useCallback(
    (e) => {
      setCurrentVersionIdx(e.target.value);
    },
    [setCurrentVersionIdx]
  );

  return (
    <div>
      <FormText>View notes for version:</FormText>
      <Input
        className='mb-2'
        onChange={pickVersion}
        type='select'
        value={currentVersionIdx}
      >
        {
          changelog.map(
            (v, idx) => (
              <option
                key={idx}
                value={idx}
              >
                {`${v.released || 'Upcoming'} - ${v.version}`}
              </option>
            )
          )
        }
      </Input>
      {
        currentVersionIdx >= 0 &&
        changelog[currentVersionIdx] &&
        <VersionNotes notes={changelog[currentVersionIdx]} />
      }
    </div>
  );

};
