import React from 'react';

import { Button, Container, Row, Col, ModalHeader, ModalBody } from 'reactstrap';

import { connectedModal } from 'modal';
import LoginForm from './LoginForm';

const ConnectedModal = connectedModal('login');

export default ({ showModal }) => (
  <ConnectedModal centered>
    <ModalHeader>Log in</ModalHeader>
    <ModalBody>
      <Container fluid>
        <Row>
          <Col sm={8}>
            <LoginForm />
          </Col>
          <Col
            className='border-left'
            sm={4}
          >
            <p>
              Don't have an account yet?{' '}
              <Button
                color='link'
                onClick={() => showModal('register', undefined, true)}
              >
                Sign up for free!
              </Button>
            </p>
            <p>
              Trouble logging in?{' '}
              <Button
                color='link'
                onClick={() => showModal('password_reset', undefined, true)}
              >
                Reset your password
              </Button>
            </p>

          </Col>
        </Row>
      </Container>
    </ModalBody>
  </ConnectedModal>
);
