import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { pendingActionCountSelector, syncStateSelector } from '../selectors';
import { sync } from '../actions';
import { State, SYNC_FEATURE } from '../constants';
import auth from 'auth';
import resource from 'resource';
import theme from 'theme';


const AutoSync = ({ timeout }) => {
  const resourcesLoaded = useSelector(resource.selectors.resourceLoadStateSelector);
  const themesLoaded = useSelector(theme.selectors.themeLoadStateSelector);
  const { lastAttempt, syncState } = useSelector(syncStateSelector);
  const syncQueueLength = useSelector(pendingActionCountSelector);
  const syncEnabled = useSelector(auth.selectors.hasFeature(SYNC_FEATURE));

  const dispatch = useDispatch();

  const canSync = resourcesLoaded && themesLoaded && syncEnabled && syncState !== State.SYNCING;

  const poll = useCallback(
    () => {
      if (canSync) {
        const now = Date.now();
        if (!lastAttempt || lastAttempt + timeout < now || (syncQueueLength > 0 && syncState === State.OK)) {
          dispatch(sync());
        }
      }
    },
    [canSync, dispatch, lastAttempt, syncQueueLength, syncState, timeout]
  );

  useEffect(
    () => {
      const interval = window.setInterval(poll, 1000);

      return () => {
        window.clearInterval(interval);
      };
    },
    [poll]
  );

  return null;
};

AutoSync.propTypes = {
  /** Interval (in ms) between syncs. */
  timeout: PropTypes.number.isRequired
};

AutoSync.defaultProps = {
  timeout: 5 * 60 * 1000
};

export default AutoSync;
