import * as React from 'react';
import { clickHandler } from '../functions';

import RenderedLine from './RenderedLine';

const RenderedLineGroup = ({ addressSetByClick, blockIndex, groupIndex, lineGroup, live, onGroupClick, onLineClick }) => {

  const ref = React.useRef();

  React.useEffect(
    () => {
      if (live && ref.current) {
        if (addressSetByClick) {
          ref.current.scrollIntoViewIfNeeded();
        }
        else {
          ref.current.scrollIntoView({ block: 'center' });
        }
      }
    },
    [addressSetByClick, live]
  );

  return (
    <div
      className={`lineGroup ${live ? 'live' : ''}`}
      data-index={groupIndex}
      onClick={clickHandler(onGroupClick, lineGroup, blockIndex, groupIndex)}
      ref={ref}
    >
      {
        lineGroup.map(
          (line, idx) => (
            <RenderedLine
              key={idx}
              line={line}
              onClick={onLineClick}
            />
          )
        )
      }
    </div>
  );
};

export default RenderedLineGroup;
