import React from 'react';

import { Collapse, FormGroup, Label } from 'reactstrap';

import FormField from 'components/FormField';
import ColourPickerField from 'components/ColourPickerField';
import CSSValueUnitField from '../CSSValueUnitField';
import { useFormValue } from 'hooks';

export default () => {
  const isEnabled = useFormValue('textBox.enabled');
  return (
    <FormGroup>
      <FormGroup check>
        <Label>
          <FormField
            autoSave
            name='textBox.enabled'
            type="checkbox"
          /> Enabled
        </Label>
      </FormGroup>
      <Collapse isOpen={isEnabled}>
        <FormGroup className='linear'>
          <Label for='textBox.backgroundColor'>Background colour:</Label>
          <ColourPickerField
            autoSave
            name='textBox.backgroundColor' />
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='textBox.borderColor'>Border colour:</Label>
          <ColourPickerField
            autoSave
            name='textBox.borderColor' />
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='textBox.borderWidth'>Border thickness:</Label>
          <CSSValueUnitField
            autoSave
            name='textBox.borderWidth'
            unitValues={{
              'px': 'px',
              'rem': 'rem'
            }}
          />
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='textBox.padding'>Padding:</Label>
          <CSSValueUnitField
            autoSave
            name='textBox.padding'
            unitValues={{
              'px': 'px',
              'rem': 'rem'
            }}
          />
        </FormGroup>
      </Collapse>
    </FormGroup>
  );
};
