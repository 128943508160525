import axios from 'axios';

export const getAPIUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  else if (typeof(window) !== 'undefined' && window._API_URL) {
    return window._API_URL;
  }
  else {
    return 'http://localhost:8000/api/v1/';
  }
};

const client = axios.create({
  baseURL: getAPIUrl(),
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
});

export const resolved = (actionType) => (`${actionType}_SUCCESS`);
export const rejected = (actionType) => (`${actionType}_FAIL`);

export default {
  client
};
