import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { getDotpath } from 'utils/dotpath';
import { parseCSSValueAndUnit } from '../functions';

const ReactstrapInputWrapper = ({
  autoSave,
  field,
  form: { setFieldValue, submitForm, errors, touched },
  unitValues
}) => {

  const isTouched = !!getDotpath(touched, field.name);
  const myErrors = getDotpath(errors, field.name);

  const { value, unit } = parseCSSValueAndUnit(field.value);

  const update = (newValue, newUnit) => {
    setFieldValue(
      field.name,
      `${newValue}${newUnit}`,
      !autoSave
    );
    if (autoSave) {
      submitForm();
    }
  };

  return (
    <div className='form-field'>
      <div className='d-flex'>
        <Input
          {...field}
          invalid={isTouched && !!myErrors}
          onChange={(e) => update(e.target.value, unit)}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            flex: '2 1 50%',
            textAlign: 'right'
          }}
          type='number'
          valid={isTouched && !myErrors}
          value={value}
        />
        <Input
          onBlur={field.onBlur}
          onChange={(e) => update(value, e.target.value)}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 0,
            flex: '1 0 4rem'
          }}
          type='select'
          value={unit}
        >
          {
            Object.entries(unitValues).map(
              ([k, v], idx) => (
                <option
                  key={idx}
                  value={v}
                >
                  {k}
                </option>
              )
            )
          }
        </Input>
      </div>
      {
        myErrors ?
          <FormFeedback>{myErrors}</FormFeedback>
        : null
      }
    </div>
  );
};

ReactstrapInputWrapper.propTypes = {
  autoSave: PropTypes.bool
};

const CSSValueUnitField = ({ name, ...props }) => {
  return (
    <Field
      component={ReactstrapInputWrapper}
      name={name}
      {...props}
    />
  );
};

export default CSSValueUnitField;
