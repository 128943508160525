import * as React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';
import { EditResourceButton } from 'components/EditResourceButton';

export const ResourceLoading = ({ resource }) => (
  <div className='d-flex flex-column overflow-hidden h-100'>
    <div className='d-flex'>
      <h4 className='flex-grow-1'>{resource.title}</h4>
      <EditResourceButton
        className='mb-2 p-0'
        color='secondary'
        disabled
        size='sm'
      />
    </div>
    <LoadingSpinner
      className='align-self-center flex-grow-1'
      size='4x'
    />
  </div>
);
