import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'reactstrap';
import modal from 'modal';
import { CancelSubscriptionModal } from './CancelSubscriptionModal';
import { CANCEL_SUBSCRIPTION_MODAL } from '../constants';

export const CancelSubscriptionButton = ({ subscription }) => {

  const dispatch = useDispatch();

  return (
    <>
      <Button
        className='text-danger mx-2'
        color='link'
        onClick={() => dispatch(modal.actions.showModal(CANCEL_SUBSCRIPTION_MODAL))}
      >
        Cancel subscription
      </Button>
      <CancelSubscriptionModal subscription={subscription} />
    </>
  );
};
