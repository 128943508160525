import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Step from './Step';

import chrome from 'chrome';
import modal from 'modal';
import PageWrapper from '../../components/PageWrapper';

import { BROWSER_CHECK_RESULTS, checkBrowser } from '../../components/BrowserGate';

const STEPS = [
  {
    title: 'Use a supported browser',
    content: 'JustWords is supported on Google Chrome on desktop. (Firefox support coming soon.)',
    test: () => checkBrowser() === BROWSER_CHECK_RESULTS.OK
  },
  {
    title: 'Install the plugin',
    content: chrome.InstallFromStorePrompt,
    test: (state) => chrome.selectors.hasExtensionSelector(state)
  },
  {
    title: 'Register an account or log in',
    content: (dispatch) => (
      <p>
        <Button
          color='link'
          onClick={() => dispatch(modal.actions.showModal('register'))}>
          Register
        </Button>{' '}
        your free account to start using JustWords. If you've registered,{' '}
        <Button
          color='link'
          onClick={() => dispatch(modal.actions.showModal('login'))}>
          log in
        </Button>
        .
      </p>
    ),
    test: (state) => !!state.auth.account
  }
];

export default connect(
  (state) => ({ state })
)(
  ({ state, dispatch }) => {

    const testResults = STEPS.map(
      (s) => s.test ? s.test(state) : true
    );

    const allComplete = testResults.reduce(
      (a, b) => a && b,
      true
    );

    return (
      <PageWrapper>
        <h2>
          Get started
        </h2>

        <p>
          Get started using JustWords in {STEPS.length} easy steps:
        </p>

        <ul className='steps'>
          {
            STEPS.map(
              (s, idx) => (
                <Step
                  index={idx + 1}
                  key={idx}
                  test={testResults[idx]}
                  title={s.title}
                >
                  {
                    typeof(s.content) === 'function' ?
                     s.content(dispatch)
                     : s.content
                  }
                </Step>
              )
            )
          }
        </ul>
        {
          allComplete ?
            <Button
              className='float-right'
              color='primary'
              onClick={() => dispatch({ type: 'location/LIVE' })}
              size='lg'
            >
              All set! Get started now
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          : null
        }
        <chrome.PluginDetector />
      </PageWrapper>
    );
  }
);
