import React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Container, Row, Col, ModalHeader, ModalBody, Alert } from 'reactstrap';

import { connectedModal } from 'modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { forgottenPassword } from '../actions';
import { ResetPasswordForm } from './ResetPasswordForm';

const ConnectedModal = connectedModal('password_reset');

const Stages = {
  BLANK: 0,
  SUBMITTING: 1,
  SUBMITTED: 2,
  ERRORED: 3
};

export default ({ showModal }) => {

  const [stage, setStage] = React.useState(Stages.BLANK);
  const dispatch = useDispatch();

  const handleSubmit = (email) => {
    setStage(Stages.SUBMITTING);

    dispatch(
      forgottenPassword(email)
    ).then(
      (result) => {
        if (result.error) {
          setStage(Stages.ERRORED);
        }
        else {
          setStage(Stages.SUBMITTED);
        }
      }
    );
  };

  return (
    <ConnectedModal centered>
      <ModalHeader>Reset your password</ModalHeader>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col sm={8}>
              {
                stage < Stages.SUBMITTED ? (
                  <ResetPasswordForm
                    onSubmit={handleSubmit}
                    submitting={stage === Stages.SUBMITTING}
                  />
                )
                : null
              }
              {
                stage === Stages.SUBMITTED ? (
                  <Alert color='success'>
                    If you entered a valid email address, we've sent you
                    instructions on how to reset your password. Please
                    check your email to continue.
                  </Alert>
                ): null
              }
              {
                stage === Stages.ERRORED ? (
                  <Alert
                    className='d-flex flex-column'
                    color='danger'
                  >
                    There was an error trying to send your password reset email.
                    Please try again, or email us if the problem persists.
                    <br />
                    <Button
                      className='mt-2 align-self-center'
                      color='primary'
                      onClick={() => setStage(Stages.BLANK)}
                    >
                      Try again
                    </Button>
                  </Alert>
                ): null
              }
            </Col>
            <Col
              className='border-left'
              sm={4}
            >
              <p>
                Don't have an account yet?{' '}
                <Button
                  color='link'
                  onClick={() => showModal('register', undefined, true)}
                >
                  Sign up for free!
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color='secondary'
          onClick={() => {
            showModal(null);
            setStage(Stages.BLANK);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </ConnectedModal>
  );
};
