import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import { MINIMAL, TYPES, LICENCE_TYPES } from '../../constants';
import { withFormik } from 'formik';
import { Resource } from '../../schemas';
import FormField from 'components/FormField';

import SongMeta from './SongMeta';
import { NewResourceEditor } from '../NewResourceEditor';

const ResourceForm = ({
  values,
  isValid,
  handleChange,
  handleBlur,
  handleSubmit,
  hide,
  resource,
  setFieldValue,
  setTouched
}) => {

  const onResourceChanged = (content) => {
    setFieldValue('content', content);
    setTouched({
      content: true,
      title: true,
      meta: {
        author: true,
        copyright: true
      }
    });
  };

  if (resource) {
    return (
      <Fragment>
        <ModalBody className='d-flex h-100'>
          <Form
            id='resourceForm'
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className='fields'>
              <Input
                name='id'
                onBlur={handleBlur}
                onChange={handleChange}
                type='hidden'
                value={resource.id || -1}
              />
              <FormGroup className='linear'>
                <Label for='title'>Title</Label>
                <FormField
                  name='title'
                  type='text'
                  value={values.title}
                />
              </FormGroup>
            </div>
            <div className='meta'>
              <Label>Type</Label>
              <fieldset>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={values.type === TYPES.SONG}
                      name="type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="radio"
                      value={TYPES.SONG}
                    />{' '}
                    Song
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      checked={values.type === TYPES.LITURGY}
                      name="type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="radio"
                      value={TYPES.LITURGY}
                    />{' '}
                    Liturgy
                  </Label>
                </FormGroup>
              </fieldset>
              <SongMeta />
            </div>
            <NewResourceEditor
              onChange={onResourceChanged}
              resource={resource}
              resourceType={values.type} />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={hide}>Cancel</Button>
          <Button
            color='primary'
            disabled={!isValid}
            form='resourceForm'
            type='submit'
          >Submit</Button>
        </ModalFooter>
      </Fragment>
    );
  }

  return null;
};

ResourceForm.propTypes = {
  hide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resource: PropTypes.object
};

ResourceForm.defaultProps = {
  resource: MINIMAL
};

const handleSubmit = (
  values,
  {
    props,
    setSubmitting,
    setErrors /* setValues, setStatus, and other goodies */,
  }
) => {
  if (props.onSubmit) {
    props.onSubmit(values);
  }
  props.hide();
};

const mapPropsToValues = ({ resource }) => {
  const useResource = resource || MINIMAL;
  return {
    title: useResource.title,
    type: useResource.type,
    content: useResource.content,
    meta: {
      ...useResource.meta,
      licence: (useResource.meta && useResource.meta.licence) || Object.keys(LICENCE_TYPES)[0]
    },
    id: useResource.id || -1
   };
};

const ActualResourceForm = withFormik({
  handleSubmit: handleSubmit,
  mapPropsToValues,
  validationSchema: Resource
})(ResourceForm);

export default ActualResourceForm;
