import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { changeEmail } from '../actions';
import { extractErrorText } from '../functions';
import { accountSelector } from '../selectors';

export const ChangeEmailForm = () => {

  const account = useSelector(accountSelector);

  const [email, setEmail] = React.useState(account.email);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [wasSuccessful, setWasSuccessful] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const dispatch = useDispatch();

  const doSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setSubmitting(true);
      setWasSuccessful(false);
      setErrorText(null);
      dispatch(changeEmail(email)).then(
        (response) => {
          setSubmitting(false);

          if (!response.error) {
            setWasSuccessful(true);
          }
          else {
            setErrorText(extractErrorText(response.error.response.data));
          }
        }
      );
      return false;
    },
    [dispatch, email]
  );

  return (
    <Form
      className='mt-2'
      onSubmit={doSubmit}
    >
      {
        wasSuccessful && (
          <Alert color='success'>
            Your email address was changed successfully.
          </Alert>
        )
      }
      <FormGroup>
        <Label for='email'>Your email address:</Label>
        <Input
          invalid={!!errorText}
          onChange={e => setEmail(e.target.value)}
          required
          type='email'
          value={email}
        />
        <FormFeedback valid={false}>{errorText}</FormFeedback>
      </FormGroup>
      <Button
        color='primary'
        disabled={isSubmitting}
        type='submit'
      >
        Change email
      </Button>

    </Form>
  );
};
