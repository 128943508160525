import * as React from 'react';
import { Button, Form, Input } from 'reactstrap';

export const ResetPasswordForm = ({ onSubmit, submitting }) => {

  const [ email, setEmail ] = React.useState('');
  const isPlausibleEmail = email.match(/.+@.+\..+/);

  return (
    <Form>
      <p>
        Enter the email address you use to log in to JustWords, and we'll send
        you an email with a link to reset your password.
      </p>
      <Input
        disabled={submitting}
        onChange={(e) => setEmail(e.target.value)}
        type='email'
        value={email}
      />
      <Button
        className='mt-2'
        color='primary'
        disabled={submitting || !email || !isPlausibleEmail}
        onClick={() => onSubmit(email)}
        type='submit'
      >
        Send password reset email
      </Button>
    </Form>
  );
};
