import * as constants from './constants';
import { themeState, themesSelector } from './selectors';
import { newTheme as makeNewTheme, generate_id } from './functions';

import auth from 'auth';

export const load = () => (dispatch, getState) => {
  const account = auth.selectors.accountSelector(getState());
  dispatch({
    // Load from unlimited local storage via browser extension.
    type: constants.LOAD,
    chrome: {
      message: 'storage_get',
      key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`
    }
  });
};

export const save = () => (dispatch, getState) => {
  const state = getState();
  const themes = themeState(state);
  const account = auth.selectors.accountSelector(state);
  if (account) {
    return dispatch({
      type: constants.SAVE,
      chrome: {
        message: 'storage_set',
        key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`,
        value: themes
      }
    });
  }
};

const withSave = type => theme => dispatch => {
  dispatch({
    type,
    theme,
    meta: {
      injectAccount: true
    }
  });
  dispatch(save());
};

export const newTheme = () => (dispatch, getState) => {

  const ts = themesSelector(getState());

  return dispatch(
    withSave(constants.PUT_THEME)(
      makeNewTheme('New theme', ts)
    )
  );
};

export const updateTheme = withSave(constants.PUT_THEME);

export const selectTheme = (id) => dispatch => {
  dispatch({
    type: constants.SELECT_THEME,
    id
  });
  dispatch(save());
};

export const duplicateTheme = (theme) => (dispatch, getState) => {

  const ts = themesSelector(getState());
  const newID = generate_id(ts);

  const nt = {
    ...theme,
    name: `Copy of ${theme.name}`,
    id: newID
  };

  return dispatch(
    withSave(constants.PUT_THEME)(
      nt
    )
  );
};

export const deleteTheme = withSave(constants.DELETE_THEME);
