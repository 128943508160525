import React, { Fragment } from 'react';
import { Card, CardBody, Collapse, FormGroup, Label } from 'reactstrap';

import ColourPickerField from 'components/ColourPickerField';
import FormField from 'components/FormField';
import { FontSelectorField } from 'fonts';
import { useFormValue } from 'hooks';

const LineStyle = ({ prefix }) => {
  const outlineEnabled = useFormValue(`font.${prefix}.outline.enabled`);
  const shadowEnabled = useFormValue(`font.${prefix}.shadow.enabled`);
  return (
    <Fragment>
      <FormGroup className='linear'>
        <Label for={`font.${prefix}.color`}>Font colour:</Label>
        <ColourPickerField
          autoSave
          name={`font.${prefix}.color`} />
      </FormGroup>
      <FormGroup className='linear'>
        <Label>Style:</Label>
        <FormGroup check>
          <Label>
            <FormField
              autoSave
              name={`font.${prefix}.bold`}
              type="checkbox" /> Bold
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label>
            <FormField
              autoSave
              name={`font.${prefix}.italic`}
              type="checkbox" /> Italic
          </Label>
        </FormGroup>
      </FormGroup>
      <FormGroup className='linear'>
        <Label for={`font.${prefix}.align`}>Alignment:</Label>
        <FormField
          autoSave
          name={`font.${prefix}.align`}
          type='select'>
          <option value='left'>Left</option>
          <option value='center'>Centre</option>
          <option value='right'>Right</option>
        </FormField>
      </FormGroup>
      <FormGroup className='linear'>
        <Label>Outline:</Label>
        <FormGroup>
          <FormGroup check>
            <Label>
              <FormField
                autoSave
                name={`font.${prefix}.outline.enabled`}
                type="checkbox" /> Enable
            </Label>
          </FormGroup>
          <Collapse isOpen={outlineEnabled}>
            <ColourPickerField
              autoSave
              name={`font.${prefix}.outline.color`} />
            <Label for={`font.${prefix}.outline.width`}>Width</Label>
            <FormField
              autoSave
              name={`font.${prefix}.outline.width`} />
          </Collapse>
        </FormGroup>
      </FormGroup>
      <FormGroup className='linear'>
        <Label>Shadow:</Label>
        <FormGroup>
          <FormGroup check>
            <Label>
              <FormField
                autoSave
                name={`font.${prefix}.shadow.enabled`}
                type="checkbox" /> Enable
            </Label>
          </FormGroup>
          <Collapse isOpen={shadowEnabled}>
            <ColourPickerField
              autoSave
              name={`font.${prefix}.shadow.color`} />
            <Label for={`font.${prefix}.shadow.blur`}>Blur radius</Label>
            <FormField
              autoSave
              name={`font.${prefix}.shadow.blur`} />
            <Label for={`font.${prefix}.shadow.xOffset`}>x offset</Label>
            <FormField
              autoSave
              name={`font.${prefix}.shadow.xOffset`} />
            <Label for={`font.${prefix}.shadow.yOffset`}>y offset</Label>
            <FormField
              autoSave
              name={`font.${prefix}.shadow.yOffset`} />
          </Collapse>
        </FormGroup>
      </FormGroup>
    </Fragment>
  );
};

export default () => (
  <div className='font-grid'>
    <FormGroup className='linear'>
      <Label for='font.face'>Font face:</Label>
      <FontSelectorField
        autoSave
        name='font.face' />
    </FormGroup>
    <FormGroup className='linear'>
      <Label for='font.size'>Size (pt):</Label>
      <FormField
        autoSave
        min={1}
        name='font.size'
        type='number' />
    </FormGroup>
    <FormGroup className='linear'>
      <Label for='font.lineHeight'>Line height:</Label>
      <FormField
        autoSave
        min={0}
        name='font.lineHeight'
        step={0.1}
        type='number'
      />
    </FormGroup>
    <FormGroup className='linear'>
      <Label for='font.letterSpacing'>Letter spacing:</Label>
      <FormField
        autoSave
        name='font.letterSpacing'
        step={0.1}
        type='number'
      />
    </FormGroup>
    <Card>
      <CardBody>
        <h5>Normal lines</h5>
        <LineStyle prefix='normal' />
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <h5>Minor lines</h5>
        <LineStyle prefix='minor' />
      </CardBody>
    </Card>
  </div>
);
