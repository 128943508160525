import * as yup from 'yup';

export const LoginFormSchema = yup.object({
  email: yup.string().ensure().trim().email().required(),
  password: yup.string().ensure().trim().required(),
});

export const RegistrationFormSchema = yup.object({
  email: yup.string().ensure().trim().email().required(),
  password1: yup.string().ensure().trim().required(),
  password2: yup.string().ensure().trim().required(),
});
