import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, Input, Label } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import PageWrapper from '../components/PageWrapper';

import auth from 'auth';
import modal from 'modal';


const BadURL = () => (
  <PageWrapper>
    <h1>Reset your password</h1>
    <Alert color='danger'>
      Unable to reset your password. Please check the link you followed and
      try again.
    </Alert>
  </PageWrapper>
);

const State = {
  BLANK: 1,
  SUBMITTING: 2,
  COMPLETED: 3,
  ERRORED: 4
};

export default () => {

  const { key, uid } = useSelector(
    state => state.location.query || {}
  );

  const [pwd1, setPwd1] = React.useState('');
  const [pwd2, setPwd2] = React.useState('');
  const [state, setState] = React.useState(State.BLANK);

  const dispatch = useDispatch();

  if (!key || !uid) {
    return (
      <BadURL />
    );
  }

  const handleSubmit = () => {
    setState(State.SUBMITTING);
    dispatch(
      auth.actions.resetPasswordConfirm(
        key,
        uid,
        pwd1,
        pwd2
      )
    ).then(
      result => {
        setState(!!result.error ? State.ERRORED : State.COMPLETED);
      }
    ).catch(
      () => setState(State.ERRORED)
    );
  };

  if (state === State.COMPLETED) {
    return (
      <PageWrapper>
        <h1>Reset your password</h1>
        <Alert color='success'>
          Your password has been changed successfully. You can now { ' ' }
          <Button
            color='link'
            onClick={() => dispatch(modal.actions.showModal('login')) }
          >
            log in
          </Button>
          { ' ' } using your new password.
        </Alert>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <h1>Reset your password</h1>
      {
        state === State.ERRORED && (
          <Alert
            color='danger'
          >
            There was an error resetting your password. Please check the link
            from the email we sent you. If necessary, you can { ' ' }
            <Button
              color='link'
              onClick={
                () => {
                  setPwd1('');
                  setPwd2('');
                  dispatch(modal.actions.showModal('password_reset'));
                }
              }
            >
              start the process again
            </Button>.
          </Alert>
        )
      }
      <Form>
        <FormGroup>
          <Label for='pwd1'>
            Enter your new password:
          </Label>
          <Input
            id='pwd1'
            onChange={(e) => setPwd1(e.target.value)}
            type='password'
            valid={!!pwd1}
            value={pwd1}
          />
        </FormGroup>
        <FormGroup>
          <Label for='pwd2'>
            Enter it again:
          </Label>
          <Input
            id='pwd2'
            invalid={!!pwd2 && pwd2 !== pwd1}
            onChange={(e) => setPwd2(e.target.value)}
            type='password'
            valid={!!pwd2 && pwd1 === pwd2}
            value={pwd2}
          />
        </FormGroup>
        <Button
          color='primary'
          disabled={!pwd1 || !pwd2 || pwd1 !== pwd2 || state === State.SUBMITTING || state === State.COMPLETED}
          onClick={handleSubmit}
          type='submit'
        >
          Set new password
        </Button>
      </Form>
    </PageWrapper>
  );
};
