import React, { Fragment } from 'react';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

const TabHeader = ({ currentPage, pages, setPage }) => (
  <Nav tabs>
    {
      pages.map(
        (page, idx) => (
          <NavItem key={idx}>
            <NavLink
              active={currentPage === idx}
              href='#'
              onClick={() => setPage(idx)}
            >
              { page.name || `Page ${idx + 1}` }
            </NavLink>
          </NavItem>
        )
      )
    }
  </Nav>
);

export default class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
    this.setPage = this.setPage.bind(this);
  }

  setPage(page) {
    this.setState({ currentPage: page });
  }

  render() {
    const { pages, ...others } = this.props;

    return (
      <Fragment>
        <TabHeader
          currentPage={this.state.currentPage}
          pages={pages}
          setPage={this.setPage} />
        <TabContent activeTab={this.state.currentPage}>
          {
            pages.map(
              (page, idx) => {
                const PageComponent = page.component;

                return (
                  <TabPane
                    key={idx}
                    tabId={idx}>
                    <PageComponent {...others} />
                  </TabPane>
                );
              }
            )
          }
        </TabContent>
      </Fragment>
    );
  }
}
