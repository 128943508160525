import React from 'react';
import { connect } from 'react-redux';

import { logIn } from '../actions';

import { Button, Form, FormGroup, Label } from 'reactstrap';
import { withFormik } from 'formik';
import FormField from 'components/FormField';

import { LoginFormSchema } from '../schemas';

const LoginForm = ({ authErrors, handleSubmit, isSubmitting, isValid }) => (
  <Form onSubmit={handleSubmit}>
    {
      authErrors ?
        <p className='text-danger'>{authErrors['non_field_errors']}</p>
        : null
    }
    <FormGroup>
      <Label for='email'>Email address</Label>
      <FormField
        autoComplete='username'
        name='email'
      />
    </FormGroup>
    <FormGroup>
      <Label for='password'>Password</Label>
      <FormField
        autoComplete='current-password'
        name='password'
        type='password' />
    </FormGroup>
    <Button
      color='primary'
      disabled={isSubmitting || !isValid}
      type='submit'
    >
      Log in
    </Button>
  </Form>
);

export default connect(
  (state) => ({ authErrors: state.auth.errors }),
  {
    logIn
  })(
    withFormik({
  enableReinitialize: true,
  handleSubmit: (t, { props: { logIn }, setSubmitting }) => {
    logIn(t.email, t.password).then(
      () => setSubmitting(false),
      () => setSubmitting(false)
    );
  },
  mapPropsToValues: () => ({ email: '', password: '' }),
  validationSchema: LoginFormSchema
})(
  LoginForm
));
