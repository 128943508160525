import React from 'react';

import { Button } from 'reactstrap';

import chromeStoreBadge from './ChromeWebStore_Badge_v2_340x96.png';

const CHROME_STORE_URL = 'https://chrome.google.com/webstore/detail/justwords-for-chrome/bncpjjjjagbmbmaefckmjecknmigphna';

const InstallFromStorePrompt = () => (
  <React.Fragment>
    <p>
      JustWords requires the "JustWords for Chrome" browser extension to be
      installed.{' '}
      <a href={CHROME_STORE_URL}>
        Install it now from the Chrome Web Store
      </a>.
    </p>
    <a href={CHROME_STORE_URL}>
      <img
        alt='Available in the Chrome Web Store'
        src={chromeStoreBadge}
    />
    </a>
    <p>
      If you've installed the plugin already, you might need to{' '}
      <Button
        color='link'
        onClick={() => window.location.reload()}
      >
        refresh the page
      </Button>.
    </p>
  </React.Fragment>
);

export default InstallFromStorePrompt;
