import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { List } from 'react-virtualized';

import { fontsSelector } from '../selectors';
import { loadFonts } from '../actions';

class FontSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { fonts, loadFonts } = this.props;
    if (fonts.length === 0) {
      loadFonts();
    }
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { currentFont, fonts, onSelect } = this.props;

    if (fonts.length === 0) {
      return null;
    }

    const currentFontIndex = fonts.findIndex((f) => f.fontId === currentFont);

    return (
      <ButtonDropdown
        isOpen={this.state.open}
        toggle={this.toggle}>
        <DropdownToggle
          caret
          color='primary'>
          Choose
        </DropdownToggle>
        <DropdownMenu right>
          {
            fonts.length > 0 && (
              <List
                height={400}
                rowCount={fonts.length}
                rowHeight={50}
                rowRenderer={
                  ({ index, key, style }) => (
                    <DropdownItem
                      active={index === currentFontIndex}
                      key={key}
                      onClick={() => onSelect(fonts[index].fontId)}
                      style={{
                        fontFamily: fonts[index].fontId,
                        ...style
                      }}
                    >
                      {fonts[index].displayName}
                    </DropdownItem>
                  )
                }
                scrollToIndex={currentFontIndex}
                width={350}
              />
            )
          }
        </DropdownMenu>
      </ButtonDropdown>
    );
  }

}

export default connect(
  createStructuredSelector({
    fonts: fontsSelector
  }),
  {
    loadFonts
  }
)(FontSelector);
