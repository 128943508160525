import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalHeader } from 'reactstrap';

import ResourceForm from './forms/ResourceForm';
import { MINIMAL } from '../constants';
import { addResource } from '../actions';

import modal, { connectedModal } from 'modal';

const ConnectedModal = connectedModal('resource/add');

const AddResourceModal = ({ addResource, showModal }) => (
  <ConnectedModal className='full-size'>
    <ModalHeader>
      Add new resource
    </ModalHeader>
    <ResourceForm
      hide={() => showModal(null)}
      onSubmit={(resource) => addResource(resource)}
      resource={MINIMAL}
    />
  </ConnectedModal>
);

AddResourceModal.propTypes = {
  addResource: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    addResource,
    showModal: modal.actions.showModal
  }
)(AddResourceModal);
