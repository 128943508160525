import uuid from 'uuid/v4';

import * as constants from "./constants";
import { resourceState } from './selectors';
import auth from 'auth';

export const load = () => (dispatch, getState) => {

  const account = auth.selectors.accountSelector(getState());

  dispatch({
    // Load from unlimited local storage via browser extension.
    type: constants.LOAD,
    chrome: {
      message: 'storage_get',
      key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`
    }
  });
};

export const save = () => (dispatch, getState) => {
  const state = getState();
  const resources = resourceState(state);
  const account = auth.selectors.accountSelector(state);
  if (account) {
    return dispatch({
      type: constants.SAVE,
      chrome: {
        message: 'storage_set',
        key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`,
        value: resources
      }
    });
  }
};

const withSave = type => resource => dispatch => {
  dispatch({
    type,
    resource,
    meta: {
      injectAccount: true
    }
  });
  dispatch(save());
};

const generate_id = (state) => {
  let id = uuid();
  while (state[id]) {
    id = uuid();
  }
  return id;
};

export const addResource = (resource) => (dispatch, getState) => {

  const newResource = {
    ...resource,
    id: generate_id(resourceState(getState()))
  };

  return dispatch(withSave(constants.PUT_RESOURCE)(newResource));
};

export const updateResource = withSave(constants.PUT_RESOURCE);

export const deleteResource = withSave(constants.DELETE_RESOURCE);

export const touchResourceUsedTimestamp = (resourceID) => ({
  type: constants.TOUCH_RESOURCE_USED_TIMESTAMP,
  resource: {
    id: resourceID
  }
});

export const bulkPutResources = (resourceActions) => dispatch => {
  dispatch({
    type: constants.BULK_PUT_RESOURCES,
    meta: {
      bulk: true,
      injectAccount: true
    },
    resourceActions
  });
  dispatch(save());
};

export const importResources = (resources) => dispatch => {

  const data = new FormData();
  resources.forEach(
    (res, idx) => data.append(idx, res)
  );

  return dispatch({
    type: constants.IMPORT,
    payload: {
      request: {
        url: 'import',
        data,
        method: 'post',
        auth: true
      },
      meta: {
        injectAccount: true
      }
    }
  }).then(
    (resp) => {
      if (resp.payload && resp.payload.data && resp.payload.data.actions) {
        const resourcesToAdd = resp.payload.data.actions.filter(
          (a) => a.type === constants.PUT_RESOURCE
        );

        dispatch(
          bulkPutResources(resourcesToAdd)
        );
      }
      dispatch(save());
      return resp;
    }
  );
};
