import { withFormik } from 'formik';
import * as React from 'react';

import FormField from 'components/FormField';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { retrieveDisplaySettings } from '../../actions';

const OutputFormInner = ({ canvasSize, index, setFieldValue, submitForm, values }) => {

  const isCanvasSize = !(!!values.width && !!values.height);
  const [useCanvasSize, setUseCanvasSize] = React.useState(isCanvasSize);
  const dispatch = useDispatch();

  const toggleCanvasSize = (e) => {
    if (e.target.checked) {
      setFieldValue('width', '');
      setFieldValue('height', '');
      setUseCanvasSize(true);
    } else {
      setFieldValue('width', canvasSize.width);
      setFieldValue('height', canvasSize.height);
      setUseCanvasSize(false);
    }
    submitForm();
  };

  return (
    <div className='output-form border mx-2'>
      <div className='p-2'>
        <h4>Position</h4>
        <FormGroup className='linear'>
          <Label for='left'>Left</Label>
          <FormField
            autoSave
            name='left'
            type='number'
          />
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='top'>Top</Label>
          <FormField
            autoSave
            name='top'
            type='number'
          />
        </FormGroup>
      </div>
      <div className='p-2'>
        <h4>Size</h4>
        <FormGroup check >
          <Label>
            <Input
              checked={useCanvasSize}
              onChange={toggleCanvasSize}
              type='checkbox'
            />
            Use canvas size
          </Label>
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='width'>Width</Label>
          <FormField
            autoSave
            disabled={useCanvasSize}
            name='width'
            type='number'
          />
        </FormGroup>
        <FormGroup className='linear'>
          <Label for='height'>Height</Label>
          <FormField
            autoSave
            disabled={useCanvasSize}
            name='height'
            type='number'
          />
        </FormGroup>
        <FormGroup check>
          <Label>
            <FormField
              autoSave
              name='fullscreen'
              type="checkbox" /> Full screen
          </Label>
        </FormGroup>
      </div>
      <div className='p-2'>
        <Button
          onClick={() => dispatch(retrieveDisplaySettings(index))}
          size='sm'
        >
          Use current position and size
        </Button>
      </div>
    </div>
  );
};

export const OutputForm = withFormik({
  displayName: 'OutputForm',
  mapPropsToValues: props => ({
    ...props.output,
    width: props.output.width || '',
    height: props.output.height || '',
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.onChange(values);
  }
})(OutputFormInner);
