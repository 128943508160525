import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import modal from 'modal';
import { CHANGE_PAYMENT_MODAL } from '../constants';
import { ChangePaymentModal } from './ChangePaymentModal';

export const ChangePaymentButton = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Button
        className='mx-2'
        color='primary'
        onClick={() => dispatch(modal.actions.showModal(CHANGE_PAYMENT_MODAL))}
      >
        Change payment card
      </Button>
      <ChangePaymentModal />
    </>
  );
};
