import React from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default ({ children, index, test, title }) => {

  return (
    <li className={classNames('step', { 'step-complete': test })}>
      <span className='step-index'>
        { test ? <FontAwesomeIcon icon={faCheck} /> : index }
      </span>
      <span className='step-title'>
        { title }
      </span>

      {
          test ? null : <span className='step-content'>{children}</span>
        }
    </li>
  );
};
