import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { GlobalHotKeys } from 'react-hotkeys';

import { liveResourceProcessingSelector, liveResourceSelector } from '../selectors';
import * as actions from '../actions';
import { RenderedResource } from 'resource';
import output from '../../output';
import { ResourceLoading } from './ResourceLoading';

const KEYMAP = {
  MOVE_UP: ['up', 'pageup'],
  MOVE_DOWN: ['down', 'pagedown'],
  SEEK_BRIDGE: ['b', 'B'],
  SEEK_CHORUS: ['c', 'C'],
  SEEK_NUMBERED_VERSE: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
  SEEK_VERSE: ['v', 'V']
};

class Main extends React.Component {

  constructor(props) {
    super(props);

    this.handlers = {
      MOVE_UP: this.previous.bind(this),
      MOVE_DOWN: this.next.bind(this),
      SEEK_BRIDGE: this.seekBridge.bind(this),
      SEEK_CHORUS: this.seekChorus.bind(this),
      SEEK_NUMBERED_VERSE: this.seekNumberedVerse.bind(this),
      SEEK_VERSE: this.seekVerse.bind(this)
    };
  }

  next() {
    const { liveBlockAddress: [liveBlock, liveGroup], resource } = this.props;
    if (!resource) {
      return;
    }

    const numBlocks = resource.content.length;
    const numGroupsInBlock = resource.content[liveBlock].content.length;

    let nextBlock = liveBlock;
    let nextGroup = liveGroup + 1;

    if (nextGroup >= numGroupsInBlock) {
      nextGroup = 0;
      nextBlock = nextBlock + 1;
    }

    if (nextBlock < numBlocks) {
      this.setLive(nextBlock, nextGroup);
    }

  }

  previous() {
    const { liveBlockAddress: [liveBlock, liveGroup], resource } = this.props;
    if (!resource) {
      return;
    }

    let nextBlock = liveBlock;
    let nextGroup = liveGroup - 1;

    if (nextGroup < 0) {
      nextBlock = nextBlock - 1;
      if (nextBlock >= 0) {
        nextGroup = resource.content[nextBlock].content.length - 1;
      }
    }

    if (nextBlock >= 0) {
      this.setLive(nextBlock, nextGroup);
    }
  }

  seek(filter) {
    const { liveBlockAddress: [liveBlock, liveGroup], resource } = this.props;
    if (!resource) {
      return;
    }

    const numBlocks = resource.content.length;

    let nextBlock = (liveBlock + 1) % numBlocks;

    while (nextBlock !== liveBlock) {
      const candidateBlock = resource.content[nextBlock];
      if (filter(candidateBlock)) {
        this.setLive(nextBlock, 0);
        return;
      }
      nextBlock = (nextBlock + 1) % numBlocks;
    }

    if (liveGroup > 0) {
      this.setLive(nextBlock, 0);
    }
  }

  seekChorus() {
    this.seek((b) => b.type === 'chorus');
  }

  seekBridge() {
    this.seek((b) => b.type === 'bridge');
  }

  seekVerse() {
    this.seek((b) => b.type === 'verse');
  }

  seekNumberedVerse(ev) {
    const { resource } = this.props;
    if (!resource) {
      return;
    }
    const idx = parseInt(ev.key, 10);
    let verseIndex = 0;
    resource.content.forEach(
      (block, blockIndex) => {
        if (block.type === 'verse') {
          if (++verseIndex === idx) {
            this.setLive(blockIndex, 0);
            return;
          }
        }
      }
    );
  }

  setLive(blockIndex, groupIndex) {
    const { setBlockAddress } = this.props;
    setBlockAddress(blockIndex, groupIndex);
  }

  render() {
    const { addressSetByClick, liveBlockAddress, processing, resource, setBlockAddress } = this.props;

    const handleGroupClick = (_, blockIndex, groupIndex) => {
      setBlockAddress(blockIndex, groupIndex, true);
      return true;
    };

    if (processing && !resource) {
      return (
        <div className='resource'>
          <ResourceLoading resource={processing} />
        </div>
      );
    }

    if (resource) {
      return (
        <>
          <RenderedResource
            addressSetByClick={addressSetByClick}
            liveBlockAddress={liveBlockAddress}
            onGroupClick={handleGroupClick}
            resource={resource}
          />
          <GlobalHotKeys
            handlers={this.handlers}
            keyMap={KEYMAP}
          />
        </>
      );
    }
    return <div className='resource'></div>;
  };
}

export default connect(
  createStructuredSelector({
    processing: liveResourceProcessingSelector,
    resource: liveResourceSelector,
    liveBlockAddress: output.selectors.blockAddressSelector,
    addressSetByClick: output.selectors.blockAddressSetByClickSelector
  }),
  {
    ...actions,
    setBlockAddress: output.actions.setBlockAddress
  }
)(Main);
