import * as React from 'react';

import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadPlaylist } from '../actions';
import { loadPlaylistFromFile } from '../functions';

export default (props) => {
  const dispatch = useDispatch();
  const inputRef = React.useRef();

  const doLoad = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const playlist = await loadPlaylistFromFile(file);
      dispatch(
        loadPlaylist(playlist)
      );
      inputRef.current.value = null;
    }
  };

  return (
    <React.Fragment>
      <Button
        {...props}
        className='p-0'
        color='secondary'
        onClick={() => inputRef.current && inputRef.current.click()}
        title='Open playlist'
      >
        <FontAwesomeIcon
          fixedWidth
          icon={faFolderOpen}
        />
      </Button>
      <input
        accept='.jwpl'
        onChange={doLoad}
        ref={inputRef}
        style={{ display: 'none' }}
        type='file'
      />
    </React.Fragment>
  );
};
