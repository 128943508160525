import React from 'react';
import Router from 'components/Router';

import Header from './Header';
import PreReleaseWarning from './PreReleaseWarning';

// This needs to wrap its content in a <div> or else key bindings stop working
// :(

export default ({ routes }) => (
  <div className='window'>
    <Header />
    <Router routes={routes} />
    <PreReleaseWarning />
  </div>
);
