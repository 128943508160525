import * as components from './components';
import middleware from './middleware';
import reducer from './reducer';
import * as selectors from './selectors';

export default {
  ...components,
  middleware,
  reducer,
  selectors
};

export { getExtensionID, resolved, rejected } from './functions';
