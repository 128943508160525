import * as constants from './constants';

const INITIAL_STATE = {
  address: [0, 0],
  width: null,
  height: null,
  fadeToBlack: false,
  wasByMouseClick: false
};

export default (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.UPDATE_DISPLAY_SIZE:
      return {
        ...state,
        width: action.width,
        height: action.height
      };

    case constants.SET_BLOCK_ADDRESS:
      return {
        ...state,
        address: action.address,
        wasByMouseClick: !!action.wasByMouseClick
      };

    case constants.TOGGLE_FADE_TO_BLACK:
      return {
        ...state,
        fadeToBlack: !state.fadeToBlack
      };

    default:
      return state;
  }
};
