import styled from 'styled-components';

import { themeValue } from '../utils';

const Content = styled.div`
  flex-grow: 1;

  margin-top: ${themeValue('margin.top')};
  margin-right: ${themeValue('margin.right')};
  margin-bottom: ${themeValue('margin.bottom')};
  margin-left: ${themeValue('margin.left')};

  min-height: 0;

  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;

`;

export default Content;
