import React from 'react';
import { Button } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { blankedSelector } from '../selectors';
import { toggleBlank } from '../actions';

const BlankToggle = () => {

  const blanked = useSelector(blankedSelector);
  const dispatch = useDispatch();

  return (
    <Button
      active={blanked}
      color='blanked'
      onClick={() => dispatch(toggleBlank())}
      title='Clear words (Escape)'
    >
      Clear
    </Button>
  );
};

export default BlankToggle;
