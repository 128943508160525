import { createSelector } from 'reselect';

import auth from 'auth';

const syncState = (state) => state.sync;

export const syncStateSelector = createSelector(
  syncState,
  auth.selectors.accountSelector,
  (sync, account) => ({
    lastAttempt: sync.lastAttemptedSync[account.id],
    lastSuccess: sync.lastSuccessfulSync[account.id],
    state: sync.state
  })
);

export const pendingActionsSelector = createSelector(
  syncState,
  (sync) => sync.pending
);

export const pendingActionCountSelector = createSelector(
  pendingActionsSelector,
  auth.selectors.accountSelector,
  (pending, account) => pending[account.id] ? pending[account.id].length : 0
);
