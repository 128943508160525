import { accessTokenSelector, accountSelector, refreshTokenSelector, tokenExpirySelector } from './selectors';
import { updateToken, logOut } from './actions';
import api, { rejected } from 'api';

const EXPIRY_BUFFER = 5 * 60 * 1000;

const actionWithToken = (action, token) => {
  const newAction = { ...action };
  delete newAction.payload.request.auth;

  newAction.payload.request.headers = {
    ...newAction.payload.request.headers,
    'Authorization': `Bearer ${token}`
  };

  return newAction;
};

export default ({ dispatch, getState }) => next => async action => {

  if (action.meta && action.meta.injectAccount) {
    const account = accountSelector(getState());
    delete action.meta.injectAccount;
    action.meta.account = account.id;
  }

  if (action.payload && action.payload.request && action.payload.request.auth) {

    const state = getState();
    const expires = tokenExpirySelector(state);

    if (expires - EXPIRY_BUFFER < Date.now()) {
      const refreshToken = refreshTokenSelector(state);

      try {
        const response = await api.client.request({
          url: 'token/refresh',
          method: 'post',
          data: {
            refresh: refreshToken
          }
        });
        dispatch(updateToken(response.data.access, response.data.account));
        return dispatch(actionWithToken(action, response.data.access));
      }
      catch (error) {
        if (error.response) {  // We know we're online...
          dispatch(logOut());  // On error, just log out (fine for now)
        } // Don't log out offline
        dispatch({ type: rejected(action.type) });
      }
    }
    else {
      return dispatch(actionWithToken(action, accessTokenSelector(getState())));
    }

  }
  return next(action);
};
