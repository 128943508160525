import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import modal from 'modal';

const SubscribeButton = ({ showModal }) => (
  <Button
    color='primary'
    onClick={() => showModal('subscribe')}
  >
    Subscribe today
  </Button>
);

export default connect(
  null,
  {
    showModal: modal.actions.showModal
  }
)(SubscribeButton);
