import { createSelector } from 'reselect';

export const resourceState = (state) => state.resources;

export const resourcesSelector = createSelector(
  resourceState,
  (resources) => Object.keys(resources).filter(k => k[0] !== '_').map(k => resources[k])
);

export const resourceLoadStateSelector = (state) => (state.resources || {})._loaded || false;
