import { createSelector } from "reselect";

export const outputState = (state) => state.output;

export const outputSizeSelector = createSelector(
  outputState,
  (os) => ( os ? { width: os.width, height: os.height } : null)
);

export const blockAddressSelector = createSelector(
  outputState,
  state => state.address
);

export const blockAddressSetByClickSelector = createSelector(
  outputState,
  state => !!state.wasByMouseClick
);

// Massive breach of abstraction to pull these bits of state in
// from elsewhere in the store!
const uiLiveResourceIDSelector = state => state.ui.liveResource;
const renderCacheStateSelector = state => state.renderCache.cache;

const liveResourceSelector = createSelector(
  uiLiveResourceIDSelector,
  renderCacheStateSelector,
  (resourceID, cache) => cache[resourceID]
);

export const liveResourceIDSelector = createSelector(
  uiLiveResourceIDSelector,
  id => id
);

export const liveGroupSelector = createSelector(
  liveResourceSelector,
  blockAddressSelector,
  (resource, [blockIdx, groupIdx]) => {
    if (resource) {
      const block = resource.content[Math.min(blockIdx, resource.content.length - 1)];
      if (block) {
        return block.content[Math.min(groupIdx, block.content.length - 1)];
      }
    }

    return null;
  }
);

export const liveMetaSelector = createSelector(
  liveResourceSelector,
  (resource) => resource && resource.meta
);

export const liveThemeSelector = createSelector(
  liveResourceSelector,
  resource => resource && resource.theme
);

export const fadeToBlackSelector = createSelector(
  outputState,
  outputState => !!outputState.fadeToBlack
);
