import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useState } from "react";

import { Renderer } from 'output';

export const RendererWrapper = () => {
  const [renderOptions, setRenderOptions] = useState({
    canvasHeight: 640,
    canvasWidth: 480,
    liveGroup: null,
    meta: null,
    theme: null,
    renderKey: undefined
  });

  const measurer = useRef();

  useEffect(
    () => {
      const listener = ({ data }) => {
        if (data?.type === 'renderer/SET_RENDER_OPTIONS') {
          setRenderOptions(data.renderOptions);
        }
      };

      window.addEventListener(
        'message',
        listener
      );

      return () => {
        window.removeEventListener('message', listener);
      };
    },
    []
  );

  const { renderKey, ...otherRenderOptions } = renderOptions;

  useLayoutEffect(
    () => {
      if (measurer.current && renderKey !== undefined) {
        const contentElem = measurer.current;
        const metaElem = contentElem.querySelector('#meta');
        const displayHeight = contentElem.clientHeight - (metaElem ? metaElem.clientHeight : 0);
        const textHeight = contentElem.firstChild?.clientHeight;

        window.postMessage({
          type: 'renderer/MEASUREMENTS',
          renderKey,
          measurements: {
            displayHeight,
            textHeight
          }
        });

      }
    }
  );

  return (
    <Renderer
      measurementRef={measurer}
      {...otherRenderOptions}
    />
  );
};
