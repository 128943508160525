import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faSearch } from '@fortawesome/free-solid-svg-icons';

import Playlist from './Playlist';
import { ThemeEditorButton } from 'theme';
import { SettingsButton } from 'settings';
import { BibleButton } from 'bible';
import { ShowModalButton } from 'modal';

export default () => (
  <div className='sidebar'>
    <div className='toolbar btn-group'>
      <BibleButton />
      <ShowModalButton
        color='primary'
        modalName='library'
        payload={null}
        shortcut={['ctrl+l', 'ctrl+L', 'command+l', 'command+L']}
        title='Library'
      >
        <FontAwesomeIcon icon={faBookReader} />
        Library
      </ShowModalButton>
      <ShowModalButton
        color='primary'
        modalName='library'
        payload={{ mode: 'search' }}
        shortcut={['ctrl+f', 'ctrl+F', 'command+f', 'command+F']}
        title='Search library'
      >
        <FontAwesomeIcon icon={faSearch} />
        Search
      </ShowModalButton>
    </div>
    <Playlist />
    <div className="toolbar btn-group">
      <SettingsButton />
      <ThemeEditorButton />
    </div>
  </div>
);
