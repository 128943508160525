import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';

import { ShowModalButton } from 'modal';

export default () => (
  <ShowModalButton
    color='primary'
    modalName='settings'
  >
    <FontAwesomeIcon icon={faSlidersH} />
    Settings
  </ShowModalButton>
);
