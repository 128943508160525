import * as actions from './actions';
import * as constants from './constants';
import * as components from './components';
import middleware from './middleware';
import reducer from './reducer';
import * as selectors from './selectors';

export default {
  actions,
  constants,
  ...components,
  middleware,
  reducer,
  selectors
};
