import * as constants from './constants';

export const openOutputWindow = (settings) => ({
  type: constants.OPEN_OUTPUT_WINDOW,
  chrome: {
    message: 'open_output_window',
    ...settings
  }
});

export const closeOutputWindow = (index) => ({
  type: constants.CLOSE_OUTPUT_WINDOW,
  chrome: {
    message: 'close_output_window',
    index
  }
});

export const closeAllOutputWindows = () => ({
  type: constants.CLOSE_ALL_OUTPUT_WINDOWS,
  chrome: {
    message: 'close_all_output_windows'
  }
});

export const windowHasClosed = (index) => ({
  type: constants.WINDOW_HAS_CLOSED,
  chrome: {
    message: 'window_has_closed',
    index
  }
});

export const updateDisplaySize = (width, height) => ({
  type: constants.UPDATE_DISPLAY_SIZE,
  width: width,
  height: height
});

export const setBlockAddress = (block, lineGroup, wasByMouseClick) => ({
  type: constants.SET_BLOCK_ADDRESS,
  address: [block, lineGroup],
  wasByMouseClick
});

export const toggleFadeToBlack = () => ({
  type: constants.TOGGLE_FADE_TO_BLACK
});
