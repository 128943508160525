import { previewResource } from './actions';
import * as constants from './constants';
import playlist from 'playlist';
import resource from 'resource';

export default store => next => action => {

  if (action.type === constants.LIVE_RESOURCE) {
    const state = store.getState();
    const currentPlaylist = playlist.selectors.currentPlaylistSelector(state);
    const currentLiveResource = action.resource;

    if (currentLiveResource) {
      const curIdx = currentPlaylist.findIndex((r) => r === currentLiveResource.id);
      if (curIdx >= 0 && curIdx < currentPlaylist.length - 1) {
        store.dispatch(
          previewResource(
            state.resources[currentPlaylist[curIdx + 1]]
          )
        );
      }

      store.dispatch(
        resource.actions.touchResourceUsedTimestamp(currentLiveResource.id)
      );
    }
  }

  return next(action);
};
