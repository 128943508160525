import { createSelector } from "reselect";

export const renderCacheState = (state) => state.renderCache;

export const cacheSelector = createSelector(
  renderCacheState,
  (state) => state.cache
);

export const queueSelector = createSelector(
  renderCacheState,
  (state) => state.queue
);

export const processingQueueSelector = createSelector(
  renderCacheState,
  state => state.processing
);
