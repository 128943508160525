import * as constants from './constants';

const API_VERSION = '3.1';
const ROOT_URL = 'https://nodejs.bible.com/api/bible';

const createApiUrl = (method, params) => {
  const param_string = Object.keys(params).map(
    (k) => `${k}=${params[k]}`
  ).join('&');

  return `${ROOT_URL}/${method}/${API_VERSION}?${param_string}`;
};

export const loadVersions = (lang) => ({
  type: constants.LOAD_VERSIONS,
  meta: {
    lang: lang
  },
  chrome: {
    message: 'cors_fetch',
    url: createApiUrl('versions', {
      language_tag: lang,
      type: 'all'
    }),
    json: true
  }
});

export const loadVersion = (versionID) => ({
  type: constants.LOAD_VERSION,
  chrome: {
    message: 'cors_fetch',
    url: createApiUrl('version', {
      id: versionID
    }),
    json: true
  }
});

export const loadChapter = (versionID, chapterID) => ({
  type: constants.LOAD_CHAPTER,
  versionID: versionID,
  chapterID: chapterID,
  chrome: {
    message: 'cors_fetch',
    url: createApiUrl(
      'chapter',
      {
        id: versionID,
        reference: chapterID
      }
    ),
    json: true
  }
});

export const selectVersion = (versionID) => ({
  type: constants.SELECT_VERSION,
  versionID
});
