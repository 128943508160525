// Overridden implementation of `redux-search` that keeps track of the currently
// searched-for text, and discards any old search results that don't match it.
// This avoids UI jank from relatively slow searches by only trying to display
// the most recent result.

import { applyMiddleware } from "redux";
import { defaultSearchStateSelector, SearchApi } from "redux-search";
import * as actions from 'redux-search/dist/es/actions';
import searchMiddleware from 'redux-search/dist/es/searchMiddleware';
import { RECEIVE_RESULT, SEARCH_STATE_SELECTOR } from "redux-search/dist/es/constants";
import { handlers as upstreamHandlers } from 'redux-search/dist/es/reducer';

function receiveResult(resourceName) {
  return function receiveResultForResource(result, text) {
    return {
      type: RECEIVE_RESULT,
      payload: {
        resourceName,
        result,
        text
      }
    };
  };
}

export function searchReducer (state = {}, { payload, type } = {}) {
  if (handlers.hasOwnProperty(type)) {
    return handlers[type](state, payload);
  } else {
    return state;
  }
}

const handlers = {
  ...upstreamHandlers,
  [RECEIVE_RESULT] (state, { resourceName, result, text }) {
    if (text !== state[resourceName]?.text) {
      return state;
    }
    return {
      ...state,
      [resourceName]: {
        ...state[resourceName],
        isSearching: false,
        result
      }
    };
  }
};

export default function reduxSearch ({
  resourceIndexes = {},
  resourceSelector,
  searchApi = new SearchApi(),
  searchStateSelector = defaultSearchStateSelector
} = {}) {
  return createStore => (reducer, initialState) => {
    const store = applyMiddleware(
      searchMiddleware(searchApi)
    )(createStore)(reducer, initialState);

    store.search = searchApi;
    store[SEARCH_STATE_SELECTOR] = searchStateSelector;

    const resourceNames = Object.keys(resourceIndexes);
    store.dispatch(actions.initializeResources(resourceNames));

    searchApi.subscribe(({ result, resourceName, text }) => {
      // Here we handle item responses
      // It can be fancier, but at its core this is all it is
      store.dispatch(receiveResult(resourceName)(result, text));
    }, error => {
      // TODO: Somehow handle error; redux-router lets you pass a callback
      throw error;
    });

    // Auto-index if a :resourceSelector has been provided
    if (resourceSelector) {
      let currentResources = {};

      store.subscribe(() => {
        const nextState = store.getState();
        const searchState = store[SEARCH_STATE_SELECTOR](nextState);

        for (let resourceName in resourceIndexes) {
          const resource = resourceSelector(resourceName, nextState);

          // Only rebuild the search index for resources that have changed
          if (currentResources[resourceName] !== resource) {
            currentResources[resourceName] = resource;

            const resourceIndex = resourceIndexes[resourceName];
            const searchString = searchState[resourceName].text;

            store.dispatch(actions.indexResource({
              fieldNamesOrIndexFunction: resourceIndex,
              resourceName,
              resources: resource,
              state: nextState
            }));
            store.dispatch(actions.search(resourceName)(searchString));
          }
        }
      });
    }

    return store;
  };
}
