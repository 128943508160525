import { createSelector } from "reselect";

export const settingsState = (state) => state.settings;

export const displaySettingsSelector = createSelector(
  settingsState,
  (settings) => settings.display
);

export const outputsSelector = createSelector(
  settingsState,
  (settings) => settings.outputs
);

export const settingsLoadStateSelector = (state) => (state.settings || {})._loaded || false;
