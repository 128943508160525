import { faKeyboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Popover, PopoverBody, PopoverHeader, Table } from "reactstrap";

const targetID = 'keyboardShortcutsButton';

const isMac = typeof(navigator) !== "undefined" && navigator.userAgentData.platform.toUpperCase().indexOf('MAC') >= 0;

const mod = isMac ? 'Command' : 'Ctrl';

export const KeyboardShortcuts = () => {

  const [showing, setShowing] = React.useState(false);
  const toggle = React.useCallback(
    () => setShowing(s => !s),
    [setShowing]
  );

  return (
    <>
      <Button
        color='link'
        id={targetID}
        onClick={toggle}
      >
        <FontAwesomeIcon icon={faKeyboard} />
        Keyboard shortcuts
      </Button>
      <Popover
        isOpen={showing}
        placement='top'
        target={targetID}
        toggle={toggle}
        trigger='legacy'
      >
        <PopoverHeader>Keyboard shortcuts</PopoverHeader>
        <PopoverBody>
          You can use the following keyboard shortcuts in JustWords:

          <Table size='sm'>
            <tbody>
              <tr>
                <th>↑ (Up arrow)</th>
                <td>Previous linegroup/section</td>
              </tr>
              <tr>
                <th>↓ (Down arrow)</th>
                <td>Next linegroup/section</td>
              </tr>
              <tr>
                <th>1–9</th>
                <td>Jump to verse 1–9</td>
              </tr>
              <tr>
                <th>V</th>
                <td>Jump to next verse</td>
              </tr>
              <tr>
                <th>C</th>
                <td>Jump to next chorus</td>
              </tr>
              <tr>
                <th>B</th>
                <td>Jump to next bridge</td>
              </tr>
              <tr>
                <th>Escape</th>
                <td>Clear words</td>
              </tr>
              <tr>
                <th>Shift+Escape</th>
                <td>Fade to black</td>
              </tr>
              <tr>
                <th>{mod}+B</th>
                <td>Open Bible dialog</td>
              </tr>
              <tr>
                <th>{mod}+L</th>
                <td>Open Library</td>
              </tr>
              <tr>
                <th>{mod}+F</th>
                <td>Search Library</td>
              </tr>
            </tbody>
          </Table>

        </PopoverBody>

      </Popover>
    </>
  );
};
