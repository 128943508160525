import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';

import FormField from 'components/FormField';

export default () => (
  <Fragment>
    <FormGroup className='linear'>
      <Label for='ccli'>CCLI licence number:</Label>
      <FormField name='ccli' />
    </FormGroup>
    <p>Your CCLI licence number must be displayed along with copyright information for each song you reproduce under that licence.</p>
  </Fragment>
);
