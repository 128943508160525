import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dayjs from 'dayjs';

import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { pendingActionCountSelector, syncStateSelector } from '../selectors';
import * as constants from '../constants';
import { sync } from '../actions';
import auth from 'auth';


const BUTTON_COLOUR_STATES = {
  [constants.State.OK]: 'primary',
  [constants.State.SYNCING]: 'success',
  [constants.State.ERROR]: 'danger'
};

const DATE_FORMAT = 'h:mm a, ddd D MMM';

const SyncStateButton = ({
  pendingCount,
  state: { lastSuccess, lastAttempt, state },
  syncEnabled,
  sync
}) => {

  let caption = '';

  if (!syncEnabled) {
    caption = 'Your account needs to be upgraded in order to sync your library and themes with other computers.';
  }
  else if (state === constants.State.SYNCING) {
    caption = `Sync started at ${Dayjs(lastAttempt).format(DATE_FORMAT)}`;
  }
  else if (state === constants.State.OK) {
    if (lastSuccess) {
      caption = `Last sync completed at ${Dayjs(lastSuccess).format(DATE_FORMAT)}`;
    }
    else {
      caption = 'Not yet synced';
    }
  }
  else if (state === constants.State.ERROR) {
    caption = `Last sync attempted at ${Dayjs(lastAttempt).format(DATE_FORMAT)} but failed`;
  }

  const badgeCaptionNoun = pendingCount === 1 ? 'item' : 'items';

  const disabled = !syncEnabled || (state === constants.State.SYNCING && false);

  return (
    <Button
      className='d-flex align-items-center justify-content-between px-1'
      color={BUTTON_COLOUR_STATES[state]}
      disabled={disabled}
      onClick={sync}
      title={caption}
    >
      <FontAwesomeIcon
        icon={faSyncAlt}
        spin={state === constants.State.SYNCING}
      />
      {
        !disabled && pendingCount > 0 ? (
          <Badge
            color='success'
            pill
            title={`${pendingCount} ${badgeCaptionNoun} to sync`}
          >
            {pendingCount}
          </Badge>
        ) : null
      }

    </Button>
  );

};

export default connect(
  createStructuredSelector({
    pendingCount: pendingActionCountSelector,
    state: syncStateSelector,
    syncEnabled: auth.selectors.hasFeature(constants.SYNC_FEATURE)
  }),
  {
    sync
  }
)(SyncStateButton);
