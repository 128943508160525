import { Transforms, Element, Text, Editor } from "slate";
import { HistoryEditor } from "slate-history";
import { pasteHTML } from "./pasteHelpers";

const isResourceBlock = (block) => {
  return ['verse', 'chorus', 'bridge', 'section'].includes(block.type);
};
const isLineGroup = (block) => block.type === 'lineGroup';
const isLine = (block) => block.type === 'line';

export const withResourceEditor = editor => {

  const { insertBreak, insertData, normalizeNode } = editor;

  let isMidOperation = false;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    HistoryEditor.withoutSaving(
      editor,
      () => {
        if (!isMidOperation && Element.isElement(node)) {
          if (!node.children || node.children.length === 0) {
            Transforms.removeNodes(editor, { at: path });
            return;
          }

          if (isLineGroup(node) && path.length < 2) {
            Transforms.wrapNodes(
              editor,
              {
                type: 'verse'
              },
              { at: path }
            );
            return;
          }

          if (isResourceBlock(node)) {
            if (path.length > 1) {
              Transforms.liftNodes(
                editor,
                { at: path }
              );
              return;
            }

            if (editor.resourceType === 'Song' && node.type === 'section') {
              Transforms.setNodes(
                editor,
                { type: node.prevType || 'verse' },
                { at: path }
              );
              return;
            }
            else if (editor.resourceType === 'Liturgy' && node.type !== 'section') {
              Transforms.setNodes(
                editor,
                { type: 'section', prevType: node.type },
                { at: path }
              );
              return;
            }

          }

          if (isLineGroup(node)) {
            node.children.forEach(
              (child, idx) => {
                if (Text.isText(child)) {
                  Transforms.wrapNodes(
                    editor,
                    { type: 'line' },
                    { at: [...path, idx] }
                  );
                  return;
                }
              }
            );
          }
        }
      }
    );

    normalizeNode(entry);

    HistoryEditor.withoutSaving(
      editor,
      () => {
        const perTypeCounts = {};
        editor.children.forEach(
          (block, idx) => {
            const newIndex = (perTypeCounts[block.type] || 0);
            if (!block.index || block.index !== newIndex) {
              Transforms.setNodes(
                editor,
                { index: newIndex, descriptor: `${block.type[0].toUpperCase()}${block.type.slice(1)} ${newIndex + 1}` },
                { at: [idx] }
              );
            }
              perTypeCounts[block.type] = newIndex + 1;
            }
        );
      }
    );

  };

  editor.setBlockType = (newType) => () => {
    if (editor.selection && editor.selection.focus !== editor.selection.anchor) {
      isMidOperation = true;
      Transforms.liftNodes(
        editor,
        {
          match: isLineGroup,
        }
      );
      isMidOperation = false;
      Transforms.wrapNodes(
        editor,
        {
          type: newType
        },
        {
          match: isLineGroup,
          split: true
        }
      );
      Transforms.liftNodes(
        editor,
        {
          match: (n, p) => isResourceBlock(n) && p.length > 1,
        }
      );
    }
    else {
      Transforms.setNodes(
        editor,
        { type: newType },
        {
          match: isResourceBlock
        }
      );
    }
  };

  editor.setVerse = editor.setBlockType('verse');
  editor.setChorus = editor.setBlockType('chorus');
  editor.setBridge = editor.setBlockType('bridge');
  editor.setSection = editor.setBlockType('section');

  editor.groupLines = () => {
    const anchorPath = editor.selection.anchor.path;
    const focusPath = editor.selection.focus.path;

    const firstBlockIndex = Math.min(focusPath[0], anchorPath[0]);

    const firstBlockType = editor.children[firstBlockIndex].type;

    if (anchorPath[0] !== focusPath[0]) {
      // Trying to form a linegroup that spans blocks!
      isMidOperation = true;
      const newBlockPath = [firstBlockIndex + 1];
      Transforms.insertNodes(
        editor,
        [{
          type: firstBlockType,
          children: [{
            type: 'lineGroup',
            meta: 'new',
            children: []
          }]
        }],
        {
          at: newBlockPath
        }
      );
      isMidOperation = false;
      Transforms.moveNodes(
        editor,
        {
          match: isLine,
          to: [...newBlockPath, 0, 0]
        }
      );
    }
    else {
      if (anchorPath[1] !== focusPath[1]) {
        isMidOperation = true;
        Transforms.liftNodes(
          editor,
          {
            match: isLine
          }
        );
        isMidOperation = false;
      }

      Transforms.wrapNodes(
        editor,
        {
          type: 'lineGroup'
        },
        {
          match: isLine
        }
      );
      Transforms.liftNodes(
        editor,
        {
          match: (n, p) => isLineGroup(n) && p.length > 2,
        }
      );
    }
  };

  editor.setLineStyle = style => () => {
    Transforms.setNodes(
      editor,
      { style },
      {
        match: isLine
      }
    );
  };

  editor.setMajor = editor.setLineStyle('major');
  editor.setMinor = editor.setLineStyle('minor');

  editor.insertBreak = () => {
    const prevLine = Editor.node(editor, editor.selection.anchor);
    if (prevLine[0].text === '') {
      const currentBlockType = Editor.node(editor, editor.selection.anchor.path.slice(0, 1))[0].type;
      Transforms.insertNodes(
        editor,
        {
          type: currentBlockType,
          children: [
            {
              type: 'lineGroup',
              children: [{
                type: 'line',
                style: 'normal',
                children: [
                  { text: '' }
                ]
              }]
            }
          ]
        },
        {
          at: [editor.selection.anchor.path[0] + 1]
        }
      );
      Transforms.removeNodes(
        editor,
        {
          match: isLine,
          at: editor.selection.anchor.path
        }
      );
      Transforms.select(
        editor,
        [editor.selection.anchor.path[0] + 1, 0, 0]
      );
    }
    else {
      insertBreak();
    }
  };

  editor.insertData = (data) => {

    const html = data.getData('text/html');

    if (html) {
      // Be more intelligent about pasting HTML stuff
      const parsed = new DOMParser().parseFromString(html, 'text/html');

      if (pasteHTML(editor, parsed)) {
        return;
      }
    }

    insertData(data);
  };

  return editor;
};
