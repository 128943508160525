import * as React from 'react';
import classNames from 'classnames';

import { Button, ButtonGroup } from 'reactstrap';
import { Output } from '../../schemas';
import { OutputForm } from './OutputForm';
import { useSelector } from 'react-redux';
import { displaySettingsSelector } from '../../selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';

const OutputSize = ({ output }) => output.width && output.height ? <span>{output.width}x{output.height}</span> : <span>Canvas size</span>;

const OutputListItem = ({ index, onClick, output, selected }) => (
  <li
    className={classNames({
      'live': selected
    })}
    onClick={onClick}
  >
    <FontAwesomeIcon
      fixedWidth
      icon={faWindowMaximize}
      title='Window'
    />
    {index + 1}: <OutputSize output={output} /> @ ({output.left}, {output.top})
    {
      output.fullscreen && ' [FS]'
    }
    {
      output.dirty && '*'
    }
  </li>
);

export const OutputsList = ({ workingCopy, setWorkingCopy }) => {

  const [selectedOutput, setSelectedOutput] = React.useState(0);
  const canvasSize = useSelector(displaySettingsSelector);

  const addNew = React.useCallback(
    (e) => {
      setSelectedOutput(workingCopy.length);
      const newOutput = Output.cast(canvasSize);
      newOutput.dirty = true;
      setWorkingCopy(wc => [...wc, newOutput]);
      e.preventDefault();
    },
    [canvasSize, setSelectedOutput, setWorkingCopy, workingCopy.length]
  );

  const deleteSelected = React.useCallback(
    (e) => {
      setWorkingCopy(
        wc => {
          const newWC = [...wc];
          newWC.splice(selectedOutput, 1);
          return newWC;
        }
      );
      setSelectedOutput(so => Math.max(so, workingCopy.length - 2));
      e.preventDefault();
    },
    [setSelectedOutput, selectedOutput, setWorkingCopy, workingCopy.length]
  );

  const updateOutput = idx => newOutput => {
    const newWC = [...workingCopy];
    newWC.splice(idx, 1, {
      ...newOutput,
      dirty: true
    });
    setWorkingCopy(newWC);
  };

  return (
    <div className='outputs-editor'>
      <div className='item-list'>
        <ul>
          {
            workingCopy.map(
              (o, idx) => (
                <OutputListItem
                  index={idx}
                  key={idx}
                  onClick={() => setSelectedOutput(idx) }
                  output={o}
                  selected={idx === selectedOutput}
                />
              )
            )
          }
        </ul>
        <ButtonGroup>
          <Button
            color='primary'
            onClick={addNew}
            type='button'
          >
            <FontAwesomeIcon
              icon={faPlus}
            />
            New
          </Button>
          <Button
            color='danger'
            disabled={workingCopy.length <= 1}
            onClick={deleteSelected}
            type='button'
          >
            <FontAwesomeIcon
              icon={faTrash}
            />
            Delete
          </Button>
        </ButtonGroup>
      </div>
      {
        selectedOutput >= 0 && selectedOutput < workingCopy.length && <OutputForm
          canvasSize={canvasSize}
          index={selectedOutput}
          onChange={updateOutput(selectedOutput)}
          output={workingCopy[selectedOutput]}
        />
      }
    </div>
  );
};
