import React, { Fragment } from 'react';

import { BibleModal } from 'bible';
import { SettingsModal } from 'settings';
import { ThemeEditorModal } from 'theme';
import { AddResourceModal, EditResourceModal, ImportResourceModal } from 'resource';
import LibraryModal from './LibraryModal';

export default () => (
  <Fragment>
    <BibleModal />
    <SettingsModal />
    <ThemeEditorModal />
    <AddResourceModal />
    <EditResourceModal />
    <ImportResourceModal />
    <LibraryModal />
  </Fragment>
);
