import * as constants from './constants';
import { resolved } from 'chrome';
import { newTheme } from './functions';

const INITIAL_STATE = {
  currentTheme: null,
  loaded: false,
  themes: {}
};


export default (state=INITIAL_STATE, action) => {
  const nextThemes = { ...state.themes };

  switch (action.type) {

    case resolved(constants.LOAD):
      const newState = {
        ...(action.payload || state),
        loaded: true
      };

      if (Object.entries(newState.themes).length === 0) {
        const myNewTheme = newTheme();
        newState.themes[myNewTheme.id] = myNewTheme;
      }

      if (!newState.currentTheme) {
        newState.currentTheme = state.currentTheme || Object.keys(newState.themes || {})[0];
      }

      return newState;

    case constants.PUT_THEME:
      nextThemes[action.theme.id] = { ...action.theme };
      return {
        ...state,
        themes: nextThemes
      };

    case constants.SELECT_THEME:
      return {
        ...state,
        currentTheme: action.id
      };

    case constants.DELETE_THEME:
      const themeKeys = Object.keys(state.themes);
      if (themeKeys.length <= 1) {
        // Don't let them delete the last theme
        return state;
      }

      delete nextThemes[action.theme.id];

      const newCurrent = (action.theme.id === state.currentTheme) ? Object.keys(nextThemes)[0] : state.currentTheme;

      return {
        themes: nextThemes,
        currentTheme: newCurrent
      };

    default:
      return state;
  }
};
