import { getExtensionID, resolved, rejected } from './functions';

export default store => next => action => {
  if (action.chrome) {
    if (typeof(window) !== 'undefined' && window.chrome && getExtensionID()) {
      window.chrome.runtime.sendMessage(
        getExtensionID(),
        action.chrome,
        (response) => {
          store.dispatch({
            type: resolved(action.type),
            payload: response,
            originalAction: action
          });

        }
      );
      delete action.chrome;
    }
    else {
      store.dispatch({
        type: rejected(action.type),
        originalAction: action
      });
    }

  }

  return next(action);
};
