import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Form, FormGroup, FormText, Label } from 'reactstrap';
import { ErrorText } from 'components/ErrorText';

export default ({ error, setError }) => (
  <Form
    className='flex-grow-1'
    id='subscription-form'
  >
    <FormGroup>
      <Label>
        Credit or debit card
      </Label>
      <CardElement onChange={() => setError(null)} />
      {
        error ? <ErrorText error={error} /> : null
      }
    </FormGroup>
    <FormText color="muted">
      You can cancel at any time. Your card details will be securely stored by
      our payment processor, <a href='https://stripe.com/gb/about'>Stripe</a>.
    </FormText>
  </Form>
);
