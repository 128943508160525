import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { NavLink } from 'redux-first-router-link';
import { DropdownToggle, DropdownMenu, DropdownItem, Navbar, NavbarBrand, Nav, NavItem, UncontrolledDropdown } from 'reactstrap';
import auth from 'auth';
import modal from 'modal';
import sync from 'sync';
import { createStructuredSelector } from 'reselect';

const SmartNavItem = ({ children, to }) => (
  <NavItem>
    <NavLink
      className='nav-link'
      exact
      to={{ type: to }}
    >
      {children}
    </NavLink>
  </NavItem>
);

const CommonNavItems = () => (
  <Fragment>
    <SmartNavItem to='location/HOME'>Home</SmartNavItem>
    <SmartNavItem to='location/FEATURES'>Features</SmartNavItem>
    <SmartNavItem to='location/PRICING'>Pricing</SmartNavItem>
  </Fragment>
);

const LoggedOutNav = ({ showModal }) => (
  <Fragment>
    <Nav navbar>
      <CommonNavItems />
      <SmartNavItem to='location/START'>Get started</SmartNavItem>
    </Nav>
    <Nav>
      <button
        className='btn btn-link'
        onClick={() => { showModal('login'); return false; }}>Log in</button>
      <span className='navbar-text mx-2'>or</span>
      <button
        className='btn btn-link'
        onClick={() => { showModal('register'); return false; }}>Sign up</button>
    </Nav>
    <auth.LoginModal showModal={showModal} />
    <auth.ResetPasswordModal showModal={showModal} />
    <auth.RegistrationModal showModal={showModal} />
  </Fragment>
);

const LoggedInNav = ({ account, dispatch, logOut, syncEnabled }) => (
  <Fragment>
    <Nav navbar>
      <CommonNavItems />
      <SmartNavItem to='location/LIBRARY'>Library</SmartNavItem>
      <SmartNavItem to='location/LIVE'>Live</SmartNavItem>
    </Nav>
    <Nav>
      <sync.SyncStateButton />
      <sync.AutoSync />
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          nav>
          {account.email}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            disabled={!syncEnabled}
            onClick={() => dispatch(sync.actions.syncFrom(0))}
          >
            Full sync
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch({ type: 'location/ACCOUNT' })}
          >
            My Account
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={logOut}>Log out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  </Fragment>
);

const Header = ({ account, dispatch, loggedIn, logOut, showModal, syncEnabled }) => (
  <div className="header navbar-expand">
    <Navbar
      color='primary'
      dark>
      <NavbarBrand>JustWords</NavbarBrand>
      {
        loggedIn ? <LoggedInNav
          account={account}
          dispatch={dispatch}
          logOut={logOut}
          syncEnabled={syncEnabled} />
        : <LoggedOutNav showModal={showModal} />
      }
    </Navbar>
  </div>
);

export default connect(
  createStructuredSelector({
    loggedIn: auth.selectors.isLoggedIn,
    account: auth.selectors.accountSelector,
    syncEnabled: auth.selectors.hasFeature('SYNC')
  }),
  (dispatch) => ({
    dispatch,
    showModal: (...args) => dispatch(modal.actions.showModal(...args)),
    logOut: () => dispatch(auth.actions.logOut())
  })
)(Header);
