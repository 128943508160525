import { combineReducers } from 'redux';

import * as constants from './constants';

import { resolved, rejected } from 'api';
import auth from 'auth';

const stateReducer = (state=constants.State.OK, action) => {
  switch(action.type) {
    case constants.START_SYNC:
      return constants.State.SYNCING;
    case rejected(constants.START_SYNC):
      return constants.State.ERROR;
    case resolved(constants.START_SYNC):
      return constants.State.OK;
    default:
      if (state === constants.State.SYNCING && action.type === auth.constants.LOG_OUT) {
        return constants.State.OK;
      }
      return state;
  }
};

const pendingReducer = (syncedActionTypes=[]) => (state={}, action) => {
  if (action.type === resolved(constants.START_SYNC)) {
    return {
      ...state,
      [action.meta.previousAction.payload.account]: []
    };
  }
  if (
    syncedActionTypes.includes(action.type) &&
    (!action.meta || !action.meta.bypassSyncQueue)
  ) {
    const account = action.meta.account;

    if (action.meta && action.meta.bulk) {
      return {
        ...state,
        [account]: [
          ...state[account] || [],
          ...action.resourceActions.map(
            ra => ({
              ...ra,
              type: ra.type || action.type.replace('BULK_', ''),
              meta: {
                ...ra.meta,
                timestamp: Date.now()
              }
            })
          )
        ]
      };
    }
    else {

      return {
        ...state,
        [account] : [
          ...state[account] || [],
          {
            ...action,
            meta: {
              ...action.meta,
              timestamp: Date.now()
            }
          }
        ]
      };
    }

  }
  return state;
};

const lastSuccessfulSync = (state={}, action) => {
  if (action.type === resolved(constants.START_SYNC)) {
    return {
      ...state,
      [action.meta.previousAction.payload.account]: Date.now()
    };
  }
  return state;
};

const lastAttemptedSync = (state={}, action) => {
  if (action.type === constants.START_SYNC) {
    return {
      ...state,
      [action.payload.account]: Date.now()
    };
  }
  return state;
};

export default syncedActionTypes => combineReducers({
  lastAttemptedSync,
  lastSuccessfulSync,
  pending: pendingReducer(syncedActionTypes),
  state: stateReducer
});
