import React from 'react';
import PageWrapper from 'components/PageWrapper';

import { Button, Table } from 'reactstrap';
import { NavLink } from 'redux-first-router-link';

import { faCheckCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Nay = () => (
  <FontAwesomeIcon
    className='text-danger'
    icon={faTimesCircle}
    size='2x'
  />
);

const May = () => (
  <FontAwesomeIcon
    className='text-info'
    icon={faInfoCircle}
    size='2x'
  />
);

const Yay = () => (
  <FontAwesomeIcon
    className='text-success'
    icon={faCheckCircle}
    size='2x'
  />
);

const yay = <Yay />;
const nay = <Nay />;
const may = <May />;

const workOfflineText = (
  <span>
    Work without an Internet connection<sup>1</sup>
  </span>
);

const priceGridConfig = {
  'Display songs': {
    free: [yay, 'No watermarks, logos or time limits'],
    sub: [yay, 'No watermarks, logos or time limits'],
  },
  'Themes': {
    free: [yay, 'Unlimited themes'],
    sub: [yay, 'Unlimited themes']
  },
  'Work offline': {
    free: [yay, workOfflineText],
    sub: [yay, workOfflineText]
  },
  'Get updates': {
    free: [yay, 'Receive free updates to JustWords'],
    sub: [yay, 'Receive free updates to JustWords']
  },
  'Song library': {
    free: [may, 'Limited to 20 songs/liturgy'],
    sub: [yay, 'Unlimited songs and liturgy']
  },
  'Sync across computers': {
    free: [nay, null],
    sub: [yay, 'Unlimited computers']
  }
};

export default () => (
  <PageWrapper>
    <h1>Pricing</h1>
    <p>
      JustWords is available in a free tier and with a monthly subscription.
    </p>

    <Table
      borderless
      className='pricing-table'
      striped
    >
      <thead>
        <tr>
          <td></td>
          <th colSpan={2}>JustWords Free</th>
          <th colSpan={2}>JustWords Premium</th>
        </tr>
      </thead>
      <tbody>
        {
         Object.entries(priceGridConfig).map(
           ([key, { free, sub }], idx) => (
             <tr key={idx}>
               <th>{ key }</th>
               <td style={{ width: '2rem' }}>{ free[0] }</td>
               <td>{ free[1] }</td>
               <td style={{ width: '2rem' }}>{ sub[0] }</td>
               <td>{ sub[1] }</td>
             </tr>
           )
         )
       }
      </tbody>
      <tbody className='price-summary'>
        <tr>
          <th>Price</th>
          <td colSpan={2}>
            <span>
              Free forever.
            </span>
            <NavLink to={{ type: 'location/START' }}>
              <Button color='secondary'>
                Get started
              </Button>
            </NavLink>
          </td>
          <td colSpan={2}>
            <span>
              £5 per month.
            </span>
            <NavLink to={{ type: 'location/ACCOUNT_SUBSCRIBE' }}>
              <Button color='primary'>
                Subscribe now
              </Button>
            </NavLink>
          </td>
        </tr>
      </tbody>
    </Table>

    <small className='text-muted'>
      <sup>1</sup> Internet connection initially required, but JustWords can
      then be used offline. Some functions (downloading Scripture, sync) require
      an Internet connection.
    </small>

  </PageWrapper>
);
