import React from 'react';
import { connect } from 'react-redux';
import { extensionIDSelector } from '../selectors';
import { setExtensionID } from '../actions';
import { getExtensionID } from '../functions';
import { createStructuredSelector } from 'reselect';

class PluginDetector extends React.Component {

  constructor(props) {
    super(props);
    this._interval = null;
    this.search = this.search.bind(this);
    this.stopSearching = this.stopSearching.bind(this);
  }

  search() {
    const maybeExtension = getExtensionID();
    if (maybeExtension) {
      this.props.setExtensionID(maybeExtension);
      this.stopSearching();
    }
  }

  componentDidMount() {
    if (!this.props.extensionID && typeof(window) !== 'undefined') {
      this._interval = window.setInterval(this.search, 100);
    }
  }

  componentWillUnmount() {
    this.stopSearching();
  }

  stopSearching() {
    if (this._interval && typeof(window) !== 'undefined') {
      window.clearInterval(this._interval);
      this._interval = undefined;
    }
  }

  render() {
    return null;
  }
}

export default connect(
  createStructuredSelector({
    extensionID: extensionIDSelector
  }),
  {
    setExtensionID
  }
)(PluginDetector);
