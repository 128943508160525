import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  faSave,
  faTrashAlt
} from '@fortawesome/free-regular-svg-icons';
import { createStructuredSelector } from 'reselect';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';
import resourceModule from 'resource';
import playlistModule from 'playlist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { previewResource, liveResource } from '../actions';
import { liveResourceSelector } from '../selectors';
import { Button, ButtonGroup, Card, CardBody } from 'reactstrap';
import modal from 'modal';
import PlaylistThemeSelector from './PlaylistThemeSelector';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import LoadPlaylistButton from '../../playlist/components/LoadPlaylistButton';
import PopupConfirmationButton from './PopupConfirmationButton';
import { EditResourceButton } from '../../../components/EditResourceButton';

const PlaylistEntry = SortableElement(
  ({
    children,
    extraButtons,
    isLive,
    onClick,
    onDoubleClick,
    playlistIndex,
    remove,
    resource,
    themeID
  }) => {
    return (
      <li
        className={classNames('playlist-entry', { 'library-entry': true, 'playlist-live': isLive })}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <FontAwesomeIcon
          fixedWidth
          icon={ resourceModule.constants.ICONS[resource.type] || 'file' }
          size='2x'
        />
        <div className='d-flex align-items-center'>
          {children || <span className='flex-grow-1 flex-shrink-1'>{resource.title}</span>}
          {
            themeID && (
              <small className='text-muted flex-shrink-0'>
                <FontAwesomeIcon
                  className='ml-1'
                  icon={faPalette}
                  title='Uses custom theme'
                />
              </small>
            )
          }
        </div>
        <ButtonGroup className='library-tools'>
          <PlaylistThemeSelector
            currentThemeID={themeID}
            index={playlistIndex}
          />
          {
            extraButtons
          }
          <Button
            color='danger'
            onClick={remove}
            size='sm'
            title="Remove from playlist"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </ButtonGroup>
      </li>
    );
  }
);

const ResourcePlaylistEntry = ({ currentlyLive, entry, index, liveResource, previewResource, resources, remove }) => {
  if (!resources[entry.id]) {
    // Playlist gets loaded before the resource library does so we'll temporarily have hanging references
    return (
      <UnknownPlaylistEntry
        entry={entry}
        index={index}
        remove={remove}
      />
    );
  }
  return (
    <PlaylistEntry
      extraButtons={
        <EditResourceButton
          resource={resources[entry.id]}
        />
      }
      index={index}
      isLive={currentlyLive && currentlyLive.id === entry.id}
      onClick={
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          previewResource(resources[entry.id]);
        }
      }
      onDoubleClick={
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          previewResource(null);
          liveResource(resources[entry.id]);
        }
      }
      playlistIndex={index}
      remove={remove}
      resource={resources[entry.id]}
      themeID={entry.meta ? entry.meta.themeID : null}
    />
  );
};

const ScripturePlaylistEntry = ({ currentlyLive, entry, index, liveResource, previewResource, remove }) => (
  <PlaylistEntry
    index={index}
    isLive={currentlyLive && currentlyLive.id === entry.id}
    onClick={
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        previewResource(entry);
      }
    }
    onDoubleClick={
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        previewResource(null);
        liveResource(entry);
      }
    }
    playlistIndex={index}
    remove={remove}
    resource={entry}
    themeID={entry.meta ? entry.meta.themeID : null}
  >
    <span className='flex-grow-1 flex-shrink-1'>
      {entry.title}
    </span>
    <small className='text-muted flex-shrink-0'>
      { entry.meta.version || entry.meta.attribution }
    </small>
  </PlaylistEntry>
);

const UnknownPlaylistEntry = ({ entry, index, remove }) => (
  <PlaylistEntry
    index={index}
    remove={remove}
    resource={{
      title: entry.title,
      id: entry.id,
      type: 'unknown'
    }}
  />
);

const EntryTypes = {
  [playlistModule.constants.Types.RESOURCE]: ResourcePlaylistEntry,
  [playlistModule.constants.Types.SCRIPTURE]: ScripturePlaylistEntry
};

const SortablePlaylist = SortableContainer(
  ({ currentlyLive, items, resources, liveResource, previewResource, removeItem }) => (
    <ul className='library-contents'>
      {
      items.map(
        (entry, idx) => {
          const MyPlaylistEntry = EntryTypes[entry.type];
          return (
            <MyPlaylistEntry
              currentlyLive={currentlyLive}
              entry={entry}
              index={idx}
              key={idx}
              liveResource={liveResource}
              playlistIndex={idx}
              previewResource={previewResource}
              remove={() => removeItem(idx)}
              resources={resources}
            />
          );
        }
      )
    }
    </ul>
  )
);

const EmptyPlaylistMessage = connect(
  null,
  {
    showModal: modal.actions.showModal
  }
)(
  ({ showModal }) => (
    <div className='flex-grow-1 d-flex justify-content-center flex-column'>
      <p className='text-muted'>
        The playlist is empty. Add songs and liturgy from the{' '}
        <Button
          color='link'
          onClick={() => showModal('library')}>library</Button> or{' '}
        scripture from the <Button
          color='link'
          onClick={() => showModal('bible')}>Bible</Button>!
      </p>
    </div>
  )
);

const PlaylistSection = ({ children, clearPlaylist, isEmpty, playlist }) => (
  <div className='card-deck playlist'>
    <Card>
      <CardBody>
        <div className='d-flex justify-content-between'>
          <h4>Playlist</h4>
          <ButtonGroup>
            <LoadPlaylistButton size='sm' />
            <Button
              className='p-0'
              color='secondary'
              disabled={isEmpty}
              onClick={() => playlistModule.functions.generateSavedPlaylistFile(playlist)}
              size='sm'
              title='Save playlist'
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faSave}
              />
            </Button>
            <PopupConfirmationButton
              actionText='Clear playlist'
              className='p-0'
              color='danger'
              disabled={isEmpty}
              onClick={clearPlaylist}
              size='sm'
              text='Are you sure you want to clear the playlist?'
              title='Clear playlist'
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faTrashAlt}
              />
            </PopupConfirmationButton>
          </ButtonGroup>
        </div>
        { children }
      </CardBody>
    </Card>
  </div>
);

const Playlist = ({ currentlyLive, clearPlaylist, moveItem, playlist, resources, previewResource, liveResource, removeItem }) => (
  <PlaylistSection
    clearPlaylist={clearPlaylist}
    isEmpty={playlist.length === 0}
    playlist={playlist}
  >
    { playlist.length === 0  ?
      <EmptyPlaylistMessage />
    :
      <Fragment>
        <SortablePlaylist
          currentlyLive={currentlyLive}
          distance={1}
          helperClass='library-entry--dragging'
          items={playlist}
          liveResource={liveResource}
          onSortEnd={({ oldIndex, newIndex }) => moveItem(oldIndex, newIndex)}
          previewResource={previewResource}
          removeItem={removeItem}
          resources={resources}
        />
        <small className='text-muted text-center'>
          Drag and drop to re-order
        </small>
      </Fragment>
    }
  </PlaylistSection>
);

export default connect(
  createStructuredSelector({
    currentlyLive: liveResourceSelector,
    resources: resourceModule.selectors.resourceState,
    playlist: playlistModule.selectors.currentPlaylistSelector
  }),
  {
    previewResource,
    liveResource,
    clearPlaylist: playlistModule.actions.clear,
    moveItem: playlistModule.actions.moveItem,
    removeItem: playlistModule.actions.removeItem
  }
)(Playlist);
