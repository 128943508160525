import React from 'react';

import { DisplaySize } from './DisplaySize';
import { OutputsList } from './OutputsList';

const OutputPage = ({ outputs, setOutputs }) => {

  return (
    <div className='d-flex flex-column h-100'>
      <h4>Canvas size</h4>
      <DisplaySize />
      <h4>Outputs</h4>
      <OutputsList
        setWorkingCopy={setOutputs}
        workingCopy={outputs}
      />
    </div>
  );
};

export default OutputPage;
