import { resolved } from 'chrome';
import { ACTION_TYPE } from './constants';

const alphaSort = (a, b) => {
  const nameA = a.displayName.toUpperCase();
  const nameB = b.displayName.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export default (state=[], action) => {
  switch (action.type) {
    case resolved(ACTION_TYPE):
      return [...action.payload].sort(alphaSort);

    default:
      return state;
  }
};
