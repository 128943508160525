import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, FormGroup, Label, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from 'auth';
import modal, { connectedModal } from 'modal';
import { CHANGE_PAYMENT_MODAL } from '../constants';
import { ErrorText } from 'components/ErrorText';
import { changePayment } from '../actions';

const ModalWrapper = connectedModal(CHANGE_PAYMENT_MODAL);

const ModalInner = () => {

  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const { email } = useSelector(auth.selectors.accountSelector);
  const dispatch = useDispatch();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement('card');

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email
      }
    });
    if (result.paymentMethod) {
      dispatch(changePayment(result.paymentMethod.id)).then(
        () => {
          setLoading(false);
          dispatch(modal.actions.showModal(null));
        }
      );
    }
    else {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader>
        Change payment card
      </ModalHeader>
      <ModalBody>
        <p>
          Your new card will not be charged until your next subscription payment
          is due.
        </p>
        <Form>
          <FormGroup>
            <Label>
              Credit or debit card
            </Label>
            <CardElement onChange={() => setError(null)} />
            {
              error ? <ErrorText error={error} /> : null
            }
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => dispatch(modal.actions.showModal(null))}>
          Cancel
        </Button>
        <Button
          color='primary'
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {
            isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
              />
            )
          }
          Save new card
        </Button>
      </ModalFooter>
    </>
  );
};

export const ChangePaymentModal = () => (
  <ModalWrapper centered>
    <ModalInner />
  </ModalWrapper>
);
