import {
  faFile,
  faFileAlt,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';

import {
  faBible,
  faMusic,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';

export const LOAD = 'resource/LOAD';
export const SAVE = 'resource/SAVE';
export const PUT_RESOURCE = 'resource/PUT';
export const DELETE_RESOURCE = 'resource/DELETE';
export const IMPORT = 'resource/IMPORT';
export const BULK_PUT_RESOURCES = 'resource/BULK_PUT';
export const TOUCH_RESOURCE_USED_TIMESTAMP = 'resource/TOUCH_USED_TIMESTAMP';

export const LOCAL_STORAGE_KEY = 'justwords_resources';

export const TYPES = {
  SONG: 'Song',
  LITURGY: 'Liturgy'
};

export const ICONS = {
  [TYPES.SONG]: faMusic,
  [TYPES.LITURGY]: faFileAlt,
  DEFAULT: faFile,
  ALL: faGlobe,
  'scripture': faBible,
  'unknown': faQuestionCircle
};

export const LICENCE_TYPES = {
  CCLI: 'Covered by CCLI',
  PUBLIC_DOMAIN: 'Public domain',
  EXPLICIT_PERMISSION: 'Explicit permission',
  OTHER: 'Other'
};

export const MINIMAL = {
  type: 'Song',
  title: '',
  content: [
    {
      type: 'verse',
      content: [
        [
          ['\xa0', 0]
        ]
      ]
    }
  ],
  meta: {
    author: '',
    licence: 'CCLI',
    copyright: ''
  }
};
