import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { getDotpath } from 'utils/dotpath';

const ReactstrapInputWrapper = ({ autoSave, field, form: { setFieldValue, submitForm, errors, touched, values }, type, ...props }) => {

  const isTouched = !!getDotpath(touched, field.name);
  const myErrors = getDotpath(errors, field.name);

  let checked = undefined;
  let getValueFromEvent = (e) => e.target.value;
  if (type === 'checkbox' || type === 'radio') {
    checked = getDotpath(values, field.name);
    getValueFromEvent = (e) => e.target.checked;
  }

  return (
    <div className='form-field'>
      <Input
        {...field}
        {...props}
        checked={checked}
        id={field.name}
        invalid={isTouched && !!myErrors}
        onChange={ (c) => {
          setFieldValue(field.name, getValueFromEvent(c), !autoSave);
          if (autoSave) {
            submitForm();
          }
        }}
        type={type}
        valid={isTouched && !myErrors}
      />
      {
        myErrors ?
          <FormFeedback>{myErrors}</FormFeedback>
        : null
      }
    </div>
  );
};

ReactstrapInputWrapper.propTypes = {
  autoSave: PropTypes.bool
};

export default ({ name, ...props }) => {
  return (
    <Field
      component={ReactstrapInputWrapper}
      name={name}
      {...props}
    />
  );
};
