import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from 'reactstrap';

import output from 'output';

const FadeToBlackToggle = ({ fadeToBlack, toggleFade }) => (
  <Button
    active={fadeToBlack}
    color='blanked'
    onClick={toggleFade}
    title='Fade to black (Shift+Esc)'
  >
    Fade
  </Button>
);

export default connect(
  createStructuredSelector({
    fadeToBlack: output.selectors.fadeToBlackSelector
  }),
  {
    toggleFade: output.actions.toggleFadeToBlack
  }
)(FadeToBlackToggle);
