import { createSelector } from 'reselect';

export const modalState = (state) => state.modal;

export const currentModalSelector = createSelector(
  modalState,
  (ms) => ms[0] ? ms[0].modal : null
);

export const modalPayloadSelector = createSelector(
  modalState,
  (ms) => ms[0] ? ms[0].payload : null
);
