import React from 'react';

import { HotKeys } from 'react-hotkeys';

import BlankToggle from './BlankToggle';
import Sidebar from './Sidebar';
import { Preview } from './Preview';
import Main from './Main';
import Footer from 'components/Footer';
import { ThemeChooser } from 'theme';
import PlaylistAutoAdvancer from './PlaylistAutoAdvancer';
import FadeToBlackToggle from './FadeToBlackToggle';
import { useDispatch } from 'react-redux';
import { toggleBlank } from '../actions';
import output from 'output';


const keyMap = {
  TOGGLE_BLANK: 'Esc',
  TOGGLE_FADE_TO_BLACK: 'Shift+Esc'
};


export default () => {

  const dispatch = useDispatch();

  // NB We handle these key events here so that pressing e.g. Escape with an
  // open modal will just close the modal, not close it _and_ toggle blank.
  const handlers = {
    TOGGLE_BLANK: () => dispatch(toggleBlank()),
    TOGGLE_FADE_TO_BLACK: (e) => {
      e.preventDefault();
      dispatch(output.actions.toggleFadeToBlack());
    }
  };

  return (
    <HotKeys
      className="main-window"
      handlers={handlers}
      keyMap={keyMap}
      root
    >
      <PlaylistAutoAdvancer />
      <Sidebar />
      <div className='toolbar preview-toolbar wrap'>
        <h4>Preview</h4>
      </div>
      <div className="preview">
        <Preview />
      </div>
      <div className='toolbar live-toolbar wrap'>
        <h4>Live</h4>
        <ThemeChooser />
        <BlankToggle />
        <FadeToBlackToggle />
      </div>
      <div className="live">
        <Main />
      </div>
      <Footer />
    </HotKeys>
  );
};
