import * as React from 'react';

const Section = ({ title, content }) => (
  <div>
    <h3>{title}</h3>
    <ul>
      {
        content.map(
          (c, idx) => (
            <li key={idx}>{c}</li>
          )
        )
      }
    </ul>
  </div>
);

const SECTIONS = [
  ['added', 'Added'],
  ['removed', 'Removed'],
  ['changed', 'Changed'],
  ['knownIssues', 'Known issues']
];

export const VersionNotes = ({ notes }) => (
  <div>
    <h2>
      { notes.released || 'Upcoming' }
      { ' - ' }
      { notes.version }
    </h2>
    {
      notes.description && <p>{ notes.description }</p>
    }
    {
      SECTIONS.map(
        ([key, title]) => notes[key] && notes[key].length > 0 && (
          <Section
            content={notes[key]}
            key={key}
            title={title}
          />
        )
      )
    }
  </div>
);
