import React from 'react';

import { Form } from 'reactstrap';
import Tabs from 'components/Tabs';
import { withFormik } from 'formik';
import CCLIPage from './form/CCLIPage';
import OutputPage from './form/OutputPage';

const pages = [
  {
    name: 'Output settings',
    component: OutputPage
  },
  {
    name: 'CCLI licence',
    component: CCLIPage
  }
];

const SettingsForm = (props) => (
  <Form
    id='settingsForm'
    onSubmit={props.handleSubmit}>
    <Tabs
      pages={pages}
      {...props}
    />
  </Form>
);

export default withFormik({
  enableReinitialize: true,
  handleSubmit: (t, { props: { onSubmit } }) => onSubmit(t),
  mapPropsToValues: (props) => props.settings,
})(SettingsForm);
