import { createSelector } from 'reselect';

export const themeState = (state) => state.theme;

export const currentThemeSelector = createSelector(
  themeState,
  (state) => state.themes[state.currentTheme]
);

export const themesSelector = createSelector(
  themeState,
  (state) => state.themes || {}
);

export const themeLoadStateSelector = createSelector(
  themeState,
  (state) => state.loaded || false
);
