import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import modal, { connectedModal, CloseModalButton } from 'modal';
import Library from './Library';

const ConnectedModal = connectedModal('library');

const LibraryModal = ({ payload }) => (
  <ConnectedModal className='full-size'>
    <ModalHeader>Library</ModalHeader>
    <ModalBody className='d-flex'>
      <Library searchMode={payload && payload.mode === 'search'} />
    </ModalBody>
    <ModalFooter>
      <CloseModalButton>Close</CloseModalButton>
    </ModalFooter>
  </ConnectedModal>
);

export default connect(
  createStructuredSelector({
    payload: modal.selectors.modalPayloadSelector
  })
)(LibraryModal);
