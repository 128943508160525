import React from 'react';

import { Button, Container, Row, Col, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { connectedModal } from 'modal';
import RegistrationForm from './RegistrationForm';

const ConnectedModal = connectedModal('register');

export default ({ showModal }) => (
  <ConnectedModal>
    <ModalHeader>Sign up</ModalHeader>
    <ModalBody>
      <Container fluid>
        <Row>
          <Col sm={8}>
            <RegistrationForm />
          </Col>
          <Col
            className='border-left'
            sm={4}
          >
            <p>
              Sign up for a free JustWords account now - no credit card required.
            </p>
            <p>
              Already have an account?{' '}
              <Button
                color='link'
                onClick={() => showModal('login', undefined, true)}
              >
                Sign in
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </ModalBody>
    <ModalFooter>
      <Button
        color='secondary'
        onClick={() => showModal(null)}
      >
        Cancel
      </Button>
    </ModalFooter>
  </ConnectedModal>
);
