import React from 'react';
import PropTypes from 'prop-types';

import RenderedBlock from './RenderedBlock';

const RenderedResourceContent = ({ addressSetByClick, editable, liveBlockAddress, onBlockClick, onChange, onGroupClick, onLineClick, resource }) => {
  const resourceCounts = {
    verse: 0,
    bridge: 0,
    chorus: 0,
    section: 0
  };

  const getDescriptor = (type) => {
    if (resourceCounts[type] === undefined) {
      return undefined;
    }
    return `${type.charAt(0).toUpperCase() + type.slice(1)} ${++resourceCounts[type]}`;
  };

  return (
    <div
      className="resource-content"
      contentEditable={editable}
      onInput={onChange}>
      {
        (resource.content || []).map(
          (block, idx) => {
            const live = liveBlockAddress && liveBlockAddress[0] === idx;
            return (
              <RenderedBlock
                addressSetByClick={addressSetByClick}
                block={block}
                blockIndex={idx}
                descriptor={ getDescriptor(block.type) }
                key={idx}
                live={live}
                liveGroupAddress={live ? liveBlockAddress[1] : null}
                onBlockClick={onBlockClick}
                onGroupClick={onGroupClick}
                onLineClick={onLineClick}
              />
            );
          }
        )
      }
    </div>
  );
};

// Return true from any of the onXClick handlers to stop propagation
// of the event to higher-up nodes.
// onLineClick is called first, then onGroupClick, then onBlockClick.
RenderedResourceContent.propTypes = {
  editable: PropTypes.bool,
  onBlockClick: PropTypes.func,
  onGroupClick: PropTypes.func,
  onLineClick: PropTypes.func,
  resource: PropTypes.object
};

export default RenderedResourceContent;
