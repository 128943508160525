import { createSelector } from "reselect";
import resource from 'resource';
import { cacheSelector, processingQueueSelector } from 'renderCache/selectors';

const uiState = (state) => state.ui;

export const liveSelector = createSelector(
  uiState,
  (ui) => ui.live
);

export const blankedSelector = createSelector(
  uiState,
  (ui) => ui.blanked
);

export const modalSelector = createSelector(
  uiState,
  (ui) => ui.modal
);

export const editedResourceSelector = createSelector(
  uiState,
  resource.selectors.resourceState,
  (ui, resources) => ui.editedResource ? resources[ui.editedResource] : null
);

export const previewResourceSelector = createSelector(
  uiState,
  cacheSelector,
  (ui, cache) => ui.previewResource ? cache[ui.previewResource] : null
);

export const previewResourceProcessingSelector = createSelector(
  uiState,
  processingQueueSelector,
  (ui, queue) => queue.find(q => q.id === ui.previewResource)
);

export const liveResourceSelector = createSelector(
  uiState,
  cacheSelector,
  (ui, cache) => ui.liveResource ? cache[ui.liveResource] : null
);

export const liveResourceProcessingSelector = createSelector(
  uiState,
  processingQueueSelector,
  (ui, queue) => queue.find(q => q.id === ui.liveResource)
);
