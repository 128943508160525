import * as React from 'react';

import { FormGroup, Label } from 'reactstrap';

import FormField from 'components/FormField';

export const ScripturePage = () => {
  return (
    <FormGroup>
      <FormGroup check>
        <Label>
          <FormField
            autoSave
            name='scripture.showAnnotations'
            type="checkbox"
          /> Show verse annotations
        </Label>
      </FormGroup>
    </FormGroup>
  );
};
