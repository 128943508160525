import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import FormField from 'components/FormField';
import { LICENCE_TYPES } from '../../constants';

export default () => (
  <Fragment>
    <Label for='meta.licence'>Licence</Label>
    <FormField
      name='meta.licence'
      type='select'>
      {
        Object.keys(LICENCE_TYPES).map(
          (k, idx) => <option
            key={idx}
            value={k}>{LICENCE_TYPES[k]}</option>
        )
      }
    </FormField>
    <Label for='meta.author'>Author(s)</Label>
    <FormField name='meta.author' />
    <Label for='meta.copyright'>Copyright &copy;</Label>
    <FormField name='meta.copyright' />
  </Fragment>
);
