import React from 'react';
import { clickHandler } from '../functions';

const LINE_TYPES = [
  'normal',
  'minor',
  'scripture'
];

const RenderedLine = ({ line, onClick }) => {
  const [text, type, annotation] = line;
  return (
    <div
      className={`line ${LINE_TYPES[type]}`}
      data-type={type}
      onClick={clickHandler(onClick, line)}
    >
      <sup>{annotation}</sup>
      {
        text
      }
    </div>
  );
};

export default RenderedLine;
