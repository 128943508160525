import * as constants from './constants';
import { resolved, rejected } from '../api';
import auth from 'auth';


const INITIAL_STATE = {
  data: null,
  error: null
};

export default (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case resolved(constants.GET_SUBSCRIPTION):
      return {
        data: action.payload.data,
        error: null
      };

    case rejected(constants.GET_SUBSCRIPTION):
      if (action.error.message === 'Request failed with status code 404') {
        return state;
      }
      return {
        data: null,
        error: action.error.message
      };

    case auth.constants.LOG_OUT:
      return INITIAL_STATE;

    case rejected(constants.SUBSCRIBE):
      return {
        data: state.data,
        error: action.error
      };

    default:
      return state;
  }
};
