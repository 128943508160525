import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { getDotpath } from 'utils/dotpath';

export const useFormValue = (name, defaultValue=undefined) => {
  const { values } = useFormikContext();
  return getDotpath(values, name, defaultValue);
};

export const useBroadcastChannel = (name) => {

  const bc = useRef(new BroadcastChannel(name));

  useEffect(
    () => {
      return () => {
        if (bc.current) {
          bc.current.close(); // eslint-disable-line react-hooks/exhaustive-deps
        }
      };
    },
    [name]
  );

  return bc.current;
};
