import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPalette
} from '@fortawesome/free-solid-svg-icons';

import theme from 'theme';
import playlist from 'playlist';

const ThemeMenuItem = ({ isSelected, name, onClick }) => (
  <DropdownItem
    onClick={onClick}
  >
    { isSelected && <FontAwesomeIcon icon={faCheck} /> }
    { ' ' }
    { name }
  </DropdownItem>
);

const PlaylistThemeSelector = ({ index, currentThemeID, setItemTheme, themes }) => {

  const [isOpen, setOpen] = React.useState(false);
  const toggle = (e) => {
    setOpen(!isOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle caret>
        <FontAwesomeIcon icon={faPalette} />
      </DropdownToggle>

      <DropdownMenu
        positionFixed
        right
      >
        <DropdownItem header>
          Set theme for this item:
        </DropdownItem>
        <ThemeMenuItem
          isSelected={!currentThemeID}
          name='Use global theme'
          onClick={() => setItemTheme(index, null)}
        />

        {
          Object.values(themes).sort(theme.functions.sortByName).map(
            (theme) => (
              <ThemeMenuItem
                isSelected={theme.id === currentThemeID}
                key={theme.id}
                name={theme.name}
                onClick={() => setItemTheme(index, theme.id)}
              />
            )
          )
        }

      </DropdownMenu>

    </ButtonDropdown>
  );
};

export default connect(
  createStructuredSelector({
    themes: theme.selectors.themesSelector
  }),
  {
    setItemTheme: playlist.actions.setItemTheme
  }
)(PlaylistThemeSelector);
