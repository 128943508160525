import uuid from 'uuid/v4';

import * as constants from './constants';

export const newTheme = (name='Default', state={}) => {
  const id = generate_id(state);
  return {
    ...constants.DEFAULT_THEME,
    id,
    name
  };
};

export const generate_id = (state={}) => {
  let id = uuid();
  while (state[id]) {
    id = uuid();
  }
  return id;
};

export const parseCSSValueAndUnit = (raw) => {
  if (raw == null || raw === '0' || raw === 0) {
    return {
      value: 0,
      unit: 'px'
    };
  }
  const match = raw.match(constants.CSS_VALUE_AND_UNIT_REGEX);
  if (match) {
    return {
      value: match.groups['value'],
      unit: match.groups['unit']
    };
  }
  return {
    value: 0,
    unit: 'px'
  };
};

export const sortByName = function(a, b) {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};
