import React from 'react';
import styled from 'styled-components';

import { themeValue } from '../utils';

const FadeToBlack = ({ active, className }) => (
  <div
    className={className}
    style={{ opacity: active ? 1 : 0 }}
  ></div>
);

export default styled(FadeToBlack)`

background-color: black;
cursor: none;
transition: opacity ${themeValue('fadeToBlackTime', 1)}s;
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
`;
