import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isLoggedIn } from '../selectors';

export const requiresLogout = redirectLocation => WrappedComponent => props => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();

  if (loggedIn) {
    dispatch({ type: redirectLocation });
    return null;
  }
  else {
    return (
      <WrappedComponent {...props} />
    );
  }

};
