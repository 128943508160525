import * as React from 'react';
import { useDispatch } from 'react-redux';

import modal from 'modal';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export const EditResourceButton = ({ resource, ...props }) => {

  const dispatch = useDispatch();

  const editResource = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(
        modal.actions.showModal(
          'resource/edit',
          resource
        )
      );
    },
    [dispatch, resource]
  );

  return (
    <Button
      title='Edit'
      {...props}
      onClick={editResource}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={faPencilAlt}
      />
    </Button>
  );
};
