import { combineReducers } from 'redux';
import * as constants from './constants';
import { resolved, rejected } from 'chrome';
import { extractVerseData } from './youversion';

require('polyfill-object.fromentries');

const INITIAL_VERSION_STATE = {};

const versions = (state=INITIAL_VERSION_STATE, action) => {

  if (action.type === resolved(constants.LOAD_VERSIONS)) {
    const newState = { ...state };

    action.payload.versions.forEach(
      (v) => {
        newState[v.id] = {
          id: v.id,
          name: v.local_title,
          abbreviation: v.local_abbreviation,
          language: v.language.language_tag
        };
        if (state[v.id] && state[v.id].books) {
          newState[v.id].books = [...state[v.id].books];
        }
      }
    );

    return newState;
  }
  else if (action.type === resolved(constants.LOAD_VERSION)) {
    return {
      ...state,
      [action.payload.id]: {
        id: action.payload.id,
        name: action.payload.local_title,
        abbreviation: action.payload.local_abbreviation,
        language: action.payload.language.language_tag,
        books: action.payload.books
      }
    };
  }

  return state;
};

const INITIAL_CONTENT_STATE = {};

const storeChapter = (state, versionID, usfm, content) => ({
  ...state,
  [versionID]: {
    ...state[versionID],
    [usfm]: {
      ...content,
      _loaded: true,
      _loading: false
    }
  }
});

const content = (state=INITIAL_CONTENT_STATE, action) => {
  if (action.type === resolved(constants.LOAD_CHAPTER)) {
    const versionID = action.payload.reference.version_id;

    return storeChapter(
      state,
      versionID,
      action.payload.reference.usfm[0],
      extractVerseData(action.payload.content)
    );
  }
  else if (action.type === rejected(constants.LOAD_CHAPTER)) {
    return {
      ...state,
      [action.versionID] : {
        ...state[action.versionID],
        [action.chapterID]: {
          _loaded: false,
          _loading: false
        }
      }
    };
  }
  else if (action.type === constants.LOAD_CHAPTER) {
    const versionID = action.versionID;
    const usfm = action.chapterID;

    return {
      ...state,
      [versionID] : {
        ...state[versionID],
        [usfm]: {
          _loaded: false,
          _loading: true
        }
      }
    };
  }
  else if (action.type === '@@INIT') {

    const filterUnloadedChapters = (chapterMap) => {
      // If browser is killed (or crashes) while content is being fetched we
      // can otherwise end up in a state where chapters are marked as "_loading"
      // but will never load, leading to headaches later
      return Object.fromEntries(
        Object.entries(chapterMap).filter(
          ([chapter, content]) => content && content._loaded
        )
      );
    };

    const newState = Object.fromEntries(
      Object.entries(state).map(
        ([version, contentByChapter]) => ([version, filterUnloadedChapters(contentByChapter)])
      )
    );
    return newState;
  }
  return state;
};

const loading = (state=0, action) => {
  switch (action.type) {

    case '@@INIT':
      return 0;

    case constants.LOAD_VERSIONS:
    case constants.LOAD_VERSION:
    case constants.LOAD_CHAPTER:
      return state + 1;

    case resolved(constants.LOAD_VERSIONS):
    case rejected(constants.LOAD_VERSIONS):
    case resolved(constants.LOAD_VERSION):
    case rejected(constants.LOAD_VERSION):
    case resolved(constants.LOAD_CHAPTER):
    case rejected(constants.LOAD_CHAPTER):
      return state - 1;

    default:
      return state;
  }
};

const selectedVersion = (state=null, action) => {
  if (action.type === constants.SELECT_VERSION) {
    return action.versionID;
  }
  return state;
};

export default combineReducers({
  content,
  loading,
  selectedVersion,
  versions
});
