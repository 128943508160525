export const ADD_RESOURCE = 'playlist/ADD_RESOURCE';
export const ADD_SCRIPTURE = 'playlist/ADD_SCRIPTURE';
export const CLEAR = 'playlist/CLEAR';
export const MOVE_ITEM = 'playlist/MOVE';
export const REMOVE_ITEM = 'playlist/REMOVE';
export const SET_ITEM_THEME = 'playlist/SET_ITEM_THEME';
export const LOAD_PLAYLIST = 'playlist/LOAD';

export const Types = {
  RESOURCE: 'resource',
  SCRIPTURE: 'scripture'
};
