import React from 'react';
import { extractVerses } from '../functions';

const generatePreviewText = (version, content, startRef, endRef) => {
  const verses = extractVerses(version, content, startRef, endRef);
  if (!verses) {
    return '';
  }
  return verses.map(
    (v, idx) => (
      <span key={idx}>
        <sup>{v[0]}</sup>
        {v[1]}
      </span>
    )
  );
};

export default class BiblePreview extends React.Component {

  static getDerivedStateFromProps(props) {
    const { version, content, startRef, endRef } = props;

    return {
      text: generatePreviewText(version, content, startRef, endRef)
    };

  }

  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  render() {
    return (
      <div>
        <h4>Preview</h4>
        {this.state.text}
      </div>
    );
  }

}
