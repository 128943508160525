import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import classNames from 'classnames';

const Unlimited = () => (
  <p>You can create <b>unlimited</b> items in your library.</p>
);

const getBackgroundColour = (percentageRemain) => {
  if (percentageRemain < 0.25) {
    return 'red';
  }
  else if (percentageRemain < 0.5) {
    return '#cece34';
  }
  return 'green';
};

const Limited = ({ count, limit }) => {
  const percentageRemain = 1 - (count / limit);
  return (
    <React.Fragment>
      <div className='limit-container'>
        <Doughnut
          data={{
            labels: ['Used', 'Remaining'],
            datasets: [{
              label: 'Library usage',
              data: [count, Math.max(0, limit - count)],
              backgroundColor: [getBackgroundColour(percentageRemain)]
            }]
          }}
          options={{
            circumference: Math.PI,
            cutoutPercentage: 80,
            rotation: Math.PI,
            legend: {
              display: false
            },
            maintainAspectRatio: false
          }}
        />
        <div
          className={
            classNames(
              'limit-label',
              {
                'limit-label-full': percentageRemain < 0.25,
                'limit-label-mid': percentageRemain >= 0.25 && percentageRemain < 0.5,
                'limit-label-ok': percentageRemain >= 0.5
              }
            )
          }
        >
          {Math.max(0, limit - count)} left
        </div>
      </div>
      <p>
        You have <b>{count}</b> out of {limit} items in your library.
        {
        count >= limit ?
          ' Upgrade your account to store unlimited items!'
          : null
      }
      </p>
    </React.Fragment>
  );
};

export default ({ count, limit }) => {
  if (limit === -1) {
    return <Unlimited />;
  }
  else {
    return <Limited
      count={count}
      limit={limit} />;
  }
};
