import React from 'react';
import PageWrapper from './PageWrapper';

/* global chrome */

export const BROWSER_CHECK_RESULTS = {
  MOBILE: 1,
  NOT_CHROME: 2,
  OK: 99
};

export const checkBrowser = () => {
  if (
    typeof(window) === 'undefined' ||
    typeof(chrome) === 'undefined' ||
    !!chrome.webstore
  ) {
    return BROWSER_CHECK_RESULTS.NOT_CHROME;
  }

  if (
    typeof(navigator) !== 'undefined' &&
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  ) {
    return BROWSER_CHECK_RESULTS.MOBILE;
  }

  return BROWSER_CHECK_RESULTS.OK;

};

const NotOKMessage = ({ reason }) => (
  <PageWrapper>
    <h2>Browser not supported</h2>
    <p>
      We're sorry, the browser you're using is not supported by JustWords.
    </p>
    {
      reason === BROWSER_CHECK_RESULTS.MOBILE ?
        <p>
          You need to use a desktop browser to run JustWords. Please try again
          using Chrome on your desktop computer.
        </p>
      : null
    }
    {
      reason === BROWSER_CHECK_RESULTS.NOT_CHROME ?
        <p>
          JustWords currently requires {' '}
          <a href="https://www.google.com/chrome/">Google Chrome</a>. We're
          working on adding support for Mozilla Firefox too.
        </p>
      : null
    }
  </PageWrapper>
);

const BrowserGate = ({ children }) => {
  const checkResult = checkBrowser();

  if (checkResult === BROWSER_CHECK_RESULTS.OK) {
    return (
      <React.Fragment>{ children }</React.Fragment>
    );
  }

  return <NotOKMessage reason={checkResult} />;
};

export default BrowserGate;

export const withBrowserGate = (WrappedComponent) => (props) => (
  <BrowserGate>
    <WrappedComponent {...props} />
  </BrowserGate>
);
