import React from 'react';
import { connect } from 'react-redux';

import { register } from '../actions';

import { Button, Form, FormGroup, Label } from 'reactstrap';
import { withFormik } from 'formik';
import FormField from 'components/FormField';

import { RegistrationFormSchema } from '../schemas';

const RegistrationForm = ({ authErrors, handleSubmit, isSubmitting, isValid, submitCount }) => (
  <Form onSubmit={handleSubmit}>
    {
      submitCount > 0 && authErrors && authErrors['non_field_errors'] ?
        <p className='text-danger'>{authErrors['non_field_errors']}</p>
        : null
    }
    <FormGroup>
      <Label for='email'>Email address</Label>
      <FormField name='email' />
    </FormGroup>
    <FormGroup>
      <Label for='password1'>Password</Label>
      <FormField
        name='password1'
        type='password' />
    </FormGroup>
    <FormGroup>
      <Label for='password'>Password again</Label>
      <FormField
        name='password2'
        type='password' />
    </FormGroup>
    <Button
      color='primary'
      disabled={isSubmitting || !isValid}
      type='submit'
    >
      Sign up
    </Button>
  </Form>
);

export default connect(
  (state) => (
    { authErrors: state.auth.errors }
  ),
  {
    register
  }
)(
  withFormik(
    {
      enableReinitialize: true,
      handleSubmit: (t, { props: { register }, setSubmitting }) => {
        register(t.email, t.password1, t.password2).then(
          () => setSubmitting(false)
        );
      },
      mapPropsToErrors: ({ authErrors }) => authErrors,
      mapPropsToValues: () => ({ email: '', password1: '', password2: '' }),
      validationSchema: RegistrationFormSchema
    }
  )(
    RegistrationForm
  )
);
