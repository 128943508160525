import * as actions from './actions';
import reducer from './reducer';
import { fontsSelector } from './selectors';
export { default as FontSelectorField } from './components/FontSelectorField';

export default {
  actions,
  reducer,
  selector: fontsSelector
};
