import { SHOW_MODAL } from './constants';

const INITIAL_STATE = [];

export default (state=INITIAL_STATE, action) => {
  if (action.type === SHOW_MODAL) {

    if (action.modal === null) {
      return state.slice(1);
    }

    if (action.replace) {
      return [
        {
          modal: action.modal,
          payload: action.payload
        },
        ...state.slice(1)
      ];
    }

    return [
      {
        modal: action.modal,
        payload: action.payload
      },
      ...state
    ];
  }
  return state;
};
