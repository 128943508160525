import React, { useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

import * as constants from '../constants';
import { useBroadcastChannel } from '../../../hooks';

const HiddenIframe = styled.iframe`
  z-index: -1000;
  position: absolute;
  bottom: 0;
  border: none;
  width: ${ props => props.canvasSize.width || 640 }px;
  height: ${ props => props.canvasSize.height || 480 }px;
`;

const CacheRenderer = ({ canvasSize }) => {
  const iframeRef = useRef();
  const bc = useBroadcastChannel('justwords');

  React.useEffect(
    () => {
      bc.onmessage = ({ data }) => {
        if (data.type === constants.GENERATE_DISPLAY_MODEL && iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(data, '*');
        }
      };
    },
    [bc]
  );

  const cacheResource = useCallback(
    (event) => {
      if (
        bc &&
        event.data &&
        event.data.type === constants.CACHE_DISPLAY_MODEL &&
        event.data.resource
      ) {
        bc.postMessage(
          event.data,
          '*'
        );
      }
    },
    [bc]
  );

  useEffect(
    () => {
      if (typeof(window) !== 'undefined') {
        window.addEventListener(
          'message',
          cacheResource
        );

        return () => {
          window.removeEventListener('message', cacheResource);
        };
      }
    },
    [cacheResource]
  );

  return (
    <HiddenIframe
      canvasSize={canvasSize}
      id='background-renderer'
      ref={iframeRef}
      sandbox='allow-scripts'
      src={`${window.location.origin}/backgroundRenderer.html`}
      title='Background renderer'
    >
    </HiddenIframe>
  );
};

export default CacheRenderer;
