export const SELECT_THEME = 'theme/SELECT';
export const DELETE_THEME = 'theme/DELETE';
export const PUT_THEME = 'theme/PUT';
export const LOAD = 'theme/LOAD';
export const SAVE = 'theme/SAVE';

export const LOCAL_STORAGE_KEY = 'justwords_themes';

export const CSS_VALUE_AND_UNIT_REGEX = /^0$|^(?<value>[0-9]+(\.[0-9]+)?)(?<unit>[a-zA-Z]+|%)$/;

export const DEFAULT_OUTLINE = {
  enabled: false,
  color: '#000000',
  width: '5px'
};

export const DEFAULT_LINE = {
  align: 'center',
  bold: false,
  color: '#FFFFFF',
  italic: false,
  outline: DEFAULT_OUTLINE
};

export const DEFAULT_THEME = {
  background: '#000000',
  verticalAlignment: 'middle',
  fadeToBlackTime: 1,
  font: {
    face: 'Arial',
    size: 40,
    normal: DEFAULT_LINE,
    minor: {
      ...DEFAULT_LINE,
      color: '#FFFF00'
    }
  },
  margin: {
    top: '1rem',
    bottom: '1rem',
    left: '1rem',
    right: '1rem'
  },
  meta: {
    font: {
      face: 'Arial',
      size: 18,
      ...DEFAULT_LINE,
      align: 'right'
    },
    duration: 10
  },
  textBox: {
    enabled: false,
    backgroundColor: '',
    borderColor: '#FFFFFF',
    borderWidth: '3px',
    padding: '1rem'
  }
};
