import React from 'react';

import { FormGroup, Label } from 'reactstrap';
import CSSValueUnitField from '../CSSValueUnitField';

export default () => (
  <FormGroup className='margins'>
    <FormGroup className='top'>
      <Label for="margin.top">Top</Label>
      <CSSValueUnitField
        autoSave
        name='margin.top'
        unitValues={{
          '%': 'vh',
          'px': 'px',
          'rem': 'rem'
        }}
      />
    </FormGroup>
    <FormGroup className='left'>
      <Label for="margin.left">Left</Label>
      <CSSValueUnitField
        autoSave
        name='margin.left'
        unitValues={{
          '%': 'vw',
          'px': 'px',
          'rem': 'rem'
        }}
      />
    </FormGroup>
    <FormGroup className='bottom'>
      <Label for="margin.bottom">Bottom</Label>
      <CSSValueUnitField
        autoSave
        name='margin.bottom'
        unitValues={{
          '%': 'vh',
          'px': 'px',
          'rem': 'rem'
        }}
      />
    </FormGroup>
    <FormGroup className='right'>
      <Label for="margin.right">Right</Label>
      <CSSValueUnitField
        autoSave
        name='margin.right'
        unitValues={{
          '%': 'vw',
          'px': 'px',
          'rem': 'rem'
        }}
      />
    </FormGroup>
  </FormGroup>
);
