import * as constants from './constants';
import { arrayMove } from 'react-sortable-hoc';

const INITIAL_STATE = {
  current: []
};

export default (state = INITIAL_STATE, action) => {
  const idx = action.index === null ? state.current.length : action.index;

  switch (action.type) {
    case constants.ADD_RESOURCE:
      const plItem = {
        type: constants.Types.RESOURCE,
        title: action.resource.title,
        id: action.resource.id
      };

     return addItem(state, plItem, idx);

    case constants.ADD_SCRIPTURE:
      const scrItem = {
        ...action.scripture,
        type: constants.Types.SCRIPTURE,
      };

     return addItem(state, scrItem, state.current.length);

    case constants.CLEAR:
      return {
        ...state,
        current: []
      };

    case constants.MOVE_ITEM:
      if (0 <= action.fromIdx && action.fromIdx < state.current.length) {
        return {
          ...state,
          current: arrayMove(state.current, action.fromIdx, Math.min(action.toIdx, state.current.length - 1))
        };
      }
      return state;

    case constants.REMOVE_ITEM:
      const newCurrent = [...state.current];
      newCurrent.splice(idx, 1);
      return {
        ...state,
        current: newCurrent
      };

    case constants.SET_ITEM_THEME:
      const newCurrent2 = [...state.current];

      const { themeID } = action;

      newCurrent2[idx] = {
        ...newCurrent2[idx],
        meta: {
          ...newCurrent2[idx].meta,
          themeID
        }
      };

      return {
        ...state,
        current: newCurrent2
      };

    case constants.LOAD_PLAYLIST:
      return {
        ...state,
        current: action.playlist
      };

    default:
      return state;
  }
};

const addItem = (state, item, idx) => {
  const newCurrent = [...state.current];
  newCurrent.splice(idx, 0, item);
      return {
        ...state,
        current: newCurrent
      };
};
