import * as yup from 'yup';
import { CSS_VALUE_AND_UNIT_REGEX } from './constants';

const CSSValueWithUnit = yup.string().matches(
  CSS_VALUE_AND_UNIT_REGEX,
  'Must be a valid CSS value (with unit), e.g. "2rem" or "50%"'
);
const hexColour = yup.string().matches(/^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{2})?$/, 'Must be a hexadecimal colour value e.g. "#FFC4C4"');

const Margin = yup.object({
  top: CSSValueWithUnit.required(),
  bottom: CSSValueWithUnit.required(),
  left: CSSValueWithUnit.required(),
  right: CSSValueWithUnit.required()
});

const Outline = yup.object({
  enabled: yup.bool(),
  color: hexColour,
  width: CSSValueWithUnit
});

const Shadow = yup.object({
  enabled: yup.bool().default(false),
  color: hexColour.default('#000000'),
  blur: CSSValueWithUnit.default(0),
  xOffset: CSSValueWithUnit.default(0),
  yOffset: CSSValueWithUnit.default(0),
});

const LineStyle = yup.object({
  align: yup.string().oneOf(['left', 'center', 'right']).required(),
  color: hexColour.required(),
  italic: yup.bool().required(),
  bold: yup.bool().required(),
  outline: Outline,
  shadow: Shadow
});

const Font = yup.object({
  face: yup.string().ensure().trim().required(),
  size: yup.number().integer().min(1).required(),
  normal: LineStyle.required(),
  minor: LineStyle.required(),
  lineHeight: yup.number().default(1.2),
  letterSpacing: yup.number().default(0)
});

const Meta = yup.object({
  duration: yup.number().min(1).required(),
  font: yup.object({
    size: yup.number().integer().min(1).required(),
  }).concat(LineStyle).required()
});

const TextBox = yup.object({
  enabled: yup.bool().required(),
  backgroundColor: hexColour,
  borderColor: hexColour.required(),
  borderWidth: CSSValueWithUnit.required(),
  padding: CSSValueWithUnit.required()
});

const Scripture = yup.object({
  showAnnotations: yup.bool().default(true)
});

export const Theme = yup.object({
  name: yup.string().ensure().trim().required(),
  id: yup.string(),
  margin: Margin.required(),
  meta: Meta.required(),
  font: Font.required(),
  background: hexColour.required(),
  textBox: TextBox,
  scripture: Scripture.required().default(() => Scripture.default()),
  fadeToBlackTime: yup.number().default(1).min(0).required(),
  verticalAlignment: yup.string().oneOf(['top', 'middle', 'bottom']).required()
}).noUnknown();
