import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import withGracefulUnmount from '../../../components/withGracefulUnmount';

import * as constants from '../constants';
import { closeAllOutputWindows, openOutputWindow, windowHasClosed } from '../actions';
import { fadeToBlackSelector, liveGroupSelector, liveMetaSelector, liveThemeSelector, liveResourceIDSelector } from '../selectors';
import theme from 'theme';
import { useBroadcastChannel } from '../../../hooks';

const themeSelector = createSelector(
  theme.selectors.currentThemeSelector,
  liveThemeSelector,
  (globalTheme, liveTheme) => (liveTheme || globalTheme)
);

const OutputWindows = ({ blanked }) => {
  const { display, outputs } = useSelector(state => state.settings);
  const dispatch = useDispatch();

  const liveGroup = useSelector(liveGroupSelector);
  const liveResourceID = useSelector(liveResourceIDSelector);
  const meta = useSelector(liveMetaSelector);
  const fadeToBlack = useSelector(fadeToBlackSelector);
  const theme = useSelector(themeSelector);

  const renderableState = {
    blanked,
    id: liveResourceID,
    liveGroup,
    meta,
    fadeToBlack,
    theme
  };

  const bc = useBroadcastChannel('justwords');

  const broadcastState = useCallback(
    () => {
      bc.postMessage(
        {
          type: constants.RENDERABLE_STATE_BROADCAST,
          state: renderableState
        },
        '*'
      );
    },
    [bc, renderableState]
  );

  useEffect(
    () => {
      bc.onmessage = (event) => {
        if (event.data.type === 'output/REQUEST_STATE') {
          broadcastState();
        }
        else if (event.data.type === constants.WINDOW_HAS_CLOSED) {
          dispatch(windowHasClosed(event.data.index));
        }
      };
    },
    [bc, broadcastState, dispatch]
  );

  useEffect(
    () => {
      broadcastState();
    },
    [broadcastState, renderableState]
  );

  useEffect(
    () => {

      outputs.filter(o => o.type === 'window').forEach(
        (output, index) => {

          const settings = {
            ...output,
            display,
            index
          };
          if (!settings.width) {
            settings.width = display.width;
          }
          if (!settings.height) {
            settings.height = display.height;
          }

          dispatch(
            openOutputWindow(settings)
          );
        }
      );

      return () => {
        dispatch(
          closeAllOutputWindows()
        );
      };
    },
    [dispatch, display, outputs]
  );

  return null;
};

export default withGracefulUnmount(OutputWindows);
