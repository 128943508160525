import * as React from 'react';

import PageWrapper from "../components/PageWrapper";
import { AddResourceModal, EditResourceModal, ImportResourceModal } from 'resource';
import { Library as LibraryUI } from 'ui';
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import chrome from 'chrome';
import { StoreStateLoader } from '../components/StoreStateLoader';


const copyResourceToClipboard = (resource) => {
  const resourceText = resource.content.map(
    section => section.content.map(
      linegroup => linegroup.map(
        line => line[0]
      ).join('\n')
    ).join('\n')
  ).join('\n\n');

  navigator.clipboard.writeText(resourceText);
};

const CopyButton = ({ resource }) => {

  const buttonRef = React.useRef();
  const [isShowing, setShowing] = React.useState(false);

  return (
    <>
      <Button
        className='px-1'
        color='secondary'
        innerRef={buttonRef}
        onClick={
          (e) => {
            e.preventDefault();
            e.stopPropagation();
            copyResourceToClipboard(resource);
            setShowing(true);
            setTimeout(
              () => setShowing(false),
              2500
            );
          }
        }
        title="Copy text to clipboard"
      >
        <FontAwesomeIcon
          fixedWidth
          icon={faCopy} />
      </Button>
      {
        buttonRef.current && (
          <Tooltip
            fade
            isOpen={isShowing}
            target={buttonRef.current}
          >
            Copied!
          </Tooltip>
        )
      }
    </>
  );
};

export const Library = chrome.requiresPlugin()(
  () => (
    <StoreStateLoader>
      <PageWrapper cardClassName='d-flex flex-column'>
        <h1>Your library</h1>
        <LibraryUI
          extraButtons={CopyButton}
          showHelpText={false}
        />
        <AddResourceModal />
        <EditResourceModal />
        <ImportResourceModal />
      </PageWrapper>
    </StoreStateLoader>
  )
);
