import * as constants from './constants';

export const generateDisplayModel = (resource) => ({
  type: constants.GENERATE_DISPLAY_MODEL,
  resource
});

export const processDisplayModel = (resourceID) => ({
  type: constants.PROCESSING_DISPLAY_MODEL,
  resource: { id: resourceID }
});

export const cacheDisplayModel = (resource) => ({
  type: constants.CACHE_DISPLAY_MODEL,
  resource: resource
});

export const invalidateCache = () => ({
  type: constants.INVALIDATE_CACHE
});
