export const State = {
  OK: 1,
  SYNCING: 2,
  ERROR: 3
};

export const START_SYNC = 'sync/START';
export const FINISH_SYNC = 'sync/FINISH';

export const SYNC_FEATURE = 'SYNC';
