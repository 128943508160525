import React from 'react';
import PageWrapper from '../components/PageWrapper';

import { NavLink } from 'redux-first-router-link';
import { Button } from 'reactstrap';

import justwordsUiImage from 'images/JustWords_UI.png';

export default () => (
  <PageWrapper>
    <h1>
      Welcome to JustWords
    </h1>
    <h2>
      Simple lyric display software in your browser
    </h2>
    <p>
      <b>JustWords</b> is software that helps display song lyrics, liturgy and
      Bible passages on a second screen or projector. It's designed for
      churches, home groups, and houses of worship.
    </p>

    <div className='d-lg-flex'>

      <div className='ml-4 mr-lg-4'>
        <h2>
          Why JustWords?
        </h2>

        <p>
          Unlike most lyric projection software, JustWords is <b>laser-focused</b>{' '}
          on doing one job, and doing it well: <b>displaying words</b>. We've kept
          the interface simple so that it's easy to use, even for new users.
        </p>
        <p>
          We also understand that songs don't fit well into the
          "Powerpoint-on-steroids" model used by other software. Here, songs are
          stored as <b>verses, choruses, lines</b> and so on, <b>not slides</b>.
          JustWords works out how much can fit on the screen automatically - {' '}
          <b>no need to reformat all your songs when you change the font size!</b>
        </p>

        <div className='d-flex justify-content-around'>
          <NavLink to={{ type: 'location/FEATURES' }}>
            <Button
              color='primary'
              size='lg'
            >
              Show features
            </Button>
          </NavLink>
          <NavLink to={{ type: 'location/PRICING' }}>
            <Button
              color='primary'
              size='lg'
            >
              View pricing
            </Button>
          </NavLink>
        </div>

      </div>

      <img
        alt='A screenshot of the JustWords user interface'
        className='main-image'
        src={justwordsUiImage}
    />

    </div>

  </PageWrapper>
);
