import * as actions from './actions';
import * as constants from './constants';
import * as functions from './functions';
import reducer from './reducer';
import * as selectors from './selectors';

export default {
  actions,
  constants,
  functions,
  reducer,
  selectors
};
