import { saveAs } from 'file-saver';


const PLAYLIST_FILE_VERSION = 1;

export const generateSavedPlaylistFile = (playlist) => {

  const fileContents = JSON.stringify({
    playlist,
    version: PLAYLIST_FILE_VERSION
  });

  const fileBlob = new Blob(
    [fileContents],
    {
      type: 'application/json;charset=utf-8'
    }
  );

  saveAs(fileBlob, 'justwords-playlist.jwpl');
};

export const loadPlaylistFromFile = async (file) => {
  const textContent = await file.text();
  const playlist = JSON.parse(textContent);
  if (playlist.version !== PLAYLIST_FILE_VERSION) {
    throw new Error(`Unsupported playlist version ${playlist.version}`);
  }
  return playlist.playlist;
};
