import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import { createStructuredSelector } from 'reselect';

import ShortcutButton from 'components/ShortcutButton';

import * as selectors from './selectors';
import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducer';

export default {
  actions,
  constants,
  selectors,
  reducer
};

class FocusFixModal extends Modal {
  constructor(props) {
    super(props);
    this.setFocus = this.setFocus.bind(this);
  }

  onClosed(node) {
    const ui = document.querySelector('.main-window .sidebar');
    if (ui) {
      ui.click();
    }
    super.onClosed(node);
  }

  setFocus() {
    if (this._dialog && this._dialog.parentNode && typeof this._dialog.parentNode.focus === 'function') {
      this._dialog.parentNode.click(); // Needed to add this line FSR!
      this._dialog.parentNode.focus();
    }
  }
}

const SizedModal = ({ children, fullSize, ...others }) => {
  const className = fullSize ? 'full-size' : null;
  return (
    <FocusFixModal
      className={className}
      {...others}
    >
      { children }
    </FocusFixModal>
  );
};

export const connectedModal = (modalName) => connect(
  createStructuredSelector({
    isOpen: (state) => selectors.currentModalSelector(state) === modalName
  }),
  {
    toggle: () => actions.showModal(null)
  }
)(SizedModal);

export const CloseModalButton = connect(
  null,
  {
    closeModal: () => actions.showModal(null)
  }
)(
  ({ children, closeModal, ...rest }) => (
    <Button
      {...rest}
      onClick={closeModal}>
      { children }
    </Button>
  )
);

export const ShowModalButton = connect(
  createStructuredSelector({
    currentModal: selectors.currentModalSelector
  }),
  {
    showModal: actions.showModal
  }
)(
  ({ children, currentModal, modalName, payload, showModal, ...rest }) => (
    <ShortcutButton
      {...rest}
      active={currentModal === modalName}
      onClick={() => showModal(modalName, payload)}
    >
      { children }
    </ShortcutButton>
  )
);

ShowModalButton.propTypes = {
  modalName: PropTypes.string.isRequired,
  payload: PropTypes.any
};
