import * as constants from './constants';

export const addResource = (resource, index = null) => ({
  type: constants.ADD_RESOURCE,
  resource,
  index
});

export const addScripture = (scripture) => ({
  type: constants.ADD_SCRIPTURE,
  scripture
});

export const clear = () => ({
  type: constants.CLEAR
});

export const moveItem = (fromIdx, toIdx) => ({
  type: constants.MOVE_ITEM,
  fromIdx,
  toIdx
});

export const removeItem = (index) => ({
  type: constants.REMOVE_ITEM,
  index
});

export const setItemTheme = (index, themeID) => ({
  type: constants.SET_ITEM_THEME,
  index,
  themeID
});

export const loadPlaylist = (playlist) => ({
  type: constants.LOAD_PLAYLIST,
  playlist
});
