import * as constants from './constants';
import auth from 'auth';
import { rejected } from '../api';

export const getSubscription = () => dispatch => dispatch({
  type: constants.GET_SUBSCRIPTION,
  payload: {
    request: {
      url: 'subscription',
      method: 'get',
      auth: true
    }
  }
});

export const subscribe = (stripeToken) => dispatch => {
  return dispatch({
    type: constants.SUBSCRIBE,
    payload: {
      request: {
        url: 'subscription',
        method: 'put',
        auth: true,
        data: {
          stripe_token: stripeToken
        }
      }
    }
  }).then(
    result => {
      if (result.payload) {
        dispatch(auth.actions.updateToken(result.payload.data.access, result.payload.data.account));
      }
      else if (result.error) {
        dispatch({
          type: rejected(constants.SUBSCRIBE),
          error: result.error
        });
      }
    }
  );
};

export const cancelSubscription = () => dispatch => {
  dispatch({
    type: constants.CANCEL_SUBSCRIPTION,
    payload: {
      request: {
        url: 'subscription',
        method: 'delete',
        auth: true
      }
    }
  }).then(
    () => dispatch(getSubscription())
  );
};

export const changePayment = (stripeToken) => dispatch => {
  return dispatch({
    type: constants.CHANGE_PAYMENT,
    payload: {
      request: {
        url: 'subscription/change',
        method: 'post',
        auth: true,
        data: {
          stripe_token: stripeToken
        }
      }
    }
  }).then(
    (result) => {
      dispatch(getSubscription());
    }
  );
};
