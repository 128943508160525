import styled from 'styled-components';
import { themeValue } from '../utils';


const Inner = styled.div`
  background: ${themeValue('background')};
  transition: background ${themeValue('fadeToBlackTime', 1)}s;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: -100;

  width: ${props => props.canvasWidth}px;
  height: ${props => props.canvasHeight}px;

  * {
    visibility: ${ props => props.blanked ? 'hidden' : 'visible' };
  }
`;


export default Inner;
