import * as yup from 'yup';

const OutputType = yup.string().oneOf([
  'window'
]);

export const Output = yup.object({
  left: yup.number().required().default(0).min(0),
  top: yup.number().required().default(0).min(0),
  width: yup.mixed().oneOf([yup.number().min(640), yup.string().matches('')]).transform(v => v === '' ? null : v),
  height: yup.mixed().oneOf([yup.number().min(480), yup.string().matches('')]).transform(v => v === '' ? null : v),
  fullscreen: yup.bool().required().default(false),
  type: OutputType.required().default('window')
});
