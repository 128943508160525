import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import SubscribeButton from './SubscribeButton';
import SubscriptionBenefits from './SubscriptionBenefits';

export default () => (
  <Card>
    <CardHeader>
      Subscribe today...
    </CardHeader>
    <CardBody>
      <div className="d-flex justify-content-around align-items-center">
        <SubscriptionBenefits />
        <div className='d-flex flex-column align-items-center'>
          <h4>£5/month</h4>
          <SubscribeButton />
        </div>
      </div>
    </CardBody>
  </Card>
);
