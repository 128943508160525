import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => <FontAwesomeIcon
  icon={faSpinner}
  spin
  style={{ width: '1em' }}
  {...props} />;
