import React from 'react';

import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loadingSelector } from '../selectors';
import playlist from 'playlist';

import uuid from 'uuid/v4';

import LoadingSpinner from 'components/LoadingSpinner';
import BibleEditor from './BibleEditor';

import modal, { connectedModal, CloseModalButton } from 'modal';

const ConnectedModal = connectedModal('bible');

class BibleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      attribution: '',
      verses: [],
      title: ''
    };

    this.createScriptureResource = this.createScriptureResource.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setVerses = this.setVerses.bind(this);
    this.setAttribution = this.setAttribution.bind(this);
  }

  setVerses(verses) {
    this.setState({ verses });
  }

  setTitle(title) {
    this.setState({ title });
  }

  setAttribution(attribution) {
    this.setState({ attribution });
  }

  createScriptureResource() {
    const { addScripture, showModal } = this.props;
    const { attribution, title, verses } = this.state;
    const id = `SCR-${uuid()}`;

    const resource = {
      type: 'scripture',
      id,
      content: [{
        type: 'scripture',
        content: [
          verses.map(v => (
            [v[1], 2, v[0]]
          ))
        ]
      }],
      title,
      meta: {
        attribution: `${title} — ${attribution}`,
        sourceFromPlaylist: true,
        version: attribution
      }
    };

    addScripture(resource);
    showModal(null);
  }

  render() {
    const { loading } = this.props;

    return (
      <ConnectedModal
        centered
        fullSize
      >
        <div className='modal-header'>
          <h5 className='modal-title'>
            Bible
          </h5>
          {
            loading ?
              <LoadingSpinner />
            : null
          }
        </div>
        <ModalBody className='bible-editor'>
          <BibleEditor
            loading={loading}
            onSelectVerses={this.setVerses}
            setAttribution={this.setAttribution}
            setTitle={this.setTitle}
          />
        </ModalBody>
        <ModalFooter>
          <CloseModalButton color='secondary'>Close</CloseModalButton>
          <Button
            color='primary'
            disabled={!this.state.verses}
            onClick={this.createScriptureResource}
          >
            Add to playlist
          </Button>
        </ModalFooter>
      </ConnectedModal>
    );
  }
}

export default connect(
  createStructuredSelector({
    loading: loadingSelector
  }),
  {
    addScripture: playlist.actions.addScripture,
    showModal: modal.actions.showModal
  }
)(BibleModal);
