import React from 'react';
import styled, { withTheme } from 'styled-components';
import { getDotpath } from 'utils/dotpath';
import { themeValue, ifThemeValue } from '../utils';


const Inner = styled.div`
  color: ${themeValue('meta.font.color')};
  font-size: ${themeValue('meta.font.size')}pt;
  font-family: ${themeValue('font.face')};
  font-style: ${ifThemeValue('meta.font.italic', 'italic', 'normal')};
  font-weight: ${ifThemeValue('meta.font.bold', 'bold', 'normal') };
  text-align: ${themeValue('meta.font.align')};

  margin-top: 0.5rem;

  transition: opacity 0.5s linear;
  opacity: ${props => props.showing ? 1 : 0};

  p {
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }
`;

const licenceFor = (meta) => {
  switch (meta.licence) {
    case 'PUBLIC_DOMAIN':
      return 'Public domain';

    case 'CCLI':
      return `CCLI licence #${meta.ccli || 'not set'}`;

    default:
      return null;

  }
};

class MetaRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasShown: false, showing: true };
    this.fadeTimer = null;
    this.displayForAWhile = this.displayForAWhile.bind(this);
  }

  componentDidMount() {
    this.displayForAWhile();
  }

  componentDidUpdate(prevProps) {
    const hasNewResource = prevProps.id !== this.props.id;
    const { blanked, fadedOut } = this.props;

    if (hasNewResource) {
      this.setState({ hasShown: false });
      if (!blanked && !fadedOut) {
        this.displayForAWhile();
      }
    }
    else if (!this.state.hasShown) {
      if (prevProps.blanked !== blanked || prevProps.fadedOut !== fadedOut) {
        this.displayForAWhile();
      }
    }

  }

  displayForAWhile() {
    if (this.fadeTimer) {
      window.clearTimeout(this.fadeTimer);
    }
    this.setState(
      { hasShown: true, showing: true },
      () => {
        this.fadeTimer = window.setTimeout(
          () => this.setState({ showing: false }),
          getDotpath(this.props.theme, 'meta.duration') * 1000 || 5000
        );
      }
    );
  }

  componentWillUnmount() {
    if (this.fadeTimer) {
      window.clearTimeout(this.fadeTimer);
    }
  }

  render() {
    const { meta } = this.props;

    if (!meta) {
      return null;
    }

    const metaLines = [meta.author, meta.attribution];

    if (meta.copyright) {
      metaLines.push(`Copyright © ${meta.copyright}`);
    }

    metaLines.push(licenceFor(meta));


    return (
      <Inner
        id='meta'
        showing={this.state.showing}>
        {
          metaLines.map(
            (ml, idx) => <p key={idx}>{ml}</p>
          )
        }
      </Inner>
    );
  }
}

export default withTheme(MetaRenderer);
