import React from 'react';
import { withFormik } from 'formik';
import { Theme } from '../schemas';

import { Form, FormGroup, Label } from 'reactstrap';

import Tabs from 'components/Tabs';
import GeneralPage from './form/GeneralPage';
import FontPage from './form/FontPage';
import FormField from 'components/FormField';
import MetaPage from './form/MetaPage';
import Margins from './form/Margins';
import TextBox from './form/TextBox';
import { ScripturePage } from './form/ScripturePage';

const pages = [
  {
    name: 'General',
    component: GeneralPage
  },
  {
    name: 'Margins',
    component: Margins
  },
  {
    name: 'Font',
    component: FontPage
  },
  {
    name: 'Scripture',
    component: ScripturePage
  },
  {
    name: 'Outline box',
    component: TextBox
  },
  {
    name: 'Copyright',
    component: MetaPage
  }
];

const ThemeForm = (props) => (
  <Form
    id='themeForm'
    onSubmit={props.handleSubmit}>
    <FormGroup className='linear'>
      <Label for='name'>Theme name:</Label>
      <FormField
        autoSave
        name='name' />
    </FormGroup>
    <Tabs
      pages={pages}
      {...props}
    />
  </Form>
);

export default withFormik({
  enableReinitialize: true,
  handleSubmit: (t, { props: { onSubmit } }) => onSubmit(t),
  mapPropsToValues: (props) => Theme.cast(props.theme),
  validationSchema: Theme
})(ThemeForm);
