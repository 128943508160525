import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { changePassword } from '../actions';
import { extractErrorText } from '../functions';
import { accountSelector } from '../selectors';


export const ChangePasswordForm = () => {

  const account = useSelector(accountSelector);

  const [pwd1, setPwd1] = React.useState('');
  const [pwd2, setPwd2] = React.useState('');

  const [isSubmitting, setSubmitting] = React.useState(false);

  const [errorText, setErrorText] = React.useState(null);

  const [wasSuccessful, setWasSuccessful] = React.useState(false);

  const dispatch = useDispatch();

  const canSubmit = (!!pwd1 && !!pwd2 && pwd1 === pwd2 && !isSubmitting);

  const doSubmit = React.useCallback(
    () => {
      setSubmitting(true);
      setWasSuccessful(false);
      setErrorText(null);
      dispatch(
        changePassword(pwd1, pwd2)
      ).then(
        (r) => {
          setSubmitting(false);

          if (r.error) {
            setErrorText(extractErrorText(r.error.response.data));
          }
          else {
            setWasSuccessful(true);
            setPwd1('');
            setPwd2('');
          }

        }
      );
    },
    [dispatch, pwd1, pwd2]
  );

  return (
    <Form
      className='mt-4'
      onSubmit={doSubmit}
    >
      {
        wasSuccessful && (
          <Alert color='success'>
            Your password was changed successfully.
          </Alert>
        )
      }
      <FormGroup>
        <input
          autoComplete='username'
          hidden
          name='username'
          readOnly
          type="text"
          value={account.email}
        />
        <Label for='pwd1'>Enter your new password:</Label>
        <Input
          autoComplete='new-password'
          id='pwd1'
          invalid={!!errorText}
          onChange={e => setPwd1(e.target.value)}
          required
          type='password'
          valid={!errorText && pwd1.length >= 8}
          value={pwd1}
        />
        <FormText>
          Your password must contain at least 8 characters.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label for='pwd2'>Enter it again:</Label>
        <Input
          autoComplete='new-password'
          id='pwd2'
          invalid={!!errorText || pwd2 !== pwd1}
          onChange={e => setPwd2(e.target.value)}
          required
          type='password'
          valid={!errorText && pwd2 === pwd1 && pwd2.length >= 8}
          value={pwd2}
        />
        <FormFeedback valid={false}>{errorText}</FormFeedback>
      </FormGroup>
      <Button
        color='primary'
        disabled={!canSubmit}
        type='submit'
      >
        Change password
      </Button>
    </Form>
  );
};
