import React from 'react';

import { FormGroup, Input } from 'reactstrap';

const BookSelect = ({ books, onSelectBook, selectedBook }) => (
  <Input
    onChange={
      (evt) => {
        const bookIdx = evt.target.value;
        onSelectBook(bookIdx, books[bookIdx].usfm);
      }
    }
    type='select'
    value={ selectedBook || '' }
  >
    {
      books.map(
        (book, idx) => <option
          key={book.usfm}
          value={idx}>{book.human_long}</option>
      )
    }
  </Input>
);

const ChapterSelect = ({ chapterCount, onSelectChapter, selectedChapter }) => {

  const chaps = [];

  for (let i = 1; i <= chapterCount; i++) {
    chaps.push(
      <option
        key={i}
        value={i}>{i}</option>
    );
  }

  return (
    <Input
      onChange={(evt) => onSelectChapter(evt.target.value)}
      type='select'
      value={ selectedChapter }
  >
      {
      chaps
    }
    </Input>
  );
};

export default class ReferenceSelector extends React.Component {

  static getDerivedStateFromProps(props) {
    return props.reference;
  }

  constructor(props) {
    super(props);

    this.state = {
      book: 'GEN',
      bookIdx: 0,
      chapter: '1',
      verse: '1'
    };

    this.setBook = this.setBook.bind(this);
    this.setChapter = this.setChapter.bind(this);
    this.setVerse = this.setVerse.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.reference !== this.props.reference) {
      this.setState(this.props.reference);
    }
  }

  setBook(idx, usfm) {
    this.props.onChange({
      ...this.state,
      book: usfm,
      bookIdx: idx,
      chapter: Math.min(this.state.chapter, this.getBookChapterCount(idx))
    });
  }

  setChapter(idx) {
    this.props.onChange({
      ...this.state,
      chapter: parseInt(idx, 10)
    });
  }

  setVerse(idx) {
    this.props.onChange({
      ...this.state,
      verse: parseInt(idx, 10)
    });
  }

  getBookChapterCount(bookIdx) {
    const { selectedVersion } = this.props;
    return selectedVersion && selectedVersion.books && selectedVersion.books[bookIdx] ? selectedVersion.books[bookIdx].chapters.filter(c => c.canonical).length : 0;
  }

  render() {
    const { invalid, selectedVersion } = this.props;
    const { bookIdx } = this.state;

    return (
      <FormGroup className='linear'>
        <BookSelect
          books={selectedVersion && selectedVersion.books ? selectedVersion.books : []}
          onSelectBook={this.setBook}
          selectedBook={this.state.bookIdx}
        />
        <ChapterSelect
          chapterCount={this.getBookChapterCount(bookIdx)}
          onSelectChapter={this.setChapter}
          selectedChapter={this.state.chapter}
        />
        <Input
          invalid={invalid}
          min={1}
          onChange={(evt) => this.setVerse(evt.target.value)}
          type='number'
          value={this.state.verse} />
      </FormGroup>
    );
  }
}
