export const clickHandler = (func, ...args) => {
  const inner = (evt) => {
    if (func && func(...args)) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  return inner;
};
