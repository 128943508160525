import { createSelector } from "reselect";

const bibleState = state => state.bible;

export const versionsSelector = createSelector(
  bibleState,
  (bs) => bs.versions
);

export const loadingSelector = createSelector(
  bibleState,
  (bs) => bs.loading > 0
);

export const selectedVersionSelector = createSelector(
  bibleState,
  (bs) => bs.selectedVersion || ''
);

export const bibleContentSelector = createSelector(
  bibleState,
  (bs) => bs.content
);
