import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from "react-dropzone";
import classNames from 'classnames';

import modal, { connectedModal } from 'modal';
import { importResources } from '../actions';

const ConnectedModal = connectedModal('resource/import');

const PendingFiles = ({ files }) => {
  if (files.length === 0) {
    return (
      <p>
        Drag and drop files here, or click to select files to upload.
      </p>
    );
  }
  return (
    <ul>
      {
        files.map(
          (file) => (
            <li key={file.name}>{file.name}</li>
          )
        )
      }
    </ul>

  );
};

const Errors = ({ errors }) => {
  if (errors.length === 0) {
    return null;
  }
  return (
    <div className='alert alert-danger'>
      <h4>Errors</h4>
      <ul>
        {
          errors.map(
            (err, idx) => <li key={idx}>{err}</li>
          )
        }
      </ul>
    </div>
  );
};


class ImportResourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      files: [],
      success: null
    };
    this.handleDrop = this.handleDrop.bind(this);
    this.upload = this.upload.bind(this);
  }

  handleDrop(files) {
    this.setState({
      files: [
        ...this.state.files,
        ...files
      ]
    });
  }

  upload(e) {
    e.preventDefault();
    const { importResources } = this.props;
    importResources(this.state.files).then(
      (resp) => {
        this.setState({
          errors: (resp.payload && resp.payload.data && resp.payload.data.errors) || [],
          files: [],
          success: resp.payload && resp.payload.data && resp.payload.data.actions && resp.payload.data.actions.length
        });
      }
    );
  }

  render() {
    const { showModal } = this.props;
    const { files, errors, success } = this.state;
    return (
      <ConnectedModal fullSize>
        <ModalHeader toggle={() => showModal(null)}>
          Import resources
        </ModalHeader>
        <ModalBody>
          <p>
            You can import song files from <em>Words of Worship</em> straight into your library.
          </p>
          {
            success ?
              <p className='text-success mt-2'>{success} file{success > 1 ? 's' : '' } successfully imported.</p>
            : null
          }
          <Errors errors={errors} />
          <Dropzone onDrop={this.handleDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames('dropzone', 'import-resource-dropzone', { 'dropzone--isActive': isDragActive })}
                >
                  <input {...getInputProps()} />
                  {
                    isDragActive && <span>Drop files here...</span>
                  }
                  <PendingFiles
                    files={files}
                  />
                </div>
              );
            }}
          </Dropzone>
        </ModalBody>
        <ModalFooter>
          <Button
            color='primary'
            disabled={files.length === 0}
            onClick={this.upload}
          >
            Import
          </Button>
          <Button onClick={() => showModal(null)}>Close</Button>
        </ModalFooter>
      </ConnectedModal>
    );
  }
}

ImportResourceModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  importResources: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    importResources,
    showModal: modal.actions.showModal
  }
)(ImportResourceModal);
