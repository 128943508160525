import * as constants from './constants';

import auth from 'auth';
import { settingsState } from './selectors';

export const load = () => (dispatch, getState) => {
  const account = auth.selectors.accountSelector(getState());
  dispatch({
    // Load from unlimited local storage via browser extension.
    type: constants.LOAD,
    chrome: {
      message: 'storage_get',
      key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`
    }
  });
};

export const save = () => (dispatch, getState) => {
  const state = getState();
  const settings = settingsState(state);
  const account = auth.selectors.accountSelector(state);
  return dispatch({
    type: constants.SAVE,
    chrome: {
      message: 'storage_set',
      key: `${constants.LOCAL_STORAGE_KEY}/${account.id}`,
      value: settings
    }
  });
};

export const updateSettings = (settings) => dispatch => {
  dispatch({
    type: constants.UPDATE_SETTINGS,
    settings
  });
  dispatch(save());
};

export const applyDisplaySettings = (settings) => ({
  type: constants.APPLY_DISPLAY_SETTINGS,
  chrome: {
    message: 'position_output_window',
    ...settings
  }
});

export const retrieveDisplaySettings = (windowIndex) => ({
  type: constants.RETRIEVE_DISPLAY_SETTINGS,
  chrome: {
    message: 'get_output_window_position',
    index: windowIndex
  }
});

export const setPreReleaseWarningVersion = () => ({
  type: constants.SET_PRERELEASE_WARNING_VERSION
});

export const saveOutputSettings = (outputs) => dispatch => {
  dispatch({
    type: constants.SAVE_OUTPUT_SETTINGS,
    outputs
  });
  dispatch(save());
};
