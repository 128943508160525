import auth from 'auth';
import resource from 'resource';
import theme from 'theme';
import * as constants from './constants';
import { pendingActionsSelector, syncStateSelector } from './selectors';

export const sync = () => (dispatch, getState) => {
  const state = getState();
  const syncState = syncStateSelector(state);
  const syncPoint = (syncState.lastSuccess / 1000);

  return dispatch(syncFrom(syncPoint));
};

export const syncFrom = (syncPoint) => (dispatch, getState) => {
  const state = getState();
  const pending = pendingActionsSelector(state);
  const account = auth.selectors.accountSelector(state);

  Promise.resolve(  // dispatch() won't return a promise if the response is a 204 (no actions)
    dispatch({
      type: constants.START_SYNC,
      payload: {
        request: {
          url: 'sync',
          data: {
            actions: pending[account.id] || [],
            lastSync: syncPoint
          },
          method: 'post',
          auth: true
        },
        account: account.id
      }
    })
  ).then(
    (r) => {
      if (r && r.payload && r.payload.data) {
        // We only need to save if there were changes
        dispatch(resource.actions.save());
        dispatch(theme.actions.save());
      }
    }
  );

};
