import * as constants from './constants';
import { resolved } from 'chrome';

const INITIAL_STATE = {
  _loaded: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case resolved(constants.LOAD):
      return {
        ...action.payload,
        _loaded: true
      };

    case constants.PUT_RESOURCE:
      const { resource } = action;
      return {
        ...state,
        [resource.id]: {
          ...resource,
          meta: {
            ...resource.meta,
            created: resource.meta.created || Date.now(),
            modified: Date.now()
          }
        }
      };

    case constants.TOUCH_RESOURCE_USED_TIMESTAMP:
      if (!!state[action.resource.id]) {
        return {
          ...state,
          [action.resource.id]: {
            ...state[action.resource.id],
            meta: {
              ...state[action.resource.id].meta,
              lastUsed: Date.now()
            }
          }
        };
      }
      else {
        // We might have been passed a scripture or something else that doesn't
        // live in our state
        return state;
      }

    case constants.BULK_PUT_RESOURCES:
      const { resourceActions } = action;
      const newState = { ...state };
      resourceActions.forEach(
        ({ resource }) => {
          newState[resource.id] = resource;
        }
      );
      return newState;

    case constants.DELETE_RESOURCE:
      const stateCopy = {
        ...state
      };

      delete stateCopy[action.resource.id];
      return stateCopy;

    default:
      return state;
  }
};
