import * as actions from './actions';
import * as constants from './constants';
import reducer from './reducer';
import * as selectors from './selectors';
export * from './components';

const searchIndexer = ({ resources, indexDocument }) => {
  selectors.resourcesSelector({ resources: { ...resources } }).forEach(
    (resource) => {
      indexDocument(`${resource.id}@`, resource.title);

      resource.content.forEach(
        (block, blockIdx) => {
          block.content.forEach(
            (lineGroup, groupIdx) => {
              lineGroup.forEach(
                (line, lineIdx) => indexDocument(
                  `${resource.id}@${blockIdx},${groupIdx},${lineIdx}`,
                  line[0]
                )
              );
            }
          );
        }
      );
    }
  );
};

export default {
  actions,
  constants,
  reducer,
  searchIndexer,
  selectors
};
