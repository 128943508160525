import React, { Fragment } from 'react';
import { FormGroup, Label } from 'reactstrap';

import FormField from 'components/FormField';
import ColourPickerField from 'components/ColourPickerField';

export default () => (
  <Fragment>
    <div className='font-grid'>
      <FormGroup className='linear'>
        <Label for='meta.font.size'>Size (pt):</Label>
        <FormField
          autoSave
          min={1}
          name='meta.font.size'
          type='number' />
      </FormGroup>
      <FormGroup className='linear'>
        <Label for='meta.font.color'>Font colour:</Label>
        <ColourPickerField
          autoSave
          name='meta.font.color' />
      </FormGroup>
      <FormGroup className='linear'>
        <Label>Style:</Label>
        <FormGroup check>
          <Label>
            <FormField
              autoSave
              name='meta.font.bold'
              type="checkbox" /> Bold
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label>
            <FormField
              autoSave
              name='meta.font.italic'
              type="checkbox" /> Italic
          </Label>
        </FormGroup>
      </FormGroup>
      <FormGroup className='linear'>
        <Label for='meta.font.align'>Alignment:</Label>
        <FormField
          autoSave
          name='meta.font.align'
          type='select'>
          <option value='left'>Left</option>
          <option value='center'>Centre</option>
          <option value='right'>Right</option>
        </FormField>
      </FormGroup>
    </div>
    <FormGroup className='linear'>
      <Label for='meta.duration'>Display duration (s):</Label>
      <FormField
        autoSave
        min={1}
        name='meta.duration'
        type='number'
        />
    </FormGroup>
    <p>Displaying valid copyright information is a condition of the CCLI licence.</p>
  </Fragment>
);
