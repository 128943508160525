import React from 'react';
import SubscribeBox from './SubscribeBox';

export default () => (
  <div>
    <p>
      You're currently using the free tier of JustWords, which limits the number
      of songs and liturgy you can store in your library, and doesn't sync
      your account between devices.
    </p>
    <SubscribeBox />
  </div>
);
