import * as React from 'react';

import { createEditor, Editor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

import { withResourceEditor } from '../editorPlugin';
import { withHistory } from 'slate-history';
import { EditorToolbar } from './EditorToolbar';

const renderElement = (props) => {
  switch(props.element.type) {
    case 'verse':
    case 'chorus':
    case 'bridge':
    case 'section':
      return (
        <BlockElement
          type={props.element.type}
          {...props}
        />
      );
    case 'lineGroup':
      return <LineGroupElement {...props} />;
    case 'line':
      return <LineElement {...props} />;
    default:
      return <DefaultElement {...props} />;
  }
};

const DefaultElement = (props) => <div {...props} />;

const BlockElement = ({ type, ...props }) => (
  <div
    className={`block ${type}`}
    data-descriptor={props.element && props.element.descriptor}
    data-index={props.element && props.element.index}
    {...props}
  />
);

const LineGroupElement = (props) => (
  <div
    className='lineGroup'
    {...props}
  />
);

const LineElement = (props) => (
  <div
    className={`line ${props.element.style || 'normal'}`}
    {...props}
  />
);

const DEFAULT_CONTENT = [
  {
    type: 'verse',
    children: [
      {
        type: 'lineGroup',
        children: [
          {
            type: 'line',
            style: 'normal',
            children: [
              {
                text: ''
              }
            ]
          }
        ]
      }
    ]
  }
];

const mapResourceToEditor = (resource, defaultBlockType='verse') => {
  if (!resource || !resource.content) {
    return DEFAULT_CONTENT;
  }

  return resource.content.map(
    block => ({
      type: block.type || defaultBlockType,
      children: block.content.map(
        lg => ({
          type: 'lineGroup',
          children: lg.map(
            mapLineToEditor
          )
        })
      )
    })
  );
};

const mapLineToEditor = (line) => {
  const [text, type] = line;
  let outType = 'normal';
  if (type === 1) {
    outType = 'minor';
  }
  return {
    type: 'line',
    style: outType,
    children: [
      { text }
    ]
  };
};

const mapEditorToResourceContent = (value) => {
  return value.map(mapBlockToResourceContent);
};

const mapBlockToResourceContent = (block) => ({
  type: block.type,
  content: block.children.map(mapLineGroupToResourceContent)
});

const mapLineGroupToResourceContent = (lg) => lg.children.map(mapLineToResourceContent);

const mapLineToResourceContent = (line) => {
  let outType = 0;
  if (line.style === 'minor') {
    outType = 1;
  }

  const text = (line.children || []).map(l => l.text).join('');

  return [text, outType];
};


export const NewResourceEditor = ({ onChange, resource, resourceType }) => {

  const editor = React.useMemo(
    () => withResourceEditor(
      withReact(
        withHistory(
          createEditor()
        )
      )
    ),
    []
  );
  const [value, setValue] = React.useState(
    mapResourceToEditor(resource, resourceType === 'Song' ? 'verse' : 'section')
  );

  const handleChange = React.useCallback(
    (newValue) => {
      setValue(newValue);
      if (onChange) {
        onChange(mapEditorToResourceContent(newValue));
      }
    },
    [onChange, setValue]
  );

  React.useEffect(
    () => {
      if (resourceType !== editor.resourceType) {
        editor.resourceType = resourceType;
        Editor.normalize(editor, { force: true });
        onChange && onChange(editor.value);
      }
    },
    [editor, onChange, resourceType]
  );

  return (
    <Slate
      editor={editor}
      onChange={handleChange}
      value={value}
    >
      <EditorToolbar
        editor={editor}
        resourceType={resourceType}
      />
      <Editable
        className='resource editable'
        renderElement={renderElement}
      />
    </Slate>
  );
};
