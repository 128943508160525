import { createSelector } from "reselect";


const subscriptionState = state => state.subscription;

export const subscriptionSelector = createSelector(
  subscriptionState,
  state => ({
    subscription: state.data ? state.data.subscription : null,
    paymentMethod: state.data ? state.data['payment_method'] : null,
    price: state.data ? state.data['price'] : null,
    error: state.error
  })
);
